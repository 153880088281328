define("apollo/pods/components/forms/input-area/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-text'],
    localClassNameBindings: ['widthClass', 'labelPosition'],
    type: 'text',
    placeholder: '',
    width: 2,
    currency: {
      separator: ".",
      prefix: " $ "
    },
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-quarter';
      }
    }),
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + "-label";
    })
  });

  _exports.default = _default;
});