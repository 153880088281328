define("apollo/pods/components/field/list-box/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JlXXP5xn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"fa-icon\",[],[[\"@icon\"],[[23,\"icon\"]]]],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/field/list-box/item/template.hbs"
    }
  });

  _exports.default = _default;
});