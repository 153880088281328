define("apollo/pods/authorized/insurance/policy/policy_versions/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3cdKvxK8",
    "block": "{\"symbols\":[],\"statements\":[[4,\"breadcrumbs\",null,null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\"],[\"authorized.insurance.companies\"]],{\"statements\":[[0,\"Insurance Companies\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"link-to\",null,[[\"route\",\"model\"],[\"authorized.insurance.company.policies.root\",[25,[\"policyVersion\",\"policy\",\"company\"]]]],{\"statements\":[[0,\"    \"],[1,[25,[\"policyVersion\",\"policy\",\"company\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"route\",\"model\"],[\"authorized.insurance.policy.policy_versions.root\",[25,[\"policyVersion\",\"policy\"]]]],{\"statements\":[[0,\"    \"],[1,[25,[\"policyVersion\",\"policy\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[9],[0,\"Create New\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"center-content\"],[9],[0,\"\\n  \"],[1,[29,\"policies/policy-version-form\",null,[[\"title\",\"policyVersion\",\"priceLists\",\"currencies\",\"disablePriceLists\",\"saveAction\",\"cancelAction\",\"setLinkedPriceList\"],[\"Create new Policy Version\",[25,[\"policyVersion\"]],[25,[\"priceLists\"]],[25,[\"currencies\"]],[25,[\"disablePriceLists\"]],[29,\"action\",[[24,0,[]],\"savePolicyVersion\"],null],[29,\"action\",[[24,0,[]],\"goBack\"],null],[29,\"action\",[[24,0,[]],\"setLinkedPriceList\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/authorized/insurance/policy/policy_versions/new/template.hbs"
    }
  });

  _exports.default = _default;
});