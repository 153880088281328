define("apollo/pods/components/checkmark-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['checkmark'],
    moldIcon: Ember.computed('mold', function () {
      switch (Ember.get(this, 'mold')) {
        case 'inverted':
          return '/assets/icons/checkmark-inverted.svg';

        default:
          return '/assets/icons/checkmark.svg';
      }
    }),
    updateAction: 'doNothing',
    actions: {
      doNothing() {
        /* Do nothing */
      },

      toggleProperty(event) {
        let click = Ember.get(this, 'click');

        if (click) {
          this.toggleProperty('value');
          return click(Ember.get(this, 'value'), event);
        }
      }

    }
  });

  _exports.default = _default;
});