define("apollo/pods/components/apollo/layout/relative/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-relative": "_grid-relative_1bigvr",
    "gravity-vertical": "_gravity-vertical_1bigvr",
    "gravity-horizontal": "_gravity-horizontal_1bigvr",
    "align-left": "_align-left_1bigvr",
    "align-center": "_align-center_1bigvr",
    "align-right": "_align-right_1bigvr",
    "align-between": "_align-between_1bigvr",
    "margin-none": "_margin-none_1bigvr",
    "margin-small": "_margin-small_1bigvr",
    "margin-medium-small": "_margin-medium-small_1bigvr",
    "margin-normal": "_margin-normal_1bigvr",
    "margin-big": "_margin-big_1bigvr",
    "margin-header-cover": "_margin-header-cover_1bigvr",
    "border-none": "_border-none_1bigvr",
    "border-1": "_border-1_1bigvr",
    "grid-layout": "_grid-layout_1bigvr"
  };
  _exports.default = _default;
});