define("apollo/pods/authorized/insurance/policy_version/benefits/coverages/controller", ["exports", "apollo/config/environment", "apollo/pods/objects/ref-list", "apollo/pods/objects/export-manager"], function (_exports, _environment, _refList, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    apollo: Ember.inject.service('apollo'),
    session: Ember.inject.service('session'),
    benefit: null,
    collapsePolicyInfo: false,
    panel: 'X',
    page_size: 15,
    page_current: 1,
    data_size: 0,
    //Filter properties
    filters: {},
    sort: {},
    settings: Ember.computed(function () {
      let token = Ember.get(this, 'session.data.authenticated.access_token');
      return {
        'headers': {
          'Authorization': "Bearer ".concat(token)
        }
      };
    }),
    editBenefitOpened: Ember.computed('panel', function () {
      let panel = Ember.get(this, 'panel');
      return panel === 'E';
    }),
    addCoveragesOpened: Ember.computed('panel', function () {
      let panel = Ember.get(this, 'panel');
      return panel === 'A';
    }),
    frequenciesList: Ember.computed(function () {
      return _refList.default.create().getList("limitFrequency");
    }),
    targetList: Ember.computed(function () {
      return _refList.default.create().getList("limitTarget");
    }),
    canAddCoverages: Ember.computed('benefit', 'benefit.isNew', function () {
      return !Ember.get(this, 'benefit.isNew');
    }),

    loadCurrent(benefits) {
      let current = Ember.get(this, 'benefit');

      if (!current) {
        if (benefits.length > 0) {
          current = benefits.firstObject;
        } else {
          let policyVersion = Ember.get(this, 'policyVersion');
          let data = {
            policyVersion,
            name: '[default]'
          };
          current = Ember.get(this, 'store').createRecord('benefit', data);
          Ember.set(this, 'editBenefit', true);
        }

        Ember.set(this, 'benefit', current);
      }

      Ember.set(this, 'limitRule', Ember.get(this, 'store').createRecord('limitRule'));
    },

    loadPages(page, recalculate) {
      let benefit = Ember.get(this, 'benefit');

      if (benefit.id) {
        let pageSize = Ember.get(this, 'page_size');
        let filter = this.getFilters();
        let sort = this.getSort();
        Ember.get(this, 'store').query('importPolicy', {
          filter,
          sort,
          endpointURI: "benefits/".concat(benefit.id, "/coverages/_search?page_number=").concat(page, "&page_size=").concat(pageSize)
        }).then(coverages => {
          Ember.set(this, 'coverages', coverages);
        });

        if (recalculate) {
          Ember.get(this, 'store').queryRecord('page-number', {
            filter,
            endpointURI: "benefits/".concat(benefit.id, "/coverages/pages-total?page_size=").concat(pageSize)
          }).then(response => {
            Ember.set(this, 'data_size', response.size);
          });
        }
      } else {
        Ember.set(this, 'coverages', null);
        Ember.set(this, 'data_size', 0);
      }
    },

    getFilters() {
      let filters = Ember.get(this, 'filters');
      return {
        epc: {
          like: filters.query
        },
        name: {
          or_ilike: filters.query
        }
      };
    },

    getSort() {
      let sort = {};
      let sorting = Ember.get(this, 'sort');

      if (sorting.name) {
        sort.name = Ember.String.decamelize(sorting.name);
        sort.order = sorting.order;

        if (sort.name.includes(".")) {
          let params = sort.name.split(".");
          sort.bind = params[0];
          sort.name = params[1];
        }
      }

      return sort;
    },

    actions: {
      changePage(page, pageSize, recalculate) {
        if (pageSize > 0) {
          Ember.set(this, 'page_size', pageSize);
        }

        this.loadPages(page, recalculate);
      },

      showPanel(selected) {
        let current = Ember.get(this, 'panel');

        if (current === selected) {
          selected = 'X';
        }

        Ember.set(this, 'panel', selected);
      },

      showPolicyInfo() {
        Ember.set(this, 'collapsePolicyInfo', !Ember.get(this, 'collapsePolicyInfo'));
      },

      downloadLayout(value) {
        let manager = _exportManager.default.create();

        let benefit = Ember.get(this, 'benefit');

        switch (value) {
          case 2:
            // Current
            manager.download('coverages-export.csv', manager.fileFrom('coverage', Ember.get(this, 'coverages')));
            break;

          case 1:
            // Full
            this.apollo.request("".concat(_environment.default.APP.apiUrl, "/benefits/").concat(benefit.id, "/download-coverages")).then(data => manager.download('coverages-export.csv', data)).catch(data => manager.download('coverages-export.csv', data));
            break;

          case 0: //Empty

          default:
            manager.download('coverages-export.csv', manager.emptyFile('coverage'));
        }
      },

      uploadLayout(file) {
        let benefit = Ember.get(this, 'benefit');
        let url = "".concat(_environment.default.APP.apiUrl, "/benefits/").concat(Ember.get(benefit, 'id'), "/coverages");
        let settings = Ember.get(this, 'settings');
        let notifications = Ember.get(this, 'notifications');
        return file.upload(url, settings).then(() => {
          this.loadPages(1, true);
          return notifications.show('success', 'Products imported');
        }).catch(error => {
          Ember.set(file, 'queue.files', []);

          if (error.status === 500) {
            return notifications.show('error', 'Error, File format not valid');
          } else {
            return notifications.show('error', 'Error, products not imported');
          }
        });
      },

      saveBenefit() {
        let benefit = Ember.get(this, 'benefit');
        let notifications = Ember.get(this, 'notifications');
        return benefit.save().then(() => {
          return notifications.show('success', 'Benefit saved successfully');
        }).catch(response => {
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      changeBenefit(benefit) {
        if (!benefit) {
          let policyVersion = Ember.get(this, 'policyVersion');
          let data = {
            policyVersion,
            name: '[default]'
          };
          benefit = Ember.get(this, 'store').createRecord('benefit', data);
          Ember.set(this, 'editBenefit', true);
        }

        Ember.set(this, 'benefit', benefit);
        this.loadPages(1, true);
      },

      deleteBenefit() {
        let notifications = Ember.get(this, 'notifications');
        return notifications.show('error', 'Try to delete benefit');
      },

      async onSelectProduct(value, product) {
        if (product) {
          let benefit = Ember.get(this, 'benefit');
          let notifications = Ember.get(this, 'notifications');
          let store = Ember.get(this, 'store');
          let existingCoverage = await store.queryRecord('coverage', {
            endpointURI: "benefits/".concat(benefit.id, "/coverages/find?epc=").concat(product.epc)
          });

          if (existingCoverage) {
            notifications.show('warning', 'Product exists.');
            return false;
          }

          if (Ember.get(benefit, 'isNew')) {
            notifications.show('warning', 'First save benefit header data.');
            return false;
          }

          let coverage = store.createRecord('coverage', {
            benefit,
            name: product.name,
            epc: product.epc,
            pType: product.category_name
          });
          return coverage.save().then(() => {
            this.loadPages(Ember.get(this, 'page_current'), true);
            notifications.show('success', 'Coverage added');
          }).catch(() => {
            notifications.show('error', 'Coverage couldn\'t be added');
          });
        }

        return false;
      },

      updateCoverage(coverage) {
        return coverage.save().then(() => {
          return true;
        }).catch(response => {
          let notifications = Ember.get(this, 'notifications');
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      deleteCoverage(coverage) {
        let notifications = Ember.get(this, 'notifications');
        coverage.destroyRecord().then(() => {
          notifications.show('success', 'Coverage successfully deleted.');
          this.loadPages(Ember.get(this, 'page_current'), true);
        }).catch(() => {
          coverage.rollbackAttributes();
          notifications.show('error', 'Coverage delete failed!');
        });
      },

      saveLimitRule(coverage) {
        let notifications = Ember.get(this, 'notifications');
        let limitRule = Ember.get(this, 'limitRule');
        Ember.set(limitRule, "coverage", coverage);
        limitRule.save().then(limitRule => {
          notifications.show('success', 'Limit Rule saved!');
          return limitRule;
        }).catch(() => {
          limitRule.rollbackAttributes();
          notifications.show('error', 'Limit Rule save failed!');
        });
        Ember.set(this, 'limitRule', Ember.get(this, 'store').createRecord('limitRule'));
      },

      deleteLimitRule(rule) {
        rule.destroyRecord();
      },

      changeRadioValue(model, changedField, relatedField, value) {
        Ember.set(model, changedField, value);
        Ember.set(model, relatedField, false);
        this.send("updateCoverage", model);
        return false;
      },

      filterCoverages(query) {
        Ember.set(this, "filters.query", query);

        if (!query || query.length > 3) {
          this.loadPages(1, true);
        }
      },

      sortBy(sort) {
        Ember.set(this, "sort", sort);
        this.loadPages(1, true);
      }

    }
  });

  _exports.default = _default;
});