define("apollo/pods/components/patient-panel/field/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "field": "_field_l01db7",
    "field-label": "_field-label_l01db7"
  };
  _exports.default = _default;
});