define("apollo/models/charge", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-api-actions"], function (_exports, _model, _attr, _relationships, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    epc: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    exceededAmount: (0, _attr.default)('number'),
    date: (0, _attr.default)('date'),
    discount: (0, _attr.default)('number'),
    quantity: (0, _attr.default)('number'),
    description: (0, _attr.default)('string'),
    splitAmount: (0, _attr.default)('number'),
    encounter: (0, _relationships.belongsTo)('encounter'),
    refCharge: (0, _relationships.belongsTo)('charge'),
    // Computed Properties
    isApproved: Ember.computed('status', function () {
      return Ember.get(this, 'status') === "authorized";
    }),
    coverage: Ember.computed('amount', 'exceededAmount', function () {
      return (Ember.get(this, 'amount') - Ember.get(this, 'exceededAmount')) / 100;
    }),
    isExceeded: Ember.computed('isApproved', 'exceededAmount', function () {
      return Ember.get(this, 'isApproved') && Ember.get(this, 'exceededAmount') > 0;
    }),
    isDiscount: Ember.computed('isApproved', 'discount', function () {
      return Ember.get(this, 'isApproved') && Ember.get(this, 'discount') !== null;
    }),
    amountFloat: Ember.computed('amount', {
      get() {
        return (Ember.get(this, 'amount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'amount', value * 100);
        return value;
      }

    }),
    exceededAmountFloat: Ember.computed('exceededAmount', {
      get() {
        return (Ember.get(this, 'exceededAmount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'exceededAmount', value * 100);
        return value;
      }

    }),
    discountFloat: Ember.computed('discount', {
      get() {
        return (Ember.get(this, 'discount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'discount', value * 100);
        return value;
      }

    }),
    splitAmountFloat: Ember.computed('splitAmount', {
      get() {
        return (Ember.get(this, 'splitAmount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'splitAmount', value * 100);
        return value;
      }

    }),
    // Member Actions
    updateStatus: (0, _emberApiActions.memberAction)({
      path: 'update-status'
    }),
    split: (0, _emberApiActions.memberAction)({
      path: 'split'
    })
  });

  _exports.default = _default;
});