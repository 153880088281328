define("apollo/pods/components/filter/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['input-form'],
    localClassNameBindings: ['widthClass', 'labelPosition'],
    text: "",
    focused: false,
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-full';
      }
    }),
    labelPosition: Ember.computed('label-align', function () {
      let align = Ember.get(this, 'label-align');
      return align ? "".concat(align, "-label") : 'top-label';
    }),
    actions: {
      chooseOption(item) {
        let key = Ember.get(this, 'option-key');
        let text = Ember.get(this, 'option-label');

        if (item && key) {
          Ember.set(this, 'value', Ember.get(item, key));
        }

        if (item && text) {
          Ember.set(this, 'text', Ember.get(item, text));
        }

        Ember.set(this, 'focused', false);
        let onSelect = Ember.get(this, 'onSelect');

        if (onSelect) {
          return onSelect(Ember.get(this, 'value'), item);
        }
      },

      setFocus(focused) {
        Ember.set(this, 'focused', focused);
      },

      onChange(text) {
        Ember.set(this, "text", text);
      }

    }
  });

  _exports.default = _default;
});