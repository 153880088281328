define("apollo/models/import-policy", ["exports", "ember-data/model", "ember-data/attr", "ember-api-actions"], function (_exports, _model, _attr, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { belongsTo } from 'ember-data/relationships';
  var _default = _model.default.extend({
    majorCompany: (0, _attr.default)('string'),
    majorCompanyName: (0, _attr.default)('string'),
    minorCompany: (0, _attr.default)('string'),
    minorCompanyName: (0, _attr.default)('string'),
    policyIdentifier: (0, _attr.default)('string'),
    policyName: (0, _attr.default)('string'),
    sequenceNumber: (0, _attr.default)('string'),
    annualLimit: (0, _attr.default)('string'),
    currency: (0, _attr.default)('string'),
    commencementDate: (0, _attr.default)('string'),
    startDate: (0, _attr.default)('string'),
    endDate: (0, _attr.default)('string'),
    priceListId: (0, _attr.default)('number'),
    override: (0, _attr.default)('string'),
    referral: (0, _attr.default)('string'),
    needsPreauthorization: (0, _attr.default)('string'),
    hasCompany: Ember.computed('company_id', function () {
      return this.company_id !== null && this.company_id > 0;
    }),
    type: Ember.computed('majorCompany', 'minorCompany', function () {
      return this.majorCompany === this.minorCompany ? "Personal" : "Corporate";
    }),
    company_id: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    process: (0, _emberApiActions.collectionAction)({
      path: '_process',
      type: 'post',
      urlType: 'findRecord'
    }),
    clean: (0, _emberApiActions.collectionAction)({
      path: '_clean',
      type: 'post',
      urlType: 'findRecord'
    })
  });

  _exports.default = _default;
});