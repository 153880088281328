define("apollo/pods/components/forms/input-search-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-box": "_search-box_10i8o8",
    "grow": "_grow_10i8o8",
    "search-icon": "_search-icon_10i8o8",
    "search-input": "_search-input_10i8o8 _input_1dnopz",
    "item-list": "_item-list_10i8o8",
    "element-item": "_element-item_10i8o8",
    "default": "_default_10i8o8",
    "input": "_input_10i8o8 _input_1dnopz",
    "label": "_label_10i8o8 _label_1dnopz",
    "form-input": "_form-input_10i8o8 _form-input_1dnopz",
    "required": "_required_10i8o8 _required_1dnopz",
    "expand-eight": "_expand-eight_10i8o8 _expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_10i8o8 _expand-quarter_1dnopz",
    "expand-half": "_expand-half_10i8o8 _expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_10i8o8 _expand-th-q_1dnopz",
    "expand-full": "_expand-full_10i8o8 _expand-full_1dnopz",
    "input-error": "_input-error_10i8o8 _input-error_1dnopz",
    "error-message": "_error-message_10i8o8 _error-message_1dnopz"
  };
  _exports.default = _default;
});