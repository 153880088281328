define("apollo/pods/components/apollo/empty/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "empty": "_empty_irwlkd",
    "empty-icon": "_empty-icon_irwlkd",
    "empty-label": "_empty-label_irwlkd",
    "empty-description": "_empty-description_irwlkd"
  };
  _exports.default = _default;
});