define("apollo/pods/components/apollo/paginate/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['paginate'],
    localClassNameBindings: ['paginateDesign'],
    design: 'default',
    pages: [10, 15, 30, 45, 60],
    page: 1,
    perPage: 5,
    totalRecords: 0,
    buttons: 5,
    first_page: 1,
    last_page: Ember.computed('totalRecords', 'perPage', function () {
      return Ember.get(this, 'totalRecords');
    }),
    display_first: Ember.computed('totalRecords', 'perPage', 'page', function () {
      let firstPage = Ember.get(this, 'first_page');
      let lastPage = Ember.get(this, 'last_page');
      let page = Ember.get(this, 'page');
      let pageBtns = Ember.get(this, 'buttons');
      return page - Math.floor(pageBtns / 2) > firstPage && lastPage > pageBtns;
    }),
    display_last: Ember.computed('totalRecords', 'buttons', 'page', 'perPage', function () {
      let lastPage = Ember.get(this, 'last_page');
      let page = Ember.get(this, 'page');
      let pageBtns = Ember.get(this, 'buttons');
      return page + Math.floor(pageBtns / 2) <= lastPage && lastPage > pageBtns;
    }),
    pageButtons: Ember.computed('totalRecords', 'perPage', 'page', function () {
      let lastPage = Ember.get(this, 'last_page');
      let maxPages = Ember.get(this, 'totalRecords');
      let pageBtns = Ember.get(this, 'buttons');
      let currentPage = Ember.get(this, 'page');
      let midPages = Math.floor(pageBtns / 2);
      let init = 1;

      if (currentPage > midPages && lastPage > pageBtns) {
        init = currentPage - midPages;
      }

      let qty = pageBtns; //4

      if (currentPage + midPages > maxPages && lastPage > pageBtns) {
        qty = qty - (currentPage + midPages - maxPages) + (1 - pageBtns % 2);
      }

      if (qty > maxPages) {
        qty = maxPages;
      }

      let results = Array.from({
        length: qty
      }, (_, i) => init + i);
      return results;
    }),
    paginateDesign: Ember.computed('design', function () {
      return "design-".concat(Ember.get(this, 'design'));
    }),
    actions: {
      setPage(page, recalculate) {
        let lastPage = Ember.get(this, 'last_page');
        let perPage = Ember.get(this, 'perPage');

        if (page <= 0) {
          page = 1;
        } else if (page > lastPage) {
          page = lastPage;
        }

        Ember.set(this, 'page', parseInt(page, 10));
        let action = Ember.get(this, 'action');

        if (action) {
          action(page, perPage, recalculate);
        }
      },

      setPerPage(pageSize) {
        Ember.set(this, 'perPage', parseInt(pageSize, 10));
        this.send('setPage', 1, true);
      }

    }
  });

  _exports.default = _default;
});