define("apollo/pods/components/forms/input-checkbox/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_brox1g _input_1dnopz",
    "label": "_label_brox1g _label_1dnopz",
    "form-input": "_form-input_brox1g _form-input_1dnopz",
    "required": "_required_brox1g _required_1dnopz",
    "expand-eight": "_expand-eight_brox1g _expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_brox1g _expand-quarter_1dnopz",
    "expand-half": "_expand-half_brox1g _expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_brox1g _expand-th-q_1dnopz",
    "expand-full": "_expand-full_brox1g _expand-full_1dnopz"
  };
  _exports.default = _default;
});