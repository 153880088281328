define("apollo/pods/authorized/insurance/policy_version/benefits/root/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    downloadFile: Ember.inject.service('download-file'),
    apollo: Ember.inject.service('apollo'),
    ajax: Ember.inject.service('ajax'),
    queryParams: ['headerless'],
    session: Ember.inject.service('session'),
    notifications: Ember.inject.service('notifications'),
    showInsuranceOption: Ember.computed.bool('current.user.hasInsuranceRole'),
    showCodingOption: Ember.computed.bool('current.user.hasCodingRole'),
    headerless: null,
    currentBenefit: null,
    benefitdiagnostics: null,
    coverages: null,
    showPopup: false,
    existingDiagnostic: null,
    text: null,
    existingCoverage: null,
    tabs: [{
      id: 'coverage',
      title: 'Coverages'
    }],
    benefitLinks: [],
    queryLinks: null,
    pagination: {
      benefits: {
        page_size: 5,
        page_current: 1,
        data_size: 0
      },
      links: {
        page_size: 5,
        page_current: 1,
        data_size: 0
      }
    },
    sort: {},
    title: Ember.computed('popup', function () {
      switch (Ember.get(this, 'popup')) {
        case 'L':
          return 'Link Benefit Level';

        case 'N':
          return 'Create Benefit';

        case 'E':
          return 'Edit Benefit';

        default:
          return 'Generic Title';
      }
    }),
    readOnly: Ember.computed(function () {
      return Ember.get(this, 'headerless');
    }),

    loadBenefitPages(page, recalculate, filter) {
      let version = Ember.get(this, 'policyVersion');
      let pageSize = Ember.get(this, "pagination.benefits.page_size");
      Ember.get(this, 'store').query('policy', {
        endpointURI: "policy-versions/".concat(version.id, "/benefits/_search?page_number=").concat(page, "&page_size=").concat(pageSize),
        filter
      }).then(benefits => {
        Ember.set(this, 'benefits', benefits);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          endpointURI: "policy-versions/".concat(version.id, "/benefits/pages-total?page_size=").concat(pageSize),
          filter
        }).then(response => {
          Ember.set(this, 'pagination.benefits.data_size', response.size);
        });
      }
    },

    loadLinkPages(page, recalculate, filter) {
      let version = Ember.get(this, 'policyVersion');
      let pageSize = Ember.get(this, "pagination.links.page_size");
      Ember.get(this, 'store').query('benefit-link', {
        endpointURI: "policy-versions/".concat(version.id, "/benefit-links/_search"),
        page_number: page,
        page_size: pageSize
      }).then(data => {
        Ember.set(this, 'benefitLinks', data);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          endpointURI: "policy-versions/".concat(version.id, "/benefit-links/pages-total?page_size=").concat(pageSize),
          filter
        }).then(response => {
          Ember.set(this, 'pagination.links.data_size', response.size);
        });
      }
    },

    actions: {
      showPolicyInfo() {
        Ember.set(this, 'collapsePolicyInfo', !Ember.get(this, 'collapsePolicyInfo'));
      },

      //Toolbar Actions
      filterBenefits(text) {
        this.loadBenefitPages(1, true, {
          code: {
            ilike: text
          },
          name: {
            or_ilike: text
          }
        });
      },

      download(version) {
        this.downloadFile.request("policy-versions/".concat(version.id, "/download"), {
          filename: "policy_".concat(version.policyName, ".xlsx")
        });
        return false;
      },

      addLevel() {
        let policyVersion = Ember.get(this, 'policyVersion');
        let level = Ember.get(this, 'store').createRecord('benefit-link', {
          policyVersion
        });
        Ember.set(this, 'level', level);
        Ember.set(this, 'popup', 'L');
      },

      newRecord() {
        let policyVersion = Ember.get(this, 'policyVersion');
        let benefit = Ember.get(this, 'store').createRecord('benefit', {
          policyVersion
        });
        Ember.set(this, 'benefit', benefit);
        Ember.set(this, 'popup', 'N');
      },

      editLine(benefit) {
        Ember.set(this, 'benefit', benefit);
        Ember.set(this, 'popup', 'E');
      },

      //Popup options
      closePopup() {
        let benefit = Ember.get(this, 'benefit');

        if (benefit) {
          benefit.rollbackAttributes();
        }

        let level = Ember.get(this, 'level');

        if (level) {
          level.rollbackAttributes();
        }

        Ember.set(this, 'popup', null);
        Ember.set(this, 'benefit', null);
        Ember.set(this, 'level', null);
      },

      onSaveRecord() {
        let notifications = Ember.get(this, 'notifications');
        let benefit = Ember.get(this, 'benefit');

        if (benefit) {
          return benefit.save().then(() => {
            this.loadBenefitPages(1, true);
            return notifications.show('success', 'Benefit saved successfully');
          }).catch(response => {
            let message = response.errors.map(error => error.detail).join(", ");
            return notifications.show('error', message);
          });
        }

        let level = Ember.get(this, 'level');

        if (level) {
          return level.save().then(() => {
            this.loadLinkPages(1, true);
            return notifications.show('success', 'Link benefits successfully');
          }).catch(response => {
            let message = response.errors.map(error => error.detail).join(", ");
            return notifications.show('error', message);
          });
        }
      },

      onDeleteBenefit(record) {
        let notifications = Ember.get(this, 'notifications');
        record.destroyRecord().then(() => {
          return notifications.show('success', 'benefits successfully deleted!');
        }).catch(response => {
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      onUnLink(record) {
        let notifications = Ember.get(this, 'notifications');
        record.destroyRecord().then(() => {
          return notifications.show('success', 'Un-Link benefits successfully');
        });
      },

      onChangePageBenefits(page, recalculate) {
        this.loadBenefitPages(page, recalculate);
      },

      onChangePageLinks(page, recalculate) {
        this.loadLinkPages(page, recalculate);
      },

      /*deleteRecord(record) {
        record.destroyRecord().then(() => {
          //this.loadPages(benefit, get(this, 'page_current'));
        });
      },*/
      update(benefit) {
        benefit.save();
        return false;
      },

      onSelect(benefit) {
        Ember.set(this, 'currentBenefit', benefit);
      },

      sortBy(sort) {
        Ember.set(this, "sort", sort);
        this.loadPages(1, true);
      },

      onLink(level, cBpartnerId, selected) {
        if (level) {
          Ember.set(level, 'policyLevel', selected);
        }
      },

      onOpen(link) {
        Ember.set(link, 'collapse', !Ember.get(link, 'collapse'));
      }

    }
  });

  _exports.default = _default;
});