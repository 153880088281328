define("apollo/pods/components/search/policy-version/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "item-line": "_item-line_1837r6",
    "code": "_code_1837r6"
  };
  _exports.default = _default;
});