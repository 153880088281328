define("apollo/pods/components/policies/policy-version-form/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "policy-form": "_policy-form_i62i6b",
    "policy-new-title": "_policy-new-title_i62i6b",
    "form-buttons": "_form-buttons_i62i6b",
    "form-inputs": "_form-inputs_i62i6b"
  };
  _exports.default = _default;
});