define("apollo/pods/authorized/claims/encounter/charges/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let encounter = this.modelFor('authorized.claims.encounter');
      return Ember.RSVP.hash({
        encounter,
        charges: encounter.charges,
        policyVersion: encounter.policyVersion
      });
    },

    setupController(controller, model) {
      Ember.set(controller, 'encounter', model.encounter);
      Ember.set(controller, 'policyVersion', model.policyVersion);
      Ember.set(controller, 'charges', model.charges);
    }

  });

  _exports.default = _default;
});