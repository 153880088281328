define("apollo/pods/components/apollo/window/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+s1SO4cS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"popup \",[25,[\"height\"]]],null]],[[\"from\"],[\"apollo/pods/components/apollo/window/styles\"]]]]]],[9],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"popup-header\"],[[\"from\"],[\"apollo/pods/components/apollo/window/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"title\"],false],[0,\"\\n    \"],[7,\"button\"],[12,\"class\",[30,[[29,\"local-class\",[\"popup-close\"],[[\"from\"],[\"apollo/pods/components/apollo/window/styles\"]]]]]],[9],[0,\"x\"],[3,\"action\",[[24,0,[]],[25,[\"close\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"Toolbar\"],[[29,\"component\",[\"apollo/window/toolbar\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"popup-content\"],[[\"from\"],[\"apollo/pods/components/apollo/window/styles\"]]]]]],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"Content\"],[[29,\"component\",[\"blank-template\"],null]]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/window/template.hbs"
    }
  });

  _exports.default = _default;
});