define("apollo/pods/components/forms/currency-text/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_5wmerf _input_1dnopz",
    "label": "_label_5wmerf _label_1dnopz",
    "form-input": "_form-input_5wmerf _form-input_1dnopz",
    "required": "_required_5wmerf _required_1dnopz",
    "expand-eight": "_expand-eight_5wmerf _expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_5wmerf _expand-quarter_1dnopz",
    "expand-half": "_expand-half_5wmerf _expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_5wmerf _expand-th-q_1dnopz",
    "expand-full": "_expand-full_5wmerf _expand-full_1dnopz",
    "left-label": "_left-label_5wmerf _left-label_1dnopz",
    "right-label": "_right-label_5wmerf _right-label_1dnopz",
    "bottom-label": "_bottom-label_5wmerf _bottom-label_1dnopz",
    "input-error": "_input-error_5wmerf _input-error_1dnopz",
    "error-message": "_error-message_5wmerf _error-message_1dnopz",
    "input-text-mask": "_input-text-mask_5wmerf _input_1dnopz",
    "input-button": "_input-button_5wmerf _input_1dnopz",
    "currency": "_currency_5wmerf",
    "percent": "_percent_5wmerf",
    "inverted": "_inverted_5wmerf"
  };
  _exports.default = _default;
});