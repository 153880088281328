define("apollo/pods/authorized/insurance/policy/edit/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    disablePriceLists: false,
    notifications: Ember.inject.service('notifications'),
    canChangeMinorCompany: false,
    readOnly: Ember.computed('policy', function () {
      return Ember.get(this, 'policy.pType') === 'Donation';
    }),
    actions: {
      savePolicy(policy, policyVersion) {
        let notifications = Ember.get(this, 'notifications');
        let id = Ember.get(policy, 'company.id');
        return policy.save().then(p => {
          Ember.set(policyVersion, 'policy', p);
          return policyVersion.save().catch(response => {
            let message = response.errors.map(error => error.detail).join(", ");
            return notifications.show('error', message);
          });
        }).then(pv => {
          Ember.get(policy, 'policyVersions').pushObject(pv);
          this.send('goBack', id);
          return this.notifications.show('success', 'Policy edited successfully', 5);
        }).catch(response => {
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      setPolicyType(policy, type) {
        Ember.set(policy, 'pType', type.value);

        if (type.value === 'Corporate') {
          Ember.set(policy, 'minorCompany', null);
          Ember.set(policy, 'minorCompanyCode', null);
          Ember.set(this, 'canChangeMinorCompany', true);
        } else {
          Ember.set(policy, 'minorCompany', 'Policy Holder');
          Ember.set(policy, 'minorCompanyCode', 1);
          Ember.set(this, 'canChangeMinorCompany', false);
        }
      },

      setMinorCompany(policy, companyId, company) {
        if (company) {
          Ember.set(policy, 'minorCompany', company.name);
          Ember.set(policy, 'minorCompanyCode', company.value);
        } else {
          Ember.set(policy, 'minorCompany', null);
          Ember.set(policy, 'minorCompanyCode', null);
        }
      },

      setLinkedPriceList(version, priceList) {
        Ember.set(version, 'priceListId', priceList.id);
        Ember.set(version, 'currency', priceList.currency);
        Ember.set(this, 'currencies', [{
          text: priceList.currency,
          value: priceList.currency
        }]);
      },

      findByQueryCompany(query) {
        let orgId = Ember.get(this, 'current.user.orgId');
        let systemId = Ember.get(this, 'current.user.client.systemId');

        if (query.length >= 3) {
          let minorCompanies = Ember.get(this, 'store').query('business-partner', {
            orgId,
            systemId,
            query,
            minor: true
          });
          Ember.set(this, 'minorCompanies', minorCompanies);
        }

        if (query.length === 0) {
          Ember.set(this, 'minorCompanies', null);
        }
      },

      goBack(id) {
        return this.transitionToRoute('authorized.insurance.company.policies', id);
      }

    }
  });

  _exports.default = _default;
});