define("apollo/helpers/uc-words", ["exports", "ember-string-helpers/helpers/uc-words"], function (_exports, _ucWords) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _ucWords.default;
    }
  });
  Object.defineProperty(_exports, "ucWords", {
    enumerable: true,
    get: function () {
      return _ucWords.ucWords;
    }
  });
});