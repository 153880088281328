define("apollo/pods/components/apollo/badage/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P2lo0XTc",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"badage-item\"],[[\"from\"],[\"apollo/pods/components/apollo/badage/styles\"]]]]]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"tooltip\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"badage-tooltip\"],[[\"from\"],[\"apollo/pods/components/apollo/badage/styles\"]]]]]],[9],[1,[23,\"tooltip\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/badage/template.hbs"
    }
  });

  _exports.default = _default;
});