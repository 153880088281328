define("apollo/pods/authorized/coding/companies/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "code": "_code_193lyc",
    "name": "_name_193lyc",
    "default-render": "_default-render_193lyc",
    "space-between": "_space-between_193lyc"
  };
  _exports.default = _default;
});