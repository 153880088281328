define("apollo/pods/components/format-label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    localClassNames: ['currency-label'],
    type: 'currency',
    currency: {
      prefix: ' $ ',
      suffix: ''
    },
    formated: Ember.computed('value', 'type', function () {
      let type = Ember.get(this, 'type');
      let value = Ember.get(this, 'value');
      let currency = Ember.get(this, 'currency');

      if (type === 'currency') {
        value = "".concat(currency.prefix).concat(((value || 0) / 100.00).toFixed(2)).concat(currency.suffix);
      } else if (type === 'percent') {
        value = "".concat((value || 0) / 100, "%");
      } else if (type === 'refList') {
        let data = Ember.get(this, 'data');

        if (data) {
          value = data.filter(item => item.value === value);

          if (value && value.length > 0) {
            value = value[0].name;
          } else {
            value = Ember.get(this, 'value');
          }
        }
      } else if (type === 'refListMultiple') {
        let data = Ember.get(this, 'data');

        if (data && value) {
          value = data.filter(item => value.split(' ').includes(item.value));

          if (value && value.length > 0) {
            value = value.map(item => item.name).join(", ");
          }
        }
      }

      return value;
    })
  });

  _exports.default = _default;
});