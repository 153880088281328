define("apollo/pods/components/search-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['search-box'],
    localClassNameBindings: ['designClass', 'grow', 'labelPosition'],
    focused: true,
    grow: false,
    design: 'default',
    designClass: Ember.computed('design', function () {
      return Ember.get(this, 'design') || 'default';
    }),
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + "-label";
    }),
    actions: {
      chooseOption(item) {
        let onSelect = Ember.get(this, 'onSelect');

        if (Ember.get(this, 'design') !== "list") {
          Ember.set(this, 'value', '');
          Ember.set(this, 'focused', false);
        }

        return onSelect(item);
      },

      setFocus(focused) {
        Ember.set(this, 'focused', focused);
      }

    }
  });

  _exports.default = _default;
});