define("apollo/pods/components/widgets/x-button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_1jjj2e",
    "ripple": "_ripple_1jjj2e",
    "default": "_default_1jjj2e",
    "save": "_save_1jjj2e",
    "send": "_send_1jjj2e",
    "cancel": "_cancel_1jjj2e",
    "import": "_import_1jjj2e",
    "tiny_small": "_tiny_small_1jjj2e",
    "small": "_small_1jjj2e",
    "big": "_big_1jjj2e",
    "flat": "_flat_1jjj2e"
  };
  _exports.default = _default;
});