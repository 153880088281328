define("apollo/pods/authorized/imports/diagnostics/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "import-layout": "_import-layout_1f2t38",
    "import-layout-header": "_import-layout-header_1f2t38",
    "import-layout-toolbar": "_import-layout-toolbar_1f2t38",
    "badage": "_badage_1f2t38",
    "badage-error": "_badage-error_1f2t38",
    "badage-ok": "_badage-ok_1f2t38",
    "form-inputs": "_form-inputs_1f2t38",
    "form-actions": "_form-actions_1f2t38",
    "sections": "_sections_1f2t38",
    "loading-sceen": "_loading-sceen_1f2t38"
  };
  _exports.default = _default;
});