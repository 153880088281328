define("apollo/pods/components/forms/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "form-input": "_form-input_1dnopz",
    "label": "_label_1dnopz",
    "required": "_required_1dnopz",
    "input": "_input_1dnopz",
    "expand-eight": "_expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_1dnopz",
    "expand-half": "_expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_1dnopz",
    "expand-full": "_expand-full_1dnopz",
    "left-label": "_left-label_1dnopz",
    "right-label": "_right-label_1dnopz",
    "bottom-label": "_bottom-label_1dnopz",
    "input-error": "_input-error_1dnopz",
    "error-message": "_error-message_1dnopz"
  };
  _exports.default = _default;
});