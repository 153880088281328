define("apollo/mirage/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    this.passthrough('/write-coverage');
    this.namespace = '/api';
    this.post('/token', function () {
      return {
        access_token: '123',
        refresh_token: 'ABC',
        token_type: 'bearer'
      };
    });
    this.get('/users/me', function (schema) {
      return schema.users.first();
    });
    this.get('/clients/:id');
    this.get('/companies/:id');
    this.get('/companies/:id/policies');
    this.get('/policies/:id');
    this.get('/policy-versions/:id');
    this.get('/policy-versions/:id/benefits');
    this.get('/policy-versions/:id/benefits/_search');
    this.get('/policy-versions/:id/benefits/pages-total');
    this.get('/policy-versions/:id/benefit-links/_search');
    this.get('/policy-versions/:id/benefit-links/pages-total');
    this.get('/price-lists/:id/products');
    this.get('/service-stations');
    this.get('/benefits', function (schema, request) {
      let policyVersionId = request.queryParams.policy_version_id || '1';
      let code = request.queryParams['filter[code][or_ilike]'] || '666';
      let name = request.queryParams['filter[name][or_ilike]'] || 'Maternidad';
      return schema.benefits.where({
        policyVersionId,
        code,
        name
      });
    });
    this.get('/companies/find', function (schema, request) {
      let name = request.queryParams['filter[code][or_ilike]'] || 'Apollo Company';
      return schema.companies.where({
        name
      });
    });
    this.post('/policies');
    this.post('/policy-versions');
  }
});