define("apollo/pods/components/apollo/badage/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "badage": "_badage_16uq93",
    "badage-item": "_badage-item_16uq93",
    "color-primary": "_color-primary_16uq93",
    "color-secondary": "_color-secondary_16uq93",
    "color-gray": "_color-gray_16uq93",
    "color-cancel": "_color-cancel_16uq93",
    "color-pdf": "_color-pdf_16uq93",
    "color-excel": "_color-excel_16uq93",
    "design-default": "_design-default_16uq93",
    "badage-tooltip": "_badage-tooltip_16uq93"
  };
  _exports.default = _default;
});