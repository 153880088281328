define("apollo/pods/authorized/coding/route", ["exports", "apollo/mixins/coding-role-mixin"], function (_exports, _codingRoleMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_codingRoleMixin.default, {
    beforeModel(route) {
      this._super(...arguments);

      if (route.targetName === "authorized.coding.index") {
        this.transitionTo('authorized.coding.companies');
      }
    }

  });

  _exports.default = _default;
});