define("apollo/pods/components/search/price-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notifications'),
    tagName: '',
    store: Ember.inject.service(),
    records: [],
    currentText: "",
    icon: "search",

    init() {
      this._super(...arguments);

      this.set('errors', []);
      let value = Ember.get(this, 'value');

      if (value) {
        Ember.get(this, 'store').findRecord('price-list', Ember.get(this, 'value')).then(record => {
          Ember.set(this, 'records', [record]);
          Ember.set(this, 'currentText', record.name);
        });
      }
    },

    actions: {
      onChange(query) {
        let companyValue = Ember.get(this, 'companyValue');

        if (query && query.length >= 3 && companyValue) {
          let params = {
            company_value: companyValue
          };
          Ember.get(this, 'store').query('price-list', params).then(records => {
            Ember.set(this, 'records', records);
          });
        } else {
          Ember.set(this, 'records', null);
        }
      },

      onSelect(selected) {
        Ember.set(this, 'currentText', selected.name);
        let onChange = Ember.get(this, 'onChange');

        if (onChange) {
          onChange(parseInt(selected.id, 10), selected);
        }
      }

    }
  });

  _exports.default = _default;
});