define("apollo/pods/components/forms/input-combo/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "li",
    localClassNames: ["combo-item"],
    innerList: null,
    item: null,
    multiple: true,
    multiline: false,
    checked: Ember.computed('innerList', 'item', function () {
      let innerList = Ember.get(this, 'innerList');
      let item = Ember.get(this, 'item');

      if (item && innerList && innerList.includes(item)) {
        return true;
      }

      return false;
    }),

    click() {
      //this.toggleProperty("checked");
      let innerList = Ember.get(this, 'innerList');
      let item = Ember.get(this, 'item');
      let chooseOption = Ember.get(this, "chooseOption");

      if (chooseOption) {
        chooseOption(item, !innerList.includes(item));
      }
    }

  });

  _exports.default = _default;
});