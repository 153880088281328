define("apollo/pods/components/field/check-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "label": "_label_1hn09t _label_lqr8xx",
    "input-readonly": "_input-readonly_1hn09t _input-readonly_lqr8xx",
    "form-input": "_form-input_1hn09t _form-input_lqr8xx",
    "required": "_required_1hn09t _required_lqr8xx",
    "expand-stretch": "_expand-stretch_1hn09t",
    "icon": "_icon_1hn09t _input_lqr8xx",
    "input-checkbox": "_input-checkbox_1hn09t",
    "color-primary": "_color-primary_1hn09t",
    "color-secondary": "_color-secondary_1hn09t",
    "color-gray": "_color-gray_1hn09t",
    "color-green": "_color-green_1hn09t",
    "color-yellow": "_color-yellow_1hn09t",
    "color-cancel": "_color-cancel_1hn09t",
    "color-pdf": "_color-pdf_1hn09t",
    "color-excel": "_color-excel_1hn09t",
    "input": "_input_1hn09t",
    "design-default": "_design-default_1hn09t",
    "checked": "_checked_1hn09t",
    "input-checkbox-icon": "_input-checkbox-icon_1hn09t",
    "design-circle": "_design-circle_1hn09t"
  };
  _exports.default = _default;
});