define("apollo/helpers/localize", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    hermes: Ember.inject.service('hermes'),

    compute([key, ...params]) {
      return this.hermes.searchLabel(key, ...params);
    }

  });

  _exports.default = _default;
});