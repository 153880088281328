define("apollo/pods/authorized/insurance/company/policies/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let {
        type
      } = params;
      let company = this.modelFor('authorized.insurance.company');
      let data = {
        company
      };

      if (type !== 'Policy') {
        data = {
          company,
          pType: type,
          minorCompany: company.name,
          minorCompanyCode: company.value
        };
      }

      let priceListId = null;

      if (type === 'Level') {
        priceListId = -1;
      }

      return Ember.RSVP.hash({
        company,
        priceLists: company.priceLists,
        policy: Ember.get(this, 'store').createRecord('policy', data),
        policyVersion: Ember.get(this, 'store').createRecord('policyVersion', {
          startDate: new Date(),
          commencementDate: new Date(),
          priceListId
        })
      });
    },

    setupController: (controller, model) => {
      Ember.set(controller, 'model', model);
      Ember.set(controller, 'policy', model.policy);
      Ember.set(controller, 'policyVersion', model.policyVersion);
      Ember.set(controller, 'priceLists', model.priceLists);

      if (model.priceLists.length === 0) {
        Ember.set(controller, 'disablePriceLists', true);
      }

      model.priceLists.forEach(list => {
        if ("".concat(model.company.priceListId) === "".concat(list.id)) {
          Ember.set(controller, 'policyVersion.priceListId', list.id);
          Ember.set(controller, 'policyVersion.currency', list.currency);
        }
      });
    },
    actions: {
      willTransition() {
        let policy = Ember.get(this.controller, 'policy');
        let policyVersion = Ember.get(this.controller, 'policyVersion');

        if (Ember.get(policy, 'isNew')) {
          policy.rollbackAttributes();
        }

        if (Ember.get(policyVersion, 'isNew')) {
          policyVersion.rollbackAttributes();
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});