define("apollo/pods/authorized/insurance/policy_version/benefits/diagnostics/controller", ["exports", "apollo/config/environment", "apollo/pods/objects/ref-list", "apollo/pods/objects/export-manager"], function (_exports, _environment, _refList, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    apollo: Ember.inject.service('apollo'),
    session: Ember.inject.service('session'),
    total_records: 0,
    benefit: null,
    editBenefit: false,
    collapsePolicyInfo: false,
    page_size: 15,
    page_current: 1,
    data_size: 0,
    settings: Ember.computed(function () {
      let token = Ember.get(this, 'session.data.authenticated.access_token');
      return {
        'headers': {
          'Authorization': "Bearer ".concat(token)
        }
      };
    }),
    frequenciesList: Ember.computed(function () {
      return _refList.default.create().getList("limitFrequency");
    }),
    targetList: Ember.computed(function () {
      return _refList.default.create().getList("limitTarget");
    }),
    canAddDiagnosis: Ember.computed('benefit', 'benefit.isNew', function () {
      return !Ember.get(this, 'benefit.isNew');
    }),

    loadCurrent(benefits) {
      let current = Ember.get(this, 'benefit');

      if (!current) {
        if (benefits.length > 0) {
          current = benefits.firstObject;
        } else {
          let policyVersion = Ember.get(this, 'policyVersion');
          let data = {
            policyVersion,
            name: '[default]'
          };
          current = Ember.get(this, 'store').createRecord('benefit', data);
          Ember.set(this, 'editBenefit', true);
        }

        Ember.set(this, 'benefit', current);
      }

      Ember.set(this, 'limitRule', Ember.get(this, 'store').createRecord('limitRule'));
    },

    loadPages(page, recalculate) {
      let benefit = Ember.get(this, 'benefit');

      if (benefit.id) {
        let pageSize = Ember.get(this, 'page_size'); //let filter = {}; //this.getFilters();

        Ember.get(this, 'store').query('importPolicy', {
          //filter,
          endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostics?page_number=").concat(page, "&page_size=").concat(pageSize)
        }).then(diagnostics => {
          Ember.set(this, 'diagnostics', diagnostics);
        });

        if (recalculate) {
          Ember.get(this, 'store').queryRecord('page-number', {
            //filter,
            endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostics/pages-total?page_size=").concat(pageSize)
          }).then(response => {
            Ember.set(this, 'total_records', response.total);
            Ember.set(this, 'data_size', response.size);
          });
        }
      } else {
        Ember.set(this, 'diagnostics', null);
        Ember.set(this, 'data_size', 0);
      }
    },

    actions: {
      changePage(page, pageSize, recalculate) {
        if (pageSize > 0) {
          Ember.set(this, 'page_size', pageSize);
        }

        this.loadPages(page, recalculate);
      },

      showEditBenefit() {
        Ember.set(this, 'editBenefit', !Ember.get(this, 'editBenefit'));
      },

      showPolicyInfo() {
        Ember.set(this, 'collapsePolicyInfo', !Ember.get(this, 'collapsePolicyInfo'));
      },

      downloadLayout(value) {
        let manager = _exportManager.default.create();

        switch (value) {
          case 1:
            // Current
            manager.download('diagnostics-export.csv', manager.fileFrom('diagnosis', Ember.get(this, 'diagnostics')));
            break;

          case 0: //Empty

          default:
            manager.download('diagnostics-export.csv', manager.emptyFile('diagnosis'));
        }
      },

      saveBenefit() {
        let benefit = Ember.get(this, 'benefit');
        let notifications = Ember.get(this, 'notifications');
        return benefit.save().then(() => {
          return notifications.show('success', 'Benefit saved successfully');
        }).catch(response => {
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      changeBenefit(benefit) {
        if (!benefit) {
          let policyVersion = Ember.get(this, 'policyVersion');
          let data = {
            policyVersion,
            name: '[default]'
          };
          benefit = Ember.get(this, 'store').createRecord('benefit', data);
          Ember.set(this, 'editBenefit', true);
        }

        Ember.set(this, 'benefit', benefit);
        this.loadPages(1, true);
      },

      deleteBenefit() {
        let notifications = Ember.get(this, 'notifications');
        return notifications.show('error', 'Try to delete benefit');
      },

      async onSelectDiagnostic(value, item) {
        if (item) {
          let benefit = Ember.get(this, 'benefit');
          let notifications = Ember.get(this, 'notifications');
          let store = Ember.get(this, 'store');

          if (Ember.get(benefit, 'isNew')) {
            notifications.show('warning', 'First save benefit header data.');
            return false;
          }

          let existing = await store.queryRecord('benefit-diagnostic', {
            endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostic/find?code=").concat(item.code)
          });

          if (existing) {
            Ember.set(this, 'showPopupDiagConfExists', true);
            Ember.set(this, 'existingDiagnostic', existing);
            return false;
          }

          let benefitDiagnostic = store.createRecord('benefitDiagnostic', {
            benefit,
            name: item.name,
            helixId: item.id,
            helixCode: item.code
          });
          return benefitDiagnostic.save().then(() => {
            notifications.show('success', 'Diagnostic saved!');
            this.loadPages(Ember.get(this, 'page_current'), true);
          });
        }

        return false;
      },

      updateDiagnostic(diagnostic) {
        return diagnostic.save().then(() => {
          return true;
        }).catch(response => {
          let notifications = Ember.get(this, 'notifications');
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      deleteDiagnostic(diagnostic) {
        let notifications = Ember.get(this, 'notifications');
        diagnostic.destroyRecord().then(() => {
          notifications.show('success', 'Diagnostic successfully deleted.');
          this.loadPages(Ember.get(this, 'page_current'), true);
        }).catch(() => {
          diagnostic.rollbackAttributes();
          notifications.show('error', 'Diagnostic delete failed!');
        });
      },

      changeRadioValue(model, changedField, relatedField, value) {
        Ember.set(model, changedField, value);
        Ember.set(model, relatedField, false);
        this.send("updateDiagnostic", model);
        return false;
      },

      uploadLayout(file) {
        let benefit = Ember.get(this, 'benefit');
        let url = "".concat(_environment.default.APP.apiUrl, "/benefits/").concat(Ember.get(benefit, 'id'), "/benefit-diagnostics");
        let settings = Ember.get(this, 'settings');
        let notifications = Ember.get(this, 'notifications');
        return file.upload(url, settings).then(() => {
          this.loadPages(1, true);
          return notifications.show('success', 'Diagnostics imported');
        }).catch(error => {
          Ember.set(file, 'queue.files', []);

          if (error.status === 500) {
            return notifications.show('error', 'Error, File format not valid');
          } else {
            return notifications.show('error', 'Error, Diagnostics not imported');
          }
        });
      }

    }
  });

  _exports.default = _default;
});