define("apollo/pods/components/filter/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-box": "_search-box_1u8kfa",
    "grow": "_grow_1u8kfa",
    "search-icon": "_search-icon_1u8kfa",
    "search-input": "_search-input_1u8kfa _input_1dnopz",
    "item-list": "_item-list_1u8kfa",
    "element-item": "_element-item_1u8kfa",
    "default": "_default_1u8kfa",
    "label": "_label_1u8kfa _label_1dnopz",
    "left-label": "_left-label_1u8kfa",
    "right-label": "_right-label_1u8kfa",
    "bottom-label": "_bottom-label_1u8kfa"
  };
  _exports.default = _default;
});