define("apollo/pods/components/charges-panel/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showPopup: false,
    title: '',
    approvedCharges: Ember.computed('model.@each.status', function () {
      return Ember.get(this, 'model').filter(charge => {
        return Ember.get(charge, 'status') === 'authorized';
      });
    }),
    rejectedCharges: Ember.computed('model.@each.status', function () {
      return Ember.get(this, 'model').filter(charge => {
        return Ember.get(charge, 'status') !== 'authorized';
      });
    }),
    actions: {
      closePopup() {
        Ember.set(this, 'showPopup', false);
      },

      openPopup(title, currentCharge) {
        Ember.set(this, 'title', title);
        Ember.set(this, 'currentCharge', currentCharge);
        Ember.set(this, 'showPopup', true);
      }

    }
  });

  _exports.default = _default;
});