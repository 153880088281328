define("apollo/mirage/factories/user", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    email: 'meep@meep.com',
    role: 'IC',
    password: 'password'
  });

  _exports.default = _default;
});