define("apollo/models/policy", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-api-actions"], function (_exports, _model, _attr, _relationships, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    identifier: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountFloat: Ember.computed('amount', {
      get() {
        return (Ember.get(this, 'amount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'amount', value * 100);
        return value;
      }

    }),
    pType: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    company: (0, _relationships.belongsTo)('company'),
    currentVersion: (0, _relationships.belongsTo)('policy-version'),
    policyVersions: (0, _relationships.hasMany)('policy-versions'),
    policyLogs: (0, _relationships.hasMany)('policy-logs'),
    minorCompany: (0, _attr.default)('string'),
    minorCompanyCode: (0, _attr.default)('string'),
    clone: (0, _emberApiActions.memberAction)({
      path: 'clone'
    })
  });

  _exports.default = _default;
});