define("apollo/pods/authorized/insurance/company/policies/root/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    notifications: Ember.inject.service('notifications'),

    model() {
      let company = this.modelFor('authorized.insurance.company');
      return Ember.RSVP.hash({
        company,
        serviceStations: Ember.get(this, 'store').findAll('service-station').catch(() => {
          this.notifications.show('error', 'Error: Services Stations not Found!');
        })
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      Ember.set(controller, 'company', model.company);
      Ember.set(controller, 'serviceStations', model.serviceStations);
      controller.loadPages(1, true);
    }

  });

  _exports.default = _default;
});