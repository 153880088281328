define("apollo/pods/authorized/imports/deductibles/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "import-layout": "_import-layout_1t33xh",
    "import-layout-header": "_import-layout-header_1t33xh",
    "import-layout-toolbar": "_import-layout-toolbar_1t33xh",
    "badage": "_badage_1t33xh",
    "badage-error": "_badage-error_1t33xh",
    "badage-ok": "_badage-ok_1t33xh",
    "form-inputs": "_form-inputs_1t33xh",
    "form-actions": "_form-actions_1t33xh",
    "sections": "_sections_1t33xh",
    "loading-sceen": "_loading-sceen_1t33xh"
  };
  _exports.default = _default;
});