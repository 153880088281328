define("apollo/pods/components/tab-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Okg7M5B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"or\",[[25,[\"showInsuranceOption\"]],[25,[\"showCodingOption\"]]],null]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/components/tab-menu/styles\"]]]],null],\"authorized.insurance\"]],{\"statements\":[[0,\"Insurance Policy Manager\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"showClaimOption\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/components/tab-menu/styles\"]]]],null],\"authorized.claims\"]],{\"statements\":[[0,\"Insurance Charges Manager\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"showCodingOption\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/components/tab-menu/styles\"]]]],null],\"authorized.coding\"]],{\"statements\":[[0,\"Diagnostics and Coding\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[25,[\"showImportOption\"]]],null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/components/tab-menu/styles\"]]]],null],\"authorized.imports\"]],{\"statements\":[[0,\"Import Policies\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/tab-menu/template.hbs"
    }
  });

  _exports.default = _default;
});