define("apollo/services/hermes", ["exports", "apollo/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    labels: {},
    notifications: Ember.inject.service('notifications'),
    language: 'en',

    async loadTags(language) {
      if (!language) {
        language = 'en';
      }

      let labels = await (0, _fetch.default)("".concat(_environment.default.APP.baseUrl, "/api/labels?language=").concat(language)).then(response => response.json()).catch(() => {
        if (_environment.default.environment !== 'test') {
          this.notifications.show("error", "Hermes is down!", 5);
        }

        return Ember.get(this, 'labels');
      });
      Ember.set(this, 'language', language);
      Ember.set(this, 'labels', labels);
    },

    searchLabel(key, ...params) {
      let labels = Ember.get(this, 'labels');
      let label = labels[key];

      if (label && params) {
        label = label.replace(/{(\d+)}/g, (match, number) => {
          return typeof params[number] !== 'undefined' ? params[number] : match;
        });
      }

      if (!label) {
        label = key;
      }

      return label;
    },

    async forceReload(language) {
      let labels = await (0, _fetch.default)("".concat(_environment.default.APP.baseUrl, "/api/labels?language=").concat(language, "&remove=true")).then(response => response.json()).catch(() => {
        if (_environment.default.environment !== 'test') {
          this.notifications.show("error", "Hermes is down!", 5);
        }

        return Ember.get(this, 'labels');
      });
      Ember.set(this, 'language', language);
      Ember.set(this, 'labels', labels);
    }

  });

  _exports.default = _default;
});