define("apollo/pods/authorized/imports/policies/controller", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    downloadFile: Ember.inject.service('download-file'),
    notifications: Ember.inject.service('notifications'),
    session: Ember.inject.service('session'),
    current_record: null,
    loading: false,
    page_size: 30,
    page_current: 1,
    settings: Ember.computed(function () {
      let token = Ember.get(this, 'session.data.authenticated.access_token');
      return {
        'headers': {
          'Authorization': "Bearer ".concat(token)
        }
      };
    }),

    loadPages(page, recalculate) {
      let pageSize = Ember.get(this, 'page_size');
      let filter = {}; //this.getFilters();

      Ember.get(this, 'store').query('importPolicy', {
        filter,
        endpointURI: "import-policies?page_number=".concat(page, "&page_size=").concat(pageSize)
      }).then(policies => {
        Ember.set(this, 'policies', policies);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          filter,
          endpointURI: "import-policies/pages-total?page_size=".concat(pageSize)
        }).then(response => {
          Ember.set(this, 'data_size', response.size);
        });
      }
    },

    afterProcessing() {
      let notifications = Ember.get(this, 'notifications');
      Ember.set(this, 'loading', false);
      this.send('changePage', Ember.get(this, 'page_current'), true);
      notifications.show('success', 'Process finish!');
    },

    actions: {
      uploadAction(file) {
        let url = "".concat(_environment.default.APP.apiUrl, "/import-policies");
        let settings = Ember.get(this, 'settings');
        let notifications = Ember.get(this, 'notifications');
        return file.upload(url, settings).then(() => {
          this.send('changePage', Ember.get(this, 'page_current'), true);
          return notifications.show('success', 'Policies loaded');
        }).catch(error => {
          Ember.set(file, 'queue.files', []);

          if (error.status === 500) {
            return notifications.show('error', 'Error, File format not valid');
          } else {
            return notifications.show('error', 'Error, Diagnostics not imported');
          }
        });
      },

      processAction() {
        Ember.set(this, 'loading', true);
        Ember.get(this, 'store').createRecord('importPolicy').process().then(() => this.afterProcessing()).catch(() => this.afterProcessing());
      },

      downloadAction(text) {
        return text;
      },

      setCurrent(policy) {
        Ember.set(this, "current_record", policy);
      },

      closePopup() {
        let current = Ember.get(this, "current_record");
        current.rollbackAttributes();
        Ember.set(this, "current_record", null);
      },

      onSave() {
        let current = Ember.get(this, "current_record");
        current.save();
      },

      onClean(cleanAll) {
        Ember.get(this, 'store').createRecord('importPolicy').clean({
          clean_all: cleanAll
        });
      },

      changePage(page, recalculate) {
        if (recalculate) {
          Ember.set(this, 'page_current', 1);
        }

        this.loadPages(page, recalculate);
      }

    }
  });

  _exports.default = _default;
});