define("apollo/pods/components/field/currency-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-text'],
    localClassNameBindings: ['labelPosition', 'inputDesign'],
    type: 'currency',
    design: 'input',
    placeholder: '',
    value: 0,
    currency: {
      separator: ".",
      prefix: " $ "
    },
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + "-label";
    }),
    inputDesign: Ember.computed('design', function () {
      return "design-".concat(Ember.get(this, 'design'));
    }),
    simbol: Ember.computed('type', function () {
      switch (Ember.get(this, 'type')) {
        case 'percent':
          return 'percent';

        default:
          return 'money-bill-alt';
      }
    }),
    actions: {
      click() {
        let click = Ember.get(this, 'onClick');

        if (click) {
          Ember.set(this, 'type', Ember.get(this, 'type') === "currency" ? "percent" : "currency");
          Ember.set(this, 'value', 0);
          return click(Ember.get(this, 'type'), event);
        }
      },

      focusOut(value) {
        let focusOut = Ember.get(this, 'onFocusOut');

        if (focusOut) {
          return focusOut(value, event);
        }
      },

      change(value) {
        Ember.set(this, 'value', value);
        let change = Ember.get(this, 'onChange');

        if (change) {
          change(value);
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});