define("apollo/pods/components/tab-menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "tab-menu": "_tab-menu_17qqkv",
    "menu-item": "_menu-item_17qqkv"
  };
  _exports.default = _default;
});