define("apollo/pods/components/dot-menu/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m1yeW3fn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"item\"],[[\"from\"],[\"apollo/pods/components/dot-menu/item/styles\"]]]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"stopPropagation\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/dot-menu/item/template.hbs"
    }
  });

  _exports.default = _default;
});