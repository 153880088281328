define("apollo/pods/components/widgets/x-button/component", ["exports", "apollo/pods/components/widgets/x-button/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['button'],
    classNameBindings: ['colorClass', 'sizeClass', 'designClass'],
    attributeBindings: ['disabled:disabled'],
    tagName: 'button',
    size: 'small',
    color: 'primary',
    design: 'default',
    colorClass: Ember.computed('color', function () {
      return _styles.default[Ember.get(this, 'color')] || _styles.default.primary;
    }),
    designClass: Ember.computed('design', function () {
      return _styles.default[Ember.get(this, 'design')] || _styles.default.default;
    }),
    sizeClass: Ember.computed('size', function () {
      return _styles.default[Ember.get(this, 'size')] || _styles.default.small;
    }),

    click() {
      return Ember.get(this, 'action')();
    }

  });

  _exports.default = _default;
});