define("apollo/pods/components/apollo/upload/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "uploading": "_uploading_4o7hkr",
    "button": "_button_4o7hkr",
    "ripple": "_ripple_4o7hkr",
    "upload-cursor": "_upload-cursor_4o7hkr",
    "color-primary": "_color-primary_4o7hkr",
    "color-secondary": "_color-secondary_4o7hkr",
    "color-gray": "_color-gray_4o7hkr",
    "color-cancel": "_color-cancel_4o7hkr",
    "color-pdf": "_color-pdf_4o7hkr",
    "color-excel": "_color-excel_4o7hkr",
    "design-default": "_design-default_4o7hkr",
    "design-ghost": "_design-ghost_4o7hkr",
    "design-simple": "_design-simple_4o7hkr",
    "size-menu": "_size-menu_4o7hkr",
    "size-square": "_size-square_4o7hkr",
    "size-tiny_small": "_size-tiny_small_4o7hkr",
    "size-small": "_size-small_4o7hkr",
    "size-normal": "_size-normal_4o7hkr",
    "size-big": "_size-big_4o7hkr"
  };
  _exports.default = _default;
});