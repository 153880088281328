define("apollo/helpers/get-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    hermes: Ember.inject.service('hermes'),

    compute() {
      let locale = this.hermes.language; //when ar_SA, es_MX, etc, is sended

      if (locale.length > 2) {
        locale = locale.substring(0, 2);
      }

      return locale;
    }

  });

  _exports.default = _default;
});