define("apollo/pods/components/apollo/table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "table": "_table_rao9hi",
    "design-default": "_design-default_rao9hi"
  };
  _exports.default = _default;
});