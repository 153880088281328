define("apollo/pods/components/forms/input-combo/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-box": "_search-box_1sya3y",
    "grow": "_grow_1sya3y",
    "search-icon": "_search-icon_1sya3y",
    "search-input": "_search-input_1sya3y _input_1dnopz",
    "item-list": "_item-list_1sya3y",
    "input": "_input_1sya3y _input_1dnopz",
    "label": "_label_1sya3y _label_1dnopz",
    "form-input": "_form-input_1sya3y _form-input_1dnopz",
    "required": "_required_1sya3y _required_1dnopz",
    "expand-eight": "_expand-eight_1sya3y _expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_1sya3y _expand-quarter_1dnopz",
    "expand-half": "_expand-half_1sya3y _expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_1sya3y _expand-th-q_1dnopz",
    "expand-full": "_expand-full_1sya3y _expand-full_1dnopz",
    "input-error": "_input-error_1sya3y _input-error_1dnopz",
    "error-message": "_error-message_1sya3y _error-message_1dnopz"
  };
  _exports.default = _default;
});