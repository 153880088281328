define("apollo/pods/components/field/text-area/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KpXjqbEE",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[25,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"label \",[29,\"if\",[[25,[\"required\"]],\"required\"],null]],null]],[[\"from\"],[\"apollo/pods/components/field/text-area/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"textarea\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"input \",[29,\"if\",[[25,[\"errors\"]],\"input-error\"],null],\" \",[29,\"if\",[[25,[\"readOnly\"]],\"input-readonly\"],null]],null]],[[\"from\"],[\"apollo/pods/components/field/text-area/styles\"]]]]]],[12,\"placeholder\",[23,\"placeholder\"]],[12,\"readonly\",[29,\"or\",[[25,[\"readonly\"]],[25,[\"readOnly\"]]],null]],[12,\"oninput\",[29,\"action\",[[24,0,[]],\"change\"],[[\"value\"],[\"target.value\"]]]],[12,\"rows\",[23,\"rows\"]],[9],[0,\"  \"],[1,[23,\"value\"],false],[0,\"\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"error-message\"],[[\"from\"],[\"apollo/pods/components/field/text-area/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/field/text-area/template.hbs"
    }
  });

  _exports.default = _default;
});