define("apollo/pods/components/apollo/menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['menu'],
    localClassNameBindings: ['openDirection'],
    attributeBindings: ['disabled:disabled'],
    tagName: 'div',
    open: false,
    direction: "right top",
    design: 'default',
    openDirection: Ember.computed('direction', function () {
      let direction = "".concat(Ember.get(this, 'direction')).split(' ');

      if (direction.includes('left') && direction.includes('bottom')) {
        return 'direction-left-bottom';
      } else if (direction.includes('left')) {
        return 'direction-left-top';
      } else if (direction.includes('bottom')) {
        return 'direction-right-bottom';
      } else {
        return 'direction-right-top';
      }
    }),

    click() {
      event.stopPropagation();
    },

    actions: {
      toggleMenu() {
        this.toggleProperty('open');
        return true;
      }

    }
  });

  _exports.default = _default;
});