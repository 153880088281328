define("apollo/pods/authorized/insurance/companies/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    actions: {
      saveCompany() {
        let notifications = Ember.get(this, 'notifications');
        let company = Ember.get(this, 'company');
        return company.save().then(() => {
          notifications.show('success', 'Company added successfully', 5);
          this.transitionToRoute('authorized.insurance.company.policies', company);
        }).catch(() => {
          notifications.show('error', "Company \"".concat(company.name, "\" is already added"), 5);
          let client = Ember.get(company, 'client');
          Ember.set(this, 'company', Ember.get(this, 'store').createRecord('company', {
            client
          }));
          company.rollbackAttributes();
        });
      },

      goBack() {
        return this.transitionToRoute('authorized.insurance.companies');
      }

    }
  });

  _exports.default = _default;
});