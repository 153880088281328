define("apollo/pods/components/draggable-item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['draggableItem'],
    localClassNames: ['draggable-item'],
    attributeBindings: ['draggable'],
    draggable: 'true',

    dragStart(event) {
      return event.dataTransfer.setData('text/data', Ember.get(this, 'content'));
    }

  });

  _exports.default = _default;
});