define("apollo/pods/components/insurance/diagnostic/popup/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "header": "_header_1rrf8p",
    "title": "_title_1rrf8p",
    "action": "_action_1rrf8p",
    "btn-upload": "_btn-upload_1rrf8p",
    "hidden-button": "_hidden-button_1rrf8p",
    "panel-group": "_panel-group_1rrf8p",
    "panel": "_panel_1rrf8p",
    "panel-action": "_panel-action_1rrf8p",
    "record": "_record_1rrf8p",
    "delete": "_delete_1rrf8p"
  };
  _exports.default = _default;
});