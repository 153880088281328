define("apollo/pods/components/forms/file-upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "czmp1njL",
    "block": "{\"symbols\":[\"queue\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"file-button\"],[[\"from\"],[\"apollo/pods/components/forms/file-upload/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"file-upload\",null,[[\"name\",\"accept\",\"onfileadd\",\"class\"],[[25,[\"uploadName\"]],[25,[\"uploadAccept\"]],[25,[\"uploadAction\"]],[29,\"concat\",[[29,\"local-class\",[\"file-button-left\"],[[\"from\"],[\"apollo/pods/components/forms/file-upload/styles\"]]]],null]]],{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"button \",[25,[\"colorClass\"]]],null]],[[\"from\"],[\"apollo/pods/components/forms/file-upload/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"if\",[[24,1,[\"files\",\"length\"]]],null,{\"statements\":[[0,\"        \"],[1,[23,\"loadingText\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[23,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[7,\"button\"],[12,\"class\",[30,[[29,\"local-class\",[\"file-button-right\"],[[\"from\"],[\"apollo/pods/components/forms/file-upload/styles\"]]]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toogleMenu\"],null]],[11,\"type\",\"button\"],[9],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[30,[[29,\"local-class\",[\"down-arrow\"],[[\"from\"],[\"apollo/pods/components/forms/file-upload/styles\"]]]]]],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[4,\"if\",[[25,[\"showMenu\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"file-menu\"],[[\"from\"],[\"apollo/pods/components/forms/file-upload/styles\"]]]]]],[9],[0,\"\\n    \"],[15,2],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/forms/file-upload/template.hbs"
    }
  });

  _exports.default = _default;
});