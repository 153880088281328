define("apollo/pods/components/charges-panel/table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "amount-panel": "_amount-panel_21e4n9",
    "subtitle": "_subtitle_21e4n9",
    "tr-exceededAmount": "_tr-exceededAmount_21e4n9",
    "discount": "_discount_21e4n9",
    "full-dropzone": "_full-dropzone_21e4n9",
    "no-data": "_no-data_21e4n9"
  };
  _exports.default = _default;
});