define("apollo/pods/authorized/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    actions: {
      logout() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});