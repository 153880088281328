define("apollo/pods/authorized/coding/companies/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showPopup: false,
    businessPartner: undefined,
    notificationMessage: undefined,
    notificationType: undefined,
    notifications: Ember.inject.service('notifications'),
    actions: {
      filterPolicy() {},

      toRoute(company, path) {
        this.transitionToRoute(path, company);
        return false;
      },

      saveCompany() {
        let businessPartner = Ember.get(this, 'businessPartner');
        let client = Ember.get(this, 'current.user.client');
        let company = Ember.get(this, 'store').createRecord('company', {
          client,
          systemId: businessPartner.cBpartnerId,
          name: businessPartner.name,
          orgId: businessPartner.adOrgId
        });
        Ember.set(this, 'showPopup', false);
        this.notifications.show('success', 'Company added successfully', 5);
        return company.save().catch(() => {
          company.rollbackAttributes();
          this.notifications.show('error', 'Selected company is already added', 5);
        });
      },

      findByQueryCompany(query) {
        let orgId = Ember.get(this, 'current.user.orgId');
        let systemId = Ember.get(this, 'current.user.client.systemId');

        if (query.length >= 3) {
          let businessPartners = Ember.get(this, 'store').query('business-partner', {
            orgId,
            systemId,
            query
          });
          Ember.set(this, 'businessPartners', businessPartners);
        }

        if (query.length === 0) {
          Ember.set(this, 'businessPartners', null);
        }
      },

      openPopup(businessPartner) {
        if (businessPartner.saved) {
          this.notifications.show('warning', "".concat(businessPartner.name, " is already added"), 5);
        } else {
          Ember.set(this, 'businessPartner', businessPartner);
          Ember.set(this, 'showPopup', true);
        }
      },

      closePopup() {
        Ember.set(this, 'showPopup', false);
      }

    }
  });

  _exports.default = _default;
});