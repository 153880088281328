define("apollo/pods/components/apollo/menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XfuBHhgD",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleMenu\"],null]],[11,\"role\",\"button\"],[9],[0,\"\\n  \"],[15,1,[[29,\"hash\",null,[[\"Button\"],[[29,\"component\",[\"apollo/button\"],[[\"design\"],[[25,[\"design\"]]]]]]]]]],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"open\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"menu-mask\"],[[\"from\"],[\"apollo/pods/components/apollo/menu/styles\"]]]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleMenu\"],null]],[11,\"role\",\"button\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"menu-list\"],[[\"from\"],[\"apollo/pods/components/apollo/menu/styles\"]]]]]],[9],[0,\"\\n    \"],[15,1,[[29,\"hash\",null,[[\"Item\"],[\"apollo/menu/item\"]]]]],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/menu/template.hbs"
    }
  });

  _exports.default = _default;
});