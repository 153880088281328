define("apollo/pods/authorized/insurance/policy_version/benefits/root/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service('apollo'),

    beforeModel(params) {
      if (Ember.isPresent(params.headerless)) {
        this.transitionTo({
          queryParams: {
            headerless: true
          }
        });
      }
    },

    model() {
      return this.modelFor('authorized.insurance.policy_version');
    },

    setupController(controller, model) {
      this._super(...arguments);

      Ember.set(controller, 'policyVersion', model);
      Ember.set(controller, 'policy', model.policy);
      controller.loadBenefitPages(1, true);
      controller.loadLinkPages(1, true);
    }

  });

  _exports.default = _default;
});