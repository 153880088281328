define("apollo/pods/components/field/list-items/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dPaOHCZ2",
    "block": "{\"symbols\":[\"error\",\"item\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"label \",[29,\"if\",[[25,[\"required\"]],\"required\"],null]],null]],[[\"from\"],[\"apollo/pods/components/field/list-items/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"menu-list\"],[[\"from\"],[\"apollo/pods/components/field/list-items/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"items\"]]],null,{\"statements\":[[0,\"    \"],[15,3,[[24,2,[]],[29,\"component\",[\"field/list-box/item\"],[[\"action\",\"item\"],[[29,\"action\",[[24,0,[]],\"chooseOption\"],null],[24,2,[]]]]]]],[0,\"\\n\"]],\"parameters\":[2]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"error-message\"],[[\"from\"],[\"apollo/pods/components/field/list-items/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/field/list-items/template.hbs"
    }
  });

  _exports.default = _default;
});