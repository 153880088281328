define("apollo/pods/components/field/list-box/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['menu-item'],
    tagName: "div",
    role: "button",
    path: null,
    params: null,

    click() {
      let action = Ember.get(this, 'action');
      let item = Ember.get(this, 'item');

      if (action) {
        let value = Ember.get(this, 'value');

        if (value) {
          action(value, item);
        } else {
          action(item);
        }
      }

      return false;
    }

  });

  _exports.default = _default;
});