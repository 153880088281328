define("apollo/pods/authorized/imports/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Nol1KUd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"import-screen\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]]]],[9],[0,\"\\n  \"],[7,\"section\"],[12,\"class\",[30,[[29,\"local-class\",[\"list-menu\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]]]],[9],[0,\"\\n    \"],[7,\"ul\"],[9],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]],null],\"authorized.imports.policies\"]],{\"statements\":[[0,\"Policies\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]],null],\"authorized.imports.deductibles\"]],{\"statements\":[[0,\"Insurance Information\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]],null],\"authorized.imports.products\"]],{\"statements\":[[0,\"Coverage Products\"]],\"parameters\":[]},null],[0,\"\\n      \"],[4,\"link-to\",null,[[\"class\",\"route\"],[[29,\"concat\",[[29,\"local-class\",[\"menu-item\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]],null],\"authorized.imports.diagnostics\"]],{\"statements\":[[0,\"Coverage Diagnostics\"]],\"parameters\":[]},null],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"section\"],[12,\"class\",[30,[[29,\"local-class\",[\"import-section\"],[[\"from\"],[\"apollo/pods/authorized/imports/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/authorized/imports/template.hbs"
    }
  });

  _exports.default = _default;
});