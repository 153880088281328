define("apollo/pods/components/draggable-dropzone/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "draggable-dropzone": "_draggable-dropzone_xk22ap",
    "hint": "_hint_xk22ap"
  };
  _exports.default = _default;
});