define("apollo/services/download-file", ["exports", "apollo/config/environment", "npm:file-saver"], function (_exports, _environment, _npmFileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    host: _environment.default.APP.apiUrl,
    session: Ember.inject.service(),

    queryParams(params, prefix) {
      let query = [];

      for (let param in params) {
        if (param && params[param]) {
          let key = param;

          if (prefix) {
            key = "".concat(prefix, "[").concat(param, "]");
          }

          if (params[param] instanceof Object) {
            let nested = this.queryParams(params[param], key);

            if (nested.length > 0) {
              query.push(nested);
            }
          } else {
            query.push("".concat(key, "=").concat(params[param]));
          }
        }
      }

      return query.join("&");
    },

    request(url, options = {}, callback) {
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.onload = () => {
        let filename = options.filename ? options.filename : 'file.txt';
        let file = new File([xhr.response], decodeURIComponent(filename));

        if (callback) {
          callback();
        }

        return (0, _npmFileSaver.default)(file, filename);
      };

      let defType = 'application/vnd.api+json';
      let method = options.method ? options.method : 'GET';
      let contentType = options.contentType ? options.contentType : defType;
      let accept = options.accept ? options.accept : defType;

      if (options.queryParams) {
        url = "".concat(url, "?").concat(this.queryParams(options.queryParams));
      }

      xhr.open(method, "".concat(this.host, "/").concat(url));
      let accessToken = Ember.get(this, 'session.data.authenticated.access_token');

      if (accessToken) {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(accessToken));
      }

      xhr.setRequestHeader('content-type', contentType);
      xhr.setRequestHeader('Accept', accept);
      xhr.send();
    }

  });

  _exports.default = _default;
});