define("apollo/models/policy-version", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    identifier: (0, _attr.default)('string'),
    policyName: (0, _attr.default)('string'),
    pType: (0, _attr.default)('string'),
    observation: (0, _attr.default)('string'),
    updatedBy: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    insertedBy: (0, _attr.default)('string'),
    insertedAt: (0, _attr.default)('date'),
    priceListId: (0, _attr.default)('number'),
    startDate: (0, _attr.default)('date'),
    endDate: (0, _attr.default)('date'),
    currency: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    preauthorization: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    sequenceNumber: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    commencementDate: (0, _attr.default)('date'),
    override: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    referral: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    policy: (0, _relationships.belongsTo)('policy', {
      inverse: 'policyVersions'
    }),
    benefits: (0, _relationships.hasMany)('benefit'),
    deductibles: (0, _relationships.hasMany)('deductible'),
    limitRules: (0, _relationships.hasMany)('limit-rules'),
    currencyData: Ember.computed('currency', {
      get() {
        switch (Ember.get(this, 'currency')) {
          case 'SAR':
            return {
              separator: ".",
              prefix: "",
              suffix: " SAR "
            };

          default:
            return {
              separator: ".",
              prefix: " $ ",
              suffix: ""
            };
        }
      }

    })
  });

  _exports.default = _default;
});