define("apollo/pods/components/apollo/empty/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UkzjdXoH",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[5,\"fa-icon\",[[13,\"class\",[30,[[29,\"local-class\",[\"empty-icon\"],[[\"from\"],[\"apollo/pods/components/apollo/empty/styles\"]]]]]]],[[\"@icon\",\"@size\",\"@transform\",\"@mask\"],[[23,\"icon\"],\"10x\",\"shrink-6\",\"circle\"]]],[0,\"\\n\\n\"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"empty-label\"],[[\"from\"],[\"apollo/pods/components/apollo/empty/styles\"]]]]]],[9],[0,\"\\n  \"],[1,[23,\"label\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"empty-description\"],[[\"from\"],[\"apollo/pods/components/apollo/empty/styles\"]]]]]],[9],[0,\"\\n  \"],[1,[23,\"description\"],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/empty/template.hbs"
    }
  });

  _exports.default = _default;
});