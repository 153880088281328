define("apollo/pods/components/insurance/coverage/threshold/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "threshold-popup": "_threshold-popup_w3i77p",
    "limit-popup": "_limit-popup_w3i77p",
    "limit-popup-row": "_limit-popup-row_w3i77p",
    "threshold-button": "_threshold-button_w3i77p",
    "open": "_open_w3i77p",
    "buttons": "_buttons_w3i77p",
    "btn-delete": "_btn-delete_w3i77p"
  };
  _exports.default = _default;
});