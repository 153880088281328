define("apollo/pods/authorized/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "_content_9k8vm",
    "main-screen": "_main-screen_9k8vm",
    "main-content": "_main-content_9k8vm"
  };
  _exports.default = _default;
});