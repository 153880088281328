define("apollo/models/business-partner", ["exports", "ember-data/model", "ember-data/attr"], function (_exports, _model, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    value: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    adOrgId: (0, _attr.default)('number'),
    adClientId: (0, _attr.default)('number'),
    cBpartnerId: (0, _attr.default)('number'),
    saved: (0, _attr.default)('boolean', {
      defaultValue: false
    })
  });

  _exports.default = _default;
});