define("apollo/pods/components/charges-panel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "charge-panel": "_charge-panel_pqc3ro",
    "middle-table": "_middle-table_pqc3ro",
    "popup": "_popup_pqc3ro",
    "popup-header": "_popup-header_pqc3ro",
    "popup-body": "_popup-body_pqc3ro",
    "popup-mask": "_popup-mask_pqc3ro",
    "close": "_close_pqc3ro",
    "form-inputs": "_form-inputs_pqc3ro",
    "form-actions": "_form-actions_pqc3ro"
  };
  _exports.default = _default;
});