define("apollo/pods/components/apollo/menu/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['menu-item'],
    tagName: "div",
    role: "button",
    path: null,
    params: null,

    click() {
      let action = Ember.get(this, 'action');

      if (action) {
        action();
      }

      return false;
    }

  });

  _exports.default = _default;
});