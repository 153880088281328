define("apollo/pods/authorized/insurance/company/policies/root/controller", ["exports", "apollo/pods/objects/ref-list", "apollo/pods/objects/export-manager"], function (_exports, _refList, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    downloadFile: Ember.inject.service('download-file'),
    showBusy: false,
    type: 'Policy',
    routes: {
      createNewVersion: "authorized.insurance.policy.policy_versions.new",
      seeVersions: "authorized.insurance.policy.policy_versions",
      seeLogs: "authorized.insurance.policy.policy_logs",
      editPolicy: "authorized.insurance.policy.edit",
      seeArchives: "authorized.insurance.company.policies.policy_archives"
    },
    //Filter properties
    filters: {
      active: true
    },
    sort: {},
    filteredPolicies: null,
    PolicyVers: null,
    page_size: 15,
    data_size: 0,
    page_current: 1,
    tabs: [{
      id: 'ccmd',
      title: 'Deductibles'
    }],
    notifications: Ember.inject.service('notifications'),
    ccmdPopup: false,
    currentPolicyVersion: null,
    isLevel: Ember.computed('type', function () {
      return Ember.get(this, 'type') === 'Level';
    }),
    patientTypeList: Ember.computed(function () {
      return _refList.default.create().getList("patientType");
    }),
    productClassList: Ember.computed(function () {
      return _refList.default.create().getList("productClass");
    }),
    frequenciesList: Ember.computed(function () {
      return _refList.default.create().getList("limitFrequencyPolicy");
    }),
    targetList: Ember.computed(function () {
      return _refList.default.create().getList("limitTarget");
    }),
    policyTypeList: Ember.computed(function () {
      return _refList.default.create().getList("policyType");
    }),

    calculateArrayButtons(len, index) {
      return Array.from({
        length: len
      }, (_, k) => k + index);
    },

    setLikeParam(key, value) {
      let filters = Ember.get(this, 'filters');
      filters.push(key, {
        like: value
      });
    },

    getFilters() {
      let filter = {};
      let filters = Ember.get(this, 'filters');

      if (filters.query) {
        filter.identifier = {
          like: filters.query
        };
        filter.name = {
          or_ilike: filters.query
        };
      } else {
        if (filters.identifier) {
          filter.identifier = {
            like: filters.identifier
          };
        }

        if (filters.name) {
          filter.name = {
            like: filters.name
          };
        }

        if (filters.pType) {
          filter.p_type = {
            like: filters.pType
          };
        } else {
          filter.p_type = {
            not_eq: 'Level'
          };
        }

        if (filters.expiryDays) {
          filter.expiry_days = {
            valid_expiry_days: filters.expiryDays
          };
        } //filter.active = { valid_active: filters.active};


        if (filters.active && !filters.archived) {
          filter.active = {
            boolean: true
          };
        } else if (!filters.active && filters.archived) {
          filter.active = {
            boolean: false
          };
        }

        let dateRFilter = {};

        if (filters.startDate) {
          dateRFilter.start_date = filters.startDate.toISOString();
        }

        if (filters.endDate) {
          dateRFilter.end_date = filters.endDate.toISOString();
        }

        if (!this._isEmpty(dateRFilter)) {
          filter.date = {
            valid_range: dateRFilter
          };
        }

        let dateFilter = {};

        if (filters.expiryDate) {
          dateFilter.expiry_date = filters.expiryDate.toISOString();
        }

        if (!this._isEmpty(dateFilter)) {
          filter.date = {
            valid_expiry_date: dateFilter
          };
        }
      }

      return filter;
    },

    getSort() {
      let sort = {};
      let sorting = Ember.get(this, 'sort');

      if (sorting.name) {
        sort.name = Ember.String.decamelize(sorting.name);
        sort.order = sorting.order;

        if (sort.name.includes(".")) {
          let params = sort.name.split(".");
          sort.bind = params[0];
          sort.name = params[1];
        }
      }

      return sort;
    },

    loadPages(page, recalculate) {
      Ember.set(this, 'showBusy', true);
      document.activeElement.blur();
      let id = Ember.get(this, 'model.company.id');
      let pageSize = Ember.get(this, 'page_size');
      let filter = this.getFilters();
      let sort = this.getSort();
      Ember.get(this, 'store').query('policy', {
        filter,
        sort,
        endpointURI: "companies/".concat(id, "/policies/_search?page_number=").concat(page, "&page_size=").concat(pageSize)
      }).then(policies => {
        Ember.set(this, 'policies', policies);
        Ember.set(this, 'showBusy', false);
      }).catch(() => {
        Ember.set(this, 'showBusy', false);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          filter,
          endpointURI: "companies/".concat(id, "/policies/pages-total?page_size=").concat(pageSize)
        }).then(response => {
          Ember.set(this, 'data_size', response.size);
        });
      }
    },

    getCurrencyLabel(value, type, policyVersion) {
      let currency = Ember.get(policyVersion, 'currencyData');

      if (type === 'currency') {
        value = "".concat(currency.prefix).concat(((value || 0) / 100.00).toFixed(2)).concat(currency.suffix);
      } else if (type === 'percent') {
        value = "".concat((value || 0) / 100, "%");
      }

      return value;
    },

    validateCCMD(field, model1, model2, policyVersion) {
      let errors = Ember.get(model2, 'errors');

      if (Ember.get(model1, field) !== Ember.get(model2, field)) {
        let value = this.getCurrencyLabel(Ember.get(model1, field), Ember.get(model1, "".concat(field, "Type")), policyVersion);
        errors.add(field, ["Required value: ".concat(value)]);
      } else if (Ember.get(model1, "".concat(field, "Type")) !== Ember.get(model2, "".concat(field, "Type"))) {
        let value = this.getCurrencyLabel(Ember.get(model1, field), Ember.get(model1, "".concat(field, "Type")), policyVersion);
        errors.add(field, ["Required value: ".concat(value)]);
      }

      if (Ember.get(model1, "".concat(field, "Limit")) !== Ember.get(model2, "".concat(field, "Limit"))) {
        let value = this.getCurrencyLabel(Ember.get(model1, field), Ember.get(model1, "".concat(field, "Type")), policyVersion);
        errors.add("".concat(field, "Limit"), ["Required value: ".concat(value)]);
      }
    },

    actions: {
      toggleFilters() {
        this.toggleProperty('showFilters');
      },

      toogleType() {
        if (Ember.get(this, 'type') === 'Level') {
          Ember.set(this, 'type', 'Policy');
          Ember.set(this, 'filters', {
            active: true
          });
        } else {
          Ember.set(this, 'type', 'Level');
          Ember.set(this, 'filters', {
            active: true,
            pType: "Level"
          });
        }

        this.send("changePage", 1, Ember.get(this, 'page_size'), true);
      },

      onFilterType(bean) {
        Ember.set(this, 'filters.pType', bean.value);
      },

      filterPolicies(query) {
        Ember.set(this, "filters.query", query);

        if (!query || query.length > 3) {
          this.loadPages(1, true);
        }
      },

      downloadCSV() {
        Ember.set(this, 'showBusy', true);
        document.activeElement.blur();
        let company = Ember.get(this, 'model.company');
        let filter = this.getFilters();
        let sort = this.getSort();
        let file = this.downloadFile;
        file.request("companies/".concat(company.id, "/policies/_download"), {
          queryParams: {
            filter,
            sort,
            format: "CSV"
          },
          filename: "".concat(company.rfc, "_policies.csv")
        }, () => {
          Ember.set(this, 'showBusy', false);
        });
        return false;
      },

      openCCMDPopup(policyVersion, state) {
        let deductible = Ember.get(this, 'currentDeductible');
        let limitRule = Ember.get(this, 'currentLimitRule');

        if (deductible) {
          deductible.rollbackAttributes();
        }

        if (limitRule) {
          limitRule.rollbackAttributes();
        }

        if (state) {
          deductible = Ember.get(this, 'store').createRecord('deductible');
          limitRule = Ember.get(this, 'store').createRecord('limitRule');
        }

        Ember.set(this, 'currentPolicyVersion', policyVersion);
        Ember.set(this, 'ccmdPopup', state);
        Ember.set(this, 'currentDeductible', deductible);
        Ember.set(this, 'currentLimitRule', limitRule);
        return false;
      },

      saveDeductible(policyVersion, deductible) {
        //Clean prev errors
        let errors = Ember.get(deductible, 'errors');
        errors.clear(); //duplicate record

        let undefinedToNull = value => value === undefined ? null : value;

        let compare = (model1, model2, field) => {
          let value1 = undefinedToNull(Ember.get(model1, field));
          let value2 = undefinedToNull(Ember.get(model2, field));
          return value1 === value2;
        };

        let duplicate = Ember.get(policyVersion, 'deductibles').filter(model => compare(model, deductible, 'patientType')).filter(model => compare(model, deductible, 'serviceStation')).filter(model => compare(model, deductible, 'productClass'));

        if (duplicate.length > 0) {
          errors.add('patientType', ['Duplicated Record']);
          errors.add('serviceStation', ['Duplicated Record']);
          errors.add('productClass', ['Duplicated Record']);
        }

        if (Ember.get(deductible, 'patientType') === 'H') {
          //validations CCMD
          let similars = Ember.get(policyVersion, 'deductibles').find(model => compare(model, deductible, 'patientType'));

          if (similars) {
            this.validateCCMD('deductible', similars, deductible, policyVersion);
            this.validateCCMD('coinsurance', similars, deductible, policyVersion);
            this.validateCCMD('copayment', similars, deductible, policyVersion);
            this.validateCCMD('medicalCoinsurance', similars, deductible, policyVersion);
          }
        }

        if (errors.length > 0) {
          this.notifications.show('error', 'Record save failed!');
        } else {
          Ember.set(deductible, 'policyVersion', policyVersion);
          deductible.save().then(deductible => {
            this.notifications.show('success', 'CCMD Record saved!');
            return deductible;
          }).catch(() => {
            deductible.rollbackAttributes();
            this.notifications.show('error', 'CCMD Record save failed!');
          });
          Ember.set(this, 'currentDeductible', Ember.get(this, 'store').createRecord('deductible'));
        }
      },

      deleteDeductible(deductible) {
        deductible.destroyRecord();
      },

      saveLimitRule(policyVersion, limitRule) {
        Ember.set(limitRule, "policyVersion", policyVersion);

        if (Ember.get(limitRule, "frequency") === 'F') {
          Ember.set(limitRule, "target", "I");
        }

        limitRule.save().then(limitRule => {
          this.notifications.show('success', 'Limit Rule saved!');
          return limitRule;
        }).catch(() => {
          limitRule.rollbackAttributes();
          this.notifications.show('error', 'Limit Rule save failed!');
        });
        Ember.set(this, 'currentLimitRule', Ember.get(this, 'store').createRecord('limitRule'));
      },

      deleteLimitRule(limitRule) {
        limitRule.destroyRecord();
      },

      redirect(route, param) {
        this.transitionToRoute(route, param);
        return false;
      },

      download(version) {
        this.downloadFile.request("policy-versions/".concat(version.id, "/download"), {
          filename: "policy_".concat(version.policyName, ".xlsx")
        });
        return false;
      },

      delete(policy) {
        policy.toggleProperty('active');
        policy.save();
        let policyLog = this.get('store').createRecord('policyLog', {
          policy,
          logDate: new Date(),
          policy_id: policy.id,
          status: policy.active
        });
        policyLog.save();
        let erroMsg = policy.active ? "Policy re-activated" : "policy de-activated";
        this.notifications.show('success', erroMsg);
        return false;
      },

      archive(version) {
        Ember.get(version, 'policy').then(policy => {
          policy.toggleProperty('active');
          Ember.set(policy, 'active', false);
          policy.save();
          Ember.set(version, 'active', false);
          version.save();
          this.transitionToRoute("authorized.insurance.company.policies.root", policy.company);
          let erroMsg = policy.active ? "Policy un-archived" : "policy archived";
          this.notifications.show('success', erroMsg);
          this.loadPages(1, true);
        });
        return false;
      },

      clone(version) {
        let pageCurrent = Ember.get(this, 'page_current');
        let pageSize = Ember.get(this, 'page_size');
        Ember.get(version, 'policy').then(policy => {
          policy.clone().then(response => {
            Ember.get(this, 'model.company.policies').reload();
            this.send("changePage", pageCurrent, pageSize, true);
            let msg = "Policy cloned as \"".concat(response.data.attributes.name, "\"");
            return this.notifications.show('success', msg);
          }).catch(() => {
            return this.notifications.show('error', 'Server error');
          });
        });
        return false;
      },

      updateVersion(version) {
        version.save();
        return true;
      },

      sortBy(sort) {
        Ember.set(this, "sort", sort);
        this.loadPages(1, true);
      },

      resetFilter() {
        Ember.set(this, 'filters', {
          active: true
        });
      },

      async downloadPDFTemplate(htmlTableId) {
        let manager = _exportManager.default.create();

        return manager.downloadPDF('policies-export.pdf', htmlTableId);
      },

      changePage(page, pageSize, recalculate) {
        if (pageSize > 0) {
          Ember.set(this, 'page_size', pageSize);
        }

        this.loadPages(page, recalculate);
      }

    },

    _isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    getPolicyVersions(policies) {
      let policyVersions = [];
      policies.forEach(policy => Ember.get(policy, 'currentVersion').then(pv => {
        policyVersions = policyVersions.addObject(pv);
        Ember.set(this, 'PolicyVers', policyVersions);
      }));
    }

  });

  _exports.default = _default;
});