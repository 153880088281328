define("apollo/pods/authorized/insurance/policy_version/benefits/coverages/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service('apollo'),

    beforeModel(params) {
      if (Ember.isPresent(params.headerless)) {
        this.transitionTo({
          queryParams: {
            headerless: true
          }
        });
      }
    },

    async model() {
      let policyVersion = this.modelFor('authorized.insurance.policy_version');
      let policy = await policyVersion.policy;
      let benefits = await policyVersion.benefits;
      let company = policy.company;
      return Ember.RSVP.hash({
        policyVersion,
        policy,
        company,
        benefits
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      Ember.set(controller, 'policy', model.policy);
      Ember.set(controller, 'company', model.company);
      Ember.set(controller, 'policyVersion', model.policyVersion);
      Ember.set(controller, 'benefits', model.benefits);
      controller.loadCurrent(model.benefits);
      controller.loadPages(1, true);
    }

  });

  _exports.default = _default;
});