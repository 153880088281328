define("apollo/pods/authorized/coding/policy_version/benefits/root/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel(params) {
      if (Ember.isPresent(params.headerless)) {
        return this.transitionTo({
          queryParams: {
            headerless: true
          }
        });
      }
    },

    model() {
      return this.modelFor('authorized.coding.policy_version');
    }

  });

  _exports.default = _default;
});