define("apollo/pods/authorized/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    model() {
      return Ember.get(this, 'store').queryRecord('user', {
        findBy: 'me'
      });
    },

    afterModel(model, transition) {
      Ember.set(this, 'current.user', model);

      if (transition.targetName === 'authorized.index') {
        this.transitionTo('authorized.home');
      }
    }

  });

  _exports.default = _default;
});