define("apollo/models/import-coverage-product", ["exports", "ember-data/model", "ember-data/attr", "ember-api-actions"], function (_exports, _model, _attr, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    majorCompany: (0, _attr.default)('string'),
    minorCompany: (0, _attr.default)('string'),
    policyIdentifier: (0, _attr.default)('string'),
    policyName: (0, _attr.default)('string'),
    sequenceNumber: (0, _attr.default)('string'),
    benefitCode: (0, _attr.default)('string'),
    benefitName: (0, _attr.default)('string'),
    benefitAmount: (0, _attr.default)('string'),
    benefitMinAmount: (0, _attr.default)('string'),
    benefitNeedsPreauthorization: (0, _attr.default)('string'),
    benefitAllIncluded: (0, _attr.default)('string'),
    productCategory: (0, _attr.default)('string'),
    productCategoryName: (0, _attr.default)('string'),
    productCode: (0, _attr.default)('string'),
    productName: (0, _attr.default)('string'),
    productAmount: (0, _attr.default)('string'),
    productNeedsPreauthorization: (0, _attr.default)('string'),
    productExcluded: (0, _attr.default)('string'),
    status: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    process: (0, _emberApiActions.collectionAction)({
      path: '_process',
      type: 'post',
      urlType: 'findRecord'
    }),
    clean: (0, _emberApiActions.collectionAction)({
      path: '_clean',
      type: 'post',
      urlType: 'findRecord'
    })
  });

  _exports.default = _default;
});