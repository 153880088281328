define("apollo/pods/components/tabbox/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "tab-menu": "_tab-menu_19natw",
    "tab-button": "_tab-button_19natw",
    "active": "_active_19natw",
    "tab-content": "_tab-content_19natw"
  };
  _exports.default = _default;
});