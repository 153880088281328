define("apollo/pods/components/apollo/table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    localClassNames: ['table'],
    localClassNameBindings: ['designClass'],
    design: 'default',
    designClass: Ember.computed('design', function () {
      return "design-".concat(Ember.get(this, 'design') || 'default');
    })
  });

  _exports.default = _default;
});