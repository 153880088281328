define("apollo/pods/components/field/label/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "format-label": "_format-label_b3be6l",
    "align-right": "_align-right_b3be6l",
    "align-left": "_align-left_b3be6l",
    "align-center": "_align-center_b3be6l",
    "design-alert": "_design-alert_b3be6l"
  };
  _exports.default = _default;
});