define("apollo/pods/components/notification-mask/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    service: Ember.inject.service('notifications'),
    localClassNames: ['notification-mask'],
    localClassNameBindings: ['active'],
    active: Ember.computed.alias('service.hasNotifications'),

    click() {
      let service = Ember.get(this, 'service');
      return service.remove();
    }

  });

  _exports.default = _default;
});