define("apollo/pods/components/apollo/layout/grid/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-layout": "_grid-layout_wqj3bp",
    "margin-none": "_margin-none_wqj3bp",
    "margin-small": "_margin-small_wqj3bp",
    "margin-medium-small": "_margin-medium-small_wqj3bp",
    "margin-big": "_margin-big_wqj3bp",
    "margin-header-cover": "_margin-header-cover_wqj3bp",
    "border-none": "_border-none_wqj3bp",
    "border-1": "_border-1_wqj3bp"
  };
  _exports.default = _default;
});