define("apollo/pods/components/field/text-area/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_bh3q7g _input_lqr8xx",
    "input-readonly": "_input-readonly_bh3q7g _input-readonly_lqr8xx",
    "label": "_label_bh3q7g _label_lqr8xx",
    "form-input": "_form-input_bh3q7g _form-input_lqr8xx",
    "required": "_required_bh3q7g _required_lqr8xx",
    "left-label": "_left-label_bh3q7g _left-label_lqr8xx",
    "right-label": "_right-label_bh3q7g _right-label_lqr8xx",
    "bottom-label": "_bottom-label_bh3q7g _bottom-label_lqr8xx",
    "input-error": "_input-error_bh3q7g _input-error_lqr8xx",
    "error-message": "_error-message_bh3q7g _error-message_lqr8xx"
  };
  _exports.default = _default;
});