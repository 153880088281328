define("apollo/pods/components/search/products/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notifications'),
    tagName: '',
    store: Ember.inject.service(),
    records: [],
    currentText: "",
    icon: "search",

    init() {
      this._super(...arguments);

      this.set('errors', []);
      let value = Ember.get(this, 'value');

      if (value) {
        Ember.get(this, 'store').findRecord('business-partner', value).then(record => {
          Ember.set(this, 'records', [record]);
          Ember.set(this, 'currentText', record.name);
        });
      }
    },

    results: Ember.computed('records', function () {
      let products = Ember.get(this, 'records');
      let categories = [];
      let results = [];

      if (products) {
        products.forEach(function (current) {
          if (!categories.includes(Ember.get(current, 'category'))) {
            categories.push(Ember.get(current, 'category'));
            results.push({
              icon: 'layer-group',
              name: Ember.get(current, 'category_name'),
              epc: Ember.get(current, 'category'),
              category_name: '--'
            });
          }

          results.push(current);
        });
      }

      return results;
    }),
    actions: {
      onChange(query) {
        if (query && query.length >= 3) {
          let params = {
            findBy: 'find',
            price_list_id: 100,
            query
          };
          Ember.get(this, 'store').query('product', params).then(records => {
            Ember.set(this, 'records', records);
          });
        } else {
          Ember.set(this, 'records', null);
        }

        let change = Ember.get(this, 'onChange');

        if (change) {
          change(query, null);
        }
      },

      onSelect(selected) {
        Ember.set(this, 'currentText', selected.name);
        let change = Ember.get(this, 'onChange');

        if (change) {
          if (change(selected.epc, selected)) {
            Ember.set(this, 'currentText', null);
          }
        }
      }

    }
  });

  _exports.default = _default;
});