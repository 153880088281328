define("apollo/pods/components/insurance/diagnostic/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gfQyWM1b",
    "block": "{\"symbols\":[\"table\",\"diagnostic\",\"item\"],\"statements\":[[4,\"search-box\",null,[[\"items\",\"onChange\",\"onSelect\"],[[25,[\"diagnostics\"]],[25,[\"findByCode\"]],[25,[\"save\"]]]],{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"code\"],[[\"from\"],[\"apollo/pods/components/insurance/diagnostic/styles\"]]]]]],[9],[1,[24,3,[\"code\"]],false],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"name\"],[[\"from\"],[\"apollo/pods/components/insurance/diagnostic/styles\"]]]]]],[9],[1,[24,3,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n\"],[4,\"widgets/table\",null,[[\"design\"],[\"simple\"]],{\"statements\":[[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"header\"]],\"expected `table.header` to be a contextual component but found a string. Did you mean `(component table.header)`? ('apollo/pods/components/insurance/diagnostic/template.hbs' @ L11:C5) \"],null]],null,{\"statements\":[[0,\"    \"],[7,\"th\"],[9],[0,\"Diagnostic\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[0,\"Helix Code\"],[10],[0,\"\\n    \"],[7,\"th\"],[9],[10],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[29,\"-assert-implicit-component-helper-argument\",[[24,1,[\"body\"]],\"expected `table.body` to be a contextual component but found a string. Did you mean `(component table.body)`? ('apollo/pods/components/insurance/diagnostic/template.hbs' @ L16:C5) \"],null]],null,{\"statements\":[[4,\"each\",[[25,[\"model\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"insurance/diagnostic/item\",null,[[\"diagnostic\",\"deleteAction\"],[[24,2,[]],[29,\"action\",[[24,0,[]],[25,[\"deleteAction\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/insurance/diagnostic/template.hbs"
    }
  });

  _exports.default = _default;
});