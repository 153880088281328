define("apollo/pods/components/apollo/column/sort/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "sort-column": "_sort-column_y7zpdn",
    "sort-column-icons": "_sort-column-icons_y7zpdn",
    "sort-column-icon-active": "_sort-column-icon-active_y7zpdn"
  };
  _exports.default = _default;
});