define("apollo/pods/authorized/coding/company/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.get(this, 'store').findRecord('company', params.company_id);
    }

  });

  _exports.default = _default;
});