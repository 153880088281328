define("apollo/pods/components/forms/input-combo/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['search-box', 'form-input'],
    localClassNameBindings: ['designClass', 'grow', 'widthClass'],
    width: 0,
    focused: false,
    grow: false,
    design: 'default',
    designClass: Ember.computed('design', function () {
      return Ember.get(this, 'design') || 'default';
    }),
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-full';
      }
    }),
    innerSelection: Ember.computed('value', 'data', function () {
      let value = Ember.get(this, 'value') || '';
      let data = Ember.get(this, 'data');
      let valueKey = Ember.get(this, 'valueKey');
      let innerSelection = [];

      if (value) {
        innerSelection = data.filter(function (item) {
          return value.split(' ').includes(Ember.get(item, valueKey));
        });
      }

      return innerSelection;
    }),
    textValue: Ember.computed('value', 'data', function () {
      let innerSelection = Ember.get(this, 'innerSelection');
      let textKey = Ember.get(this, 'textKey');
      return innerSelection.map(function (item) {
        return Ember.get(item, textKey);
      }).join(", ");
    }),
    actions: {
      chooseOption(item, checked) {
        let innerSelection = Ember.get(this, 'innerSelection');

        if (checked) {
          innerSelection.push(item);
        } else {
          innerSelection = innerSelection.filter(function (value) {
            return item !== value;
          });
        }

        let valueKey = Ember.get(this, 'valueKey');
        let results = innerSelection.map(function (item) {
          return Ember.get(item, valueKey);
        }).join(" ");
        Ember.set(this, 'value', results);
        let onChange = Ember.get(this, 'onChange');

        if (onChange) {
          onChange(results);
        }

        Ember.set(this, 'focused', false);
      },

      setFocus() {
        this.toggleProperty("focused");
      }

    }
  });

  _exports.default = _default;
});