define("apollo/pods/components/apollo/upload/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sk6of5Gc",
    "block": "{\"symbols\":[\"queue\",\"&default\"],\"statements\":[[5,\"file-upload\",[[13,\"class\",[30,[[29,\"local-class\",[\"upload-cursor\"],[[\"from\"],[\"apollo/pods/components/apollo/upload/styles\"]]]]]]],[[\"@name\",\"@accept\",\"@onfileadd\"],[[23,\"name\"],[23,\"accept\"],[29,\"action\",[[24,0,[]],\"onUpload\"],null]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,1,[\"files\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"uploading\"],[[\"from\"],[\"apollo/pods/components/apollo/upload/styles\"]]]]]],[9],[0,\"Uploading\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[15,2],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/upload/template.hbs"
    }
  });

  _exports.default = _default;
});