define("apollo/pods/authorized/coding/company/policies/root/controller", ["exports", "apollo/pods/objects/export-manager"], function (_exports, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    filters: [],
    page_size: 15,
    data_size: 0,
    page_current: 1,
    activePolicies: Ember.computed('policies.@each.active', function () {
      let policies = Ember.get(this, 'policies');

      if (policies) {
        return policies.filter(policy => {
          return Ember.get(policy, 'active');
        });
      }

      return null;
    }),

    loadPages(page, recalculate) {
      let id = Ember.get(this, 'company.id');
      let pageSize = Ember.get(this, 'page_size');
      let filter = this.getFilters();
      Ember.get(this, 'store').query('policy', {
        filter,
        endpointURI: "companies/".concat(id, "/policies?page_number=").concat(page, "&page_size=").concat(pageSize)
      }).then(policies => {
        Ember.set(this, 'policies', policies);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          filter,
          endpointURI: "companies/".concat(id, "/policies/pages-total?page_size=").concat(pageSize)
        }).then(response => {
          Ember.set(this, 'data_size', response.size);
        });
      }
    },

    getFilters() {
      let filters = Ember.get(this, 'filters');
      let filter = {};

      if (filters.name) {
        filter.name = {
          like: filters.name
        };
      }

      if (filters.pType) {
        filter.p_type = {
          like: filters.pType
        };
      }

      if (filters.expiryDays) {
        filter.expiry_days = {
          valid_expiry_days: filters.expiryDays
        };
      }

      if (filters.active) {
        filter.active = {
          valid_active: filters.active
        };
      }

      let dateRFilter = {};

      if (filters.startDate) {
        dateRFilter.start_date = filters.startDate.toISOString();
      }

      if (filters.endDate) {
        dateRFilter.end_date = filters.endDate.toISOString();
      }

      if (!this._isEmpty(dateRFilter)) {
        filter.date = {
          valid_range: dateRFilter
        };
      }

      let dateFilter = {};

      if (filters.expiryDate) {
        dateFilter.expiry_date = filters.expiryDate.toISOString();
      }

      if (!this._isEmpty(dateFilter)) {
        filter.date = {
          valid_expiry_date: dateFilter
        };
      }

      return filter;
    },

    _isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    actions: {
      seeVersions(policy) {
        this.transitionToRoute("authorized.coding.policy.policy_versions", policy);
        return false;
      },

      seeLogs(policy) {
        this.transitionToRoute("authorized.coding.policy.policy_logs", policy);
        return false;
      },

      resetFilter() {
        Ember.set(this, 'filters', []);
      },

      async downloadCSVTemplate() {
        let manager = _exportManager.default.create();

        let data = manager.fileFrom('policy', Ember.get(this, 'PolicyVers'));
        return manager.download('policies-export.csv', data);
      },

      async downloadPDFTemplate(htmlTableId) {
        let manager = _exportManager.default.create();

        return manager.downloadPDF('policies-export.pdf', htmlTableId);
      },

      changePage(page, recalculate) {
        if (recalculate) {
          Ember.set(this, 'page_current', 1);
        }

        this.loadPages(page, recalculate);
      }

    }
  });

  _exports.default = _default;
});