define("apollo/pods/authorized/insurance/companies/root/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.get(this, 'store').query('company', {
        findBy: 'find'
      });
    }

  });

  _exports.default = _default;
});