define("apollo/pods/components/forms/input-date/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0WVIJx52",
    "block": "{\"symbols\":[\"error\"],\"statements\":[[4,\"if\",[[25,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"label \",[29,\"if\",[[25,[\"required\"]],\"required\"],null]],null]],[[\"from\"],[\"apollo/pods/components/forms/input-date/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[29,\"ember-flatpickr\",null,[[\"locale\",\"onChange\",\"date\",\"class\"],[[29,\"get-locale\",null,null],[29,\"action\",[[24,0,[]],\"extractDate\"],null],[29,\"if\",[[25,[\"value\"]],[25,[\"value\"]],[25,[\"defaultDate\"]]],null],[29,\"concat\",[[29,\"local-class\",[[29,\"concat\",[\"input \",[29,\"if\",[[25,[\"errors\"]],\"input-error\"],null]],null]],[[\"from\"],[\"apollo/pods/components/forms/input-date/styles\"]]]],null]]]],false],[0,\"\\n\\n\"],[7,\"i\"],[11,\"class\",\"icon-calendar\"],[11,\"role\",\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleCalendar\"],null]],[9],[10],[0,\"\\n\"],[7,\"i\"],[11,\"class\",\"icon-close\"],[11,\"role\",\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"clearCalendar\"],null]],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"error-message\"],[[\"from\"],[\"apollo/pods/components/forms/input-date/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"errors\"]]],null,{\"statements\":[[0,\"      \"],[1,[24,1,[\"message\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/forms/input-date/template.hbs"
    }
  });

  _exports.default = _default;
});