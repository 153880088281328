define("apollo/models/limit-rule", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    frequency: (0, _attr.default)('string'),
    target: (0, _attr.default)('string'),
    patientType: (0, _attr.default)('string'),
    serviceStation: (0, _attr.default)('string'),
    days: (0, _attr.default)('number'),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    amountFloat: Ember.computed('amount', {
      get() {
        return (Ember.get(this, 'amount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'amount', value * 100);
        return value;
      }

    }),
    policyVersion: (0, _relationships.belongsTo)('policyVersion'),
    coverage: (0, _relationships.belongsTo)('coverage')
  });

  _exports.default = _default;
});