define("apollo/pods/components/apollo/layout/grid/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['grid-layout'],
    localClassNameBindings: ['marginLayout', 'panelSize', 'borderLayout'],
    margin: 'none',
    border: 'none',
    marginLayout: Ember.computed('margin', function () {
      return "margin-".concat(Ember.get(this, 'margin'));
    }),
    borderLayout: Ember.computed('border', function () {
      return "border-".concat(Ember.get(this, 'border'));
    })
  });

  _exports.default = _default;
});