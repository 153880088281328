define("apollo/pods/authorized/insurance/company/policies/policy_archives/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    //Sorting properties
    sortProperties: 'id',
    sortDesc: true,
    page_size: 15,
    data_size: 0,
    page_current: 1,
    tabs: [{
      id: 'ccmd',
      title: "Deductibles"
    }, {
      id: 'limit',
      title: "Threshold"
    }],
    notifications: Ember.inject.service('notifications'),
    ccmdPopup: false,
    currentPolicyVersion: null,

    getFilters() {
      let filter = {};
      filter.active = {
        valid_active: false
      };
      return filter;
    },

    loadPages(page, recalculate) {
      let id = Ember.get(this, 'model.company.id');
      let pageSize = Ember.get(this, 'page_size');
      let filter = this.getFilters();
      Ember.get(this, 'store').query('policy', {
        filter,
        endpointURI: "companies/".concat(id, "/policies?page_number=").concat(page, "&page_size=").concat(pageSize)
      }).then(policies => {
        Ember.set(this, 'policies', policies);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          filter,
          endpointURI: "companies/".concat(id, "/policies/pages-total?page_size=").concat(pageSize)
        }).then(response => {
          Ember.set(this, 'data_size', response.size);
        });
      }
    },

    actions: {
      unArchive(policy) {
        policy.toggleProperty('active');
        Ember.set(policy, 'active', true);
        policy.save();
        Ember.get(policy, 'currentVersion').then(pv => {
          Ember.set(pv, 'active', true);
          pv.save();
        });
        this.transitionToRoute("authorized.insurance.company.policies.policy_archives", policy.company);
        let erroMsg = policy.active ? "Policy un-archived" : "policy archived";
        this.notifications.show('success', erroMsg);
        this.loadPages(1, true);
        return false;
      },

      updateVersion(policy) {
        Ember.get(policy, 'currentVersion').then(pv => {
          return pv.save();
        });
        return false;
      },

      sortBy(property) {
        if (Ember.get(this, 'sortProperties') === property) {
          this.toggleProperty('sortDesc');
        } else {
          Ember.set(this, 'sortDesc', true);
        }

        Ember.set(this, 'sortProperties', property);
      },

      changePage(page, recalculate) {
        if (recalculate) {
          Ember.set(this, 'page_current', 1);
        }

        this.loadPages(page, recalculate);
      }

    },

    _isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    getPolicyVersions(policies) {
      let policyVersions = [];
      policies.forEach(policy => Ember.get(policy, 'currentVersion').then(pv => {
        policyVersions = policyVersions.addObject(pv);
        Ember.set(this, 'PolicyVers', policyVersions);
      }));
    }

  });

  _exports.default = _default;
});