define("apollo/pods/components/breadcrumbs/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "breadcrumbs": "_breadcrumbs_qcv48e",
    "no-border": "_no-border_qcv48e"
  };
  _exports.default = _default;
});