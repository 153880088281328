define("apollo/pods/components/apollo/busy/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TOhFV5+k",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"loading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"loading-sceen\"],[[\"from\"],[\"apollo/pods/components/apollo/busy/styles\"]]]]]],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"class\",[30,[[29,\"local-class\",[\"loading\"],[[\"from\"],[\"apollo/pods/components/apollo/busy/styles\"]]]]]],[11,\"src\",\"/assets/loading.gif\"],[11,\"alt\",\"loading\"],[9],[10],[0,\"\\n    Processing...\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/busy/template.hbs"
    }
  });

  _exports.default = _default;
});