define("apollo/pods/components/tabbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JnBsKrBj",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[7,\"ul\"],[12,\"class\",[30,[[29,\"local-class\",[\"tab-menu\"],[[\"from\"],[\"apollo/pods/components/tabbox/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"tabs\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"tab-button \",[29,\"if\",[[29,\"eq\",[[25,[\"selectedTab\"]],[24,1,[\"id\"]]],null],\"active\"],null],\" \"],null]],[[\"from\"],[\"apollo/pods/components/tabbox/styles\"]]]]]],[11,\"role\",\"button\"],[9],[0,\"\\n      \"],[1,[24,1,[\"title\"]],false],[0,\"\\n    \"],[3,\"action\",[[24,0,[]],\"changeTab\",[24,1,[\"id\"]]]],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"tab-content\"],[[\"from\"],[\"apollo/pods/components/tabbox/styles\"]]]]]],[9],[0,\"\\n  \"],[15,2,[[25,[\"selectedTab\"]]]],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/tabbox/template.hbs"
    }
  });

  _exports.default = _default;
});