define("apollo/pods/authorized/insurance/policy/policy_versions/new/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    actions: {
      savePolicyVersion(policyVersion) {
        let notifications = Ember.get(this, 'notifications');
        return policyVersion.save().then(() => {
          this.send('goBack');
        }).catch(response => {
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      setLinkedPriceList(version, priceList) {
        Ember.set(version, 'priceListId', priceList.id);
        Ember.set(version, 'currency', priceList.currency);
        Ember.set(this, 'currencies', [{
          text: priceList.currency,
          value: priceList.currency
        }]);
      },

      goBack() {
        return this.transitionToRoute('authorized.insurance.policy.policy_versions');
      }

    }
  });

  _exports.default = _default;
});