define("apollo/pods/components/search/diagnostics/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notifications'),
    tagName: '',
    store: Ember.inject.service(),
    records: [],
    currentText: "",
    icon: "search",
    actions: {
      onChange(query) {
        if (query && query.length >= 3) {
          Ember.get(this, 'store').query('diagnostic', {
            query
          }).then(records => {
            Ember.set(this, 'results', records);
          });
        } else {
          Ember.set(this, 'results', null);
        }

        let change = Ember.get(this, 'onChange');

        if (change) {
          change(query, null);
        }
      },

      onSelect(selected) {
        Ember.set(this, 'currentText', selected.name);
        let change = Ember.get(this, 'onChange');

        if (change) {
          if (change(selected.epc, selected)) {
            Ember.set(this, 'currentText', null);
          }
        }
      }

    }
  });

  _exports.default = _default;
});