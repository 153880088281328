define("apollo/pods/components/insurance/diagnostic/item/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QGus8xJ+",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[9],[0,\"\\n  \"],[1,[25,[\"diagnostic\",\"name\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n  \"],[1,[25,[\"diagnostic\",\"helixCode\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[9],[0,\"\\n\"],[4,\"widgets/x-button\",null,[[\"color\",\"action\"],[\"cancel\",[29,\"action\",[[24,0,[]],[25,[\"deleteAction\"]],[25,[\"diagnostic\"]]],null]]],{\"statements\":[[0,\"    Delete\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/insurance/diagnostic/item/template.hbs"
    }
  });

  _exports.default = _default;
});