define("apollo/helpers/lower-case", ["exports", "ember-string-helpers/helpers/lower-case"], function (_exports, _lowerCase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _lowerCase.default;
    }
  });
  Object.defineProperty(_exports, "lowerCase", {
    enumerable: true,
    get: function () {
      return _lowerCase.lowerCase;
    }
  });
});