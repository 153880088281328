define("apollo/pods/components/title-bar/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "table-title": "_table-title_1kx5r3",
    "default": "_default_1kx5r3",
    "underline": "_underline_1kx5r3",
    "underline-gray": "_underline-gray_1kx5r3",
    "underline-slim": "_underline-slim_1kx5r3"
  };
  _exports.default = _default;
});