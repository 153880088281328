define("apollo/pods/components/field/search-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-text'],
    localClassNameBindings: ['labelPosition'],
    type: 'text',
    placeholder: '',
    icon: "search",
    currency: {
      separator: ".",
      prefix: " $ "
    },
    focused: false,
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + "-label";
    }),
    open: Ember.computed('items', 'focused', function () {
      let items = Ember.get(this, 'items');
      let focused = Ember.get(this, 'focused');
      return focused && items;
    }),
    actions: {
      toggleMenu() {
        this.toggleProperty('focused');
        return true;
      },

      change(value) {
        Ember.set(this, 'value', value);
        let change = Ember.get(this, 'onChange');

        if (change) {
          change(value);
        }

        return false;
      },

      chooseOption(item) {
        let onSelect = Ember.get(this, 'onSelect');

        if (onSelect) {
          Ember.set(this, 'value', '');
          Ember.set(this, 'focused', false);
          return onSelect(item);
        }
      },

      focus(focused) {
        Ember.set(this, 'focused', focused);
      }

    }
  });

  _exports.default = _default;
});