define("apollo/pods/components/field/text-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_bw9gz2 _input_lqr8xx",
    "input-readonly": "_input-readonly_bw9gz2 _input-readonly_lqr8xx",
    "label": "_label_bw9gz2 _label_lqr8xx",
    "form-input": "_form-input_bw9gz2 _form-input_lqr8xx",
    "required": "_required_bw9gz2 _required_lqr8xx",
    "left-label": "_left-label_bw9gz2 _left-label_lqr8xx",
    "right-label": "_right-label_bw9gz2 _right-label_lqr8xx",
    "bottom-label": "_bottom-label_bw9gz2 _bottom-label_lqr8xx",
    "input-error": "_input-error_bw9gz2 _input-error_lqr8xx",
    "error-message": "_error-message_bw9gz2 _error-message_lqr8xx",
    "input-icon": "_input-icon_bw9gz2"
  };
  _exports.default = _default;
});