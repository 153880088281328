define("apollo/pods/components/dot-menu/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OjSQHj2t",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"icon\"],[[\"from\"],[\"apollo/pods/components/dot-menu/styles\"]]]]]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"icon-dot\"],[[\"from\"],[\"apollo/pods/components/dot-menu/styles\"]]]]]],[9],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"icon-dot\"],[[\"from\"],[\"apollo/pods/components/dot-menu/styles\"]]]]]],[9],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"icon-dot\"],[[\"from\"],[\"apollo/pods/components/dot-menu/styles\"]]]]]],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"items \",[25,[\"align\"]]],null]],[[\"from\"],[\"apollo/pods/components/dot-menu/styles\"]]]]]],[9],[0,\"\\n  \"],[15,1],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/dot-menu/template.hbs"
    }
  });

  _exports.default = _default;
});