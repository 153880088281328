define("apollo/pods/objects/ref-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    productClass: [{
      value: "RM",
      name: "Rooms"
    }, {
      value: "TH",
      name: "Others"
    }, {
      value: "IN",
      name: "Instrumental"
    }, {
      value: "LA",
      name: "Pathology and Laboratory Procedures"
    }, {
      value: "XR",
      name: "Radiology Procedures"
    }, {
      value: "DG",
      name: "Drug"
    }, {
      value: "AN",
      name: "Anesthesic"
    }, {
      value: "MS",
      name: "Medical Supplies"
    }, {
      value: "OV",
      name: "Office Visit"
    }, {
      value: "DT",
      name: "Dental Treatment"
    }, {
      value: "OD",
      name: "Other Drugs"
    }, {
      value: "PR",
      name: "Medicine Services and Surgery"
    }, {
      value: "IM",
      name: "Immunization"
    }, {
      value: "NM",
      name: "Medical Equipment"
    }, {
      value: "OS",
      name: "Other Service"
    }, {
      value: "PT",
      name: "Physical therapy"
    }, {
      value: "CL",
      name: "Cultures"
    }, {
      value: "BL",
      name: "Blood"
    }, {
      value: "SG",
      name: "Surgery"
    }, {
      value: "TR",
      name: "Treatment"
    }, {
      value: "FR",
      name: "FQHC/RHC"
    }, {
      value: "PS",
      name: "Physician Services"
    }, {
      value: "MR",
      name: "MR Magnetic Resonance"
    }, {
      value: "CT",
      name: "CT Computed Tomography"
    }, {
      value: "DGY",
      name: "Patent Drug"
    }],
    patientType: [{
      value: "H",
      name: "Hospitalization"
    }, {
      value: "U",
      name: "Emergency"
    }, {
      value: "A",
      name: "Ambulatory"
    }, {
      value: "O",
      name: "Other"
    }],
    limitTarget: [{
      value: "P",
      name: "Patient"
    }, {
      value: "I",
      name: "Insurance Company"
    }],
    limitFrequency: [{
      value: "D",
      name: "Daily"
    }, {
      value: "E",
      name: "Encounter"
    }],
    limitFrequencyPolicy: [{
      value: "D",
      name: "Daily"
    }, {
      value: "E",
      name: "Encounter"
    }, {
      value: "F",
      name: "Follow up"
    }],
    productType: [{
      value: "E",
      name: "Expense type"
    }, {
      value: "I",
      name: "Item"
    }, {
      value: "O",
      name: "Online"
    }, {
      value: "P",
      name: "Package"
    }, {
      value: "R",
      name: "Resource"
    }, {
      value: "S",
      name: "Service"
    }],
    policyType: [{
      value: "Corporate",
      name: "Corporate"
    }, {
      value: "Personal",
      name: "Personal"
    }, {
      value: "Donation",
      name: "Donation"
    }],
    policyDocType: [{
      value: "Corporate",
      name: "Corporate"
    }, {
      value: "Personal",
      name: "Personal"
    }],
    currencies: [{
      value: 'USD',
      name: 'USD'
    }, {
      value: 'MXN',
      name: 'MXN'
    }, {
      value: 'ARS',
      name: 'ARS'
    }, {
      value: 'SAR',
      name: 'SAR'
    }],

    getList(listName) {
      return Ember.get(this, listName);
    },

    getValueOf(listName, value) {
      return Ember.get(this, listName).filter(item => item.name === value)[0];
    }

  });

  _exports.default = _default;
});