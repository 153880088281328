define("apollo/pods/components/apollo/table/header/component", ["exports", "apollo/pods/components/apollo/table/header/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['designClass'],
    tagName: 'thead',
    design: 'default',
    designClass: Ember.computed('design', function () {
      return _styles.default["design-".concat(Ember.get(this, 'design'))];
    })
  });

  _exports.default = _default;
});