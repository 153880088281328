define("apollo/pods/components/field/list-items/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_1vem05 _input_lqr8xx",
    "input-readonly": "_input-readonly_1vem05 _input-readonly_lqr8xx",
    "label": "_label_1vem05 _label_lqr8xx",
    "form-input": "_form-input_1vem05 _form-input_lqr8xx",
    "required": "_required_1vem05 _required_lqr8xx",
    "left-label": "_left-label_1vem05 _left-label_lqr8xx",
    "right-label": "_right-label_1vem05 _right-label_lqr8xx",
    "bottom-label": "_bottom-label_1vem05 _bottom-label_lqr8xx",
    "input-error": "_input-error_1vem05 _input-error_lqr8xx",
    "error-message": "_error-message_1vem05 _error-message_lqr8xx",
    "menu-list": "_menu-list_1vem05"
  };
  _exports.default = _default;
});