define("apollo/mirage/factories/policy", ["exports", "ember-cli-mirage"], function (_exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name: 'Apollo Policy',
    maxAmount: 1000,
    currency: 'MXN',
    needsPreAuthorization: true,
    startDate: new Date(),
    expireDate: new Date(),
    type: 'true',
    priceListId: '1',
    sequenceNumber: 666,
    commencementDate: new Date(),
    override: false,
    referral: false
  });

  _exports.default = _default;
});