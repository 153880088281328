define("apollo/pods/components/apollo/column/sort/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tU2gNW4Y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"sort-column\"],[[\"from\"],[\"apollo/pods/components/apollo/column/sort/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"display\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"sort-column-icons\"],[[\"from\"],[\"apollo/pods/components/apollo/column/sort/styles\"]]]]]],[9],[0,\"\\n      \"],[5,\"fa-icon\",[[13,\"class\",[30,[[29,\"local-class\",[[29,\"if\",[[25,[\"ascending\"]],\"sort-column-icon-active\"],null]],[[\"from\"],[\"apollo/pods/components/apollo/column/sort/styles\"]]]]]]],[[\"@icon\"],[\"caret-up\"]]],[0,\"\\n      \"],[5,\"fa-icon\",[[13,\"class\",[30,[[29,\"local-class\",[[29,\"if\",[[25,[\"descending\"]],\"sort-column-icon-active\"],null]],[[\"from\"],[\"apollo/pods/components/apollo/column/sort/styles\"]]]]]]],[[\"@icon\"],[\"caret-down\"]]],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"sort-column-label\"],[[\"from\"],[\"apollo/pods/components/apollo/column/sort/styles\"]]]]]],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/apollo/column/sort/template.hbs"
    }
  });

  _exports.default = _default;
});