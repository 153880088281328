define("apollo/pods/components/field/search-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_uflgxp _input_lqr8xx",
    "input-readonly": "_input-readonly_uflgxp _input-readonly_lqr8xx",
    "label": "_label_uflgxp _label_lqr8xx",
    "form-input": "_form-input_uflgxp _form-input_lqr8xx",
    "required": "_required_uflgxp _required_lqr8xx",
    "left-label": "_left-label_uflgxp _left-label_lqr8xx",
    "right-label": "_right-label_uflgxp _right-label_lqr8xx",
    "bottom-label": "_bottom-label_uflgxp _bottom-label_lqr8xx",
    "input-error": "_input-error_uflgxp _input-error_lqr8xx",
    "error-message": "_error-message_uflgxp _error-message_lqr8xx",
    "input-icon": "_input-icon_uflgxp",
    "menu-mask": "_menu-mask_uflgxp",
    "menu-list": "_menu-list_uflgxp"
  };
  _exports.default = _default;
});