define("apollo/pods/components/breadcrumbs/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    design: 'default',
    localClassNameBindings: ['designClass'],
    localClassNames: ['breadcrumbs'],
    designClass: Ember.computed('design', function () {
      return Ember.get(this, 'design') || 'default';
    })
  });

  _exports.default = _default;
});