define("apollo/pods/authorized/claims/encounter/charges/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),

    _splitCharge(chargeId, amount) {
      let store = Ember.get(this, 'store');
      let charge = store.peekRecord('charge', chargeId);
      let notifications = Ember.get(this, 'notifications');

      if (charge !== null) {
        return charge.split({
          amount
        }).then(() => {
          this.charges.reload();
          return notifications.show('info', 'Split succesfully');
        }).catch(() => {
          return notifications.show('error', 'Error Split unsuccesfully');
        });
      }
    },

    actions: {
      rejectCharge(event) {
        this._updateCharge(event, false);
      },

      approveCharge(event) {
        this._updateCharge(event, true);
      },

      splitSave(charge) {
        if (charge !== null) {
          let amount = Ember.get(charge, 'splitAmount');
          let chargeId = Ember.get(charge, 'id');
          return this._splitCharge(chargeId, amount);
        }
      },

      sendEncounter(encounter) {
        let notifications = Ember.get(this, 'notifications');
        return encounter.send().then(() => {
          encounter.reload();
          return notifications.show('success', 'Send succesfully');
        }).catch(() => {
          return notifications.show('error', 'Error! Send unsuccesfully');
        });
      }

    },

    _updateCharge(event, approve) {
      let chargeId = event.dataTransfer.getData('text/data');
      let charge = Ember.get(this, 'store').peekRecord('charge', chargeId);

      if (charge !== null && Ember.get(charge, 'isApproved') !== approve) {
        return charge.updateStatus({
          new_status: approve ? 'authorized' : 'no-covered'
        }).then(() => {
          return this.charges.reload();
        });
      }
    }

  });

  _exports.default = _default;
});