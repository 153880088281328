define("apollo/pods/components/field/label/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "label",
    localClassNames: ['format-label'],
    localClassNameBindings: ['labelAlign', 'labelDesign'],
    align: "left",
    design: "none",
    labelAlign: Ember.computed('align', function () {
      return "align-".concat(Ember.get(this, 'align'));
    }),
    labelDesign: Ember.computed('design', function () {
      return "design-".concat(Ember.get(this, 'design'));
    })
  });

  _exports.default = _default;
});