define("apollo/pods/authorized/insurance/policy/policy_versions/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let policy = this.modelFor('authorized.insurance.policy');
      let currentVersion = policy.currentVersion;
      return Ember.RSVP.hash({
        priceLists: Ember.get(policy, 'company.priceLists'),
        policyVersion: Ember.get(this, 'store').createRecord('policyVersion', {
          policy,
          sequenceNumber: Ember.get(policy, 'currentVersion.sequenceNumber'),
          override: Ember.get(policy, 'currentVersion.override'),
          referral: Ember.get(policy, 'currentVersion.referral')
        }),
        currentVersion
      });
    },

    setupController: (controller, model) => {
      Ember.set(controller, 'model', model);
      Ember.set(controller, 'policyVersion', model.policyVersion);
      Ember.set(controller, 'priceLists', model.priceLists);

      if (model.priceLists.length !== 0) {
        Ember.set(controller, 'currencies', [{}]);
      } else {
        Ember.set(controller, 'currencies', [{
          text: 'USD',
          value: 'USD'
        }, {
          text: 'MXN',
          value: 'MXN'
        }, {
          text: 'ARS',
          value: 'ARS'
        }, {
          text: 'SAR',
          value: 'SAR'
        }]);
        Ember.set(controller, 'disablePriceLists', true);
      }
    },
    actions: {
      willTransition() {
        let policyVersion = Ember.get(this.controller, 'policyVersion');

        if (Ember.get(policyVersion, 'isNew')) {
          policyVersion.rollbackAttributes();
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});