define("apollo/pods/components/draggable-dropzone/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['draggableDropzone'],
    localClassNames: ['draggable-dropzone'],
    classNameBindings: ['dragClass'],
    localClassNameBindings: ['hint'],
    dragClass: 'deactivated',
    hint: false,

    dragLeave(event) {
      event.preventDefault();
      Ember.set(this, 'dragClass', 'deactivated');
    },

    dragOver(event) {
      event.preventDefault();
      Ember.set(this, 'dragClass', 'activated');
    },

    drop() {
      Ember.set(this, 'dragClass', 'deactivated');
    }

  });

  _exports.default = _default;
});