define("apollo/models/deductible", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    patientType: (0, _attr.default)('string'),
    productClass: (0, _attr.default)('string'),
    serviceStation: (0, _attr.default)('string'),
    updatedBy: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    insertedBy: (0, _attr.default)('string'),
    insertedAt: (0, _attr.default)('date'),
    coinsurance: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    coinsuranceType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    coinsuranceFloat: Ember.computed('coinsurance', {
      get() {
        return (Ember.get(this, 'coinsurance') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'coinsurance', value * 100);
        return value;
      }

    }),
    coinsuranceLimit: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    coinsuranceLimitFloat: Ember.computed('coinsuranceLimit', {
      get() {
        return (Ember.get(this, 'coinsuranceLimit') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'coinsuranceLimit', value * 100);
        return value;
      }

    }),
    deductible: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    deductibleType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    deductibleFloat: Ember.computed('deductible', {
      get() {
        return (Ember.get(this, 'deductible') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'deductible', value * 100);
        return value;
      }

    }),
    deductibleLimit: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    deductibleLimitFloat: Ember.computed('deductibleLimit', {
      get() {
        return (Ember.get(this, 'deductibleLimit') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'deductibleLimit', value * 100);
        return value;
      }

    }),
    copayment: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    copaymentType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    copaymentFloat: Ember.computed('copayment', {
      get() {
        return (Ember.get(this, 'copayment') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'copayment', value * 100);
        return value;
      }

    }),
    copaymentLimit: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    copaymentLimitFloat: Ember.computed('copaymentLimit', {
      get() {
        return (Ember.get(this, 'copaymentLimit') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'copaymentLimit', value * 100);
        return value;
      }

    }),
    medicalCoinsurance: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    medicalCoinsuranceType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    medicalCoinsuranceFloat: Ember.computed('medicalCoinsurance', {
      get() {
        return (Ember.get(this, 'medicalCoinsurance') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'medicalCoinsurance', value * 100);
        return value;
      }

    }),
    medicalCoinsuranceLimit: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    medicalCoinsuranceLimitFloat: Ember.computed('medicalCoinsuranceLimit', {
      get() {
        return (Ember.get(this, 'medicalCoinsuranceLimit') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'medicalCoinsuranceLimit', value * 100);
        return value;
      }

    }),
    maximumLimit: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    maximumLimitFloat: Ember.computed('maximumLimit', {
      get() {
        return (Ember.get(this, 'maximumLimit') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'maximumLimit', value * 100);
        return value;
      }

    }),
    approvalThreshold: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    approvalThresholdFloat: Ember.computed('approvalThreshold', {
      get() {
        return (Ember.get(this, 'approvalThreshold') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'approvalThreshold', value * 100);
        return value;
      }

    }),
    followUpLimit: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    followUpLimitFloat: Ember.computed('followUpLimit', {
      get() {
        return (Ember.get(this, 'followUpLimit') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'followUpLimit', value * 100);
        return value;
      }

    }),
    policyVersion: (0, _relationships.belongsTo)('policyVersion')
  });

  _exports.default = _default;
});