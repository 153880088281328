define("apollo/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "apollo/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: "".concat(_environment.default.APP.apiUrl, "/token")
  });

  _exports.default = _default;
});