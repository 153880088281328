define("apollo/pods/components/apollo/layout/grid/row/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "grid-row": "_grid-row_jr13sg",
    "col-1": "_col-1_jr13sg",
    "col-2": "_col-2_jr13sg",
    "col-3": "_col-3_jr13sg",
    "col-4": "_col-4_jr13sg",
    "col-5": "_col-5_jr13sg",
    "col-6": "_col-6_jr13sg",
    "col-7": "_col-7_jr13sg",
    "col-8": "_col-8_jr13sg",
    "col-9": "_col-9_jr13sg",
    "col-10": "_col-10_jr13sg",
    "col-11": "_col-11_jr13sg",
    "col-12": "_col-12_jr13sg",
    "rows-1": "_rows-1_jr13sg",
    "rows-2": "_rows-2_jr13sg",
    "rows-3": "_rows-3_jr13sg",
    "style-default": "_style-default_jr13sg",
    "style-overflow": "_style-overflow_jr13sg",
    "margin-none": "_margin-none_jr13sg",
    "margin-small": "_margin-small_jr13sg",
    "align-self-start": "_align-self-start_jr13sg",
    "align-start": "_align-start_jr13sg",
    "align-center": "_align-center_jr13sg",
    "align-right": "_align-right_jr13sg"
  };
  _exports.default = _default;
});