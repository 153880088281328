define("apollo/pods/components/patient-panel/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "panel": "_panel_9c2s1a",
    "labels": "_labels_9c2s1a"
  };
  _exports.default = _default;
});