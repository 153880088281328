define("apollo/pods/components/dot-menu/item/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "span",
    actions: {
      stopPropagation(event) {
        event.stopPropagation();
        let onclick = Ember.get(this, 'onclick');

        if (onclick) {
          onclick(event);
        }
      }

    }
  });

  _exports.default = _default;
});