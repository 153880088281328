define("apollo/services/apollo", ["exports", "ember-ajax/services/ajax", "apollo/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ajax.default.extend({
    session: Ember.inject.service('session'),
    host: _environment.default.APP.baseUrl,
    contentType: 'application/json',
    headers: Ember.computed('session.data.authenticated.access_token', {
      get() {
        let headers = {};
        let accessToken = Ember.get(this, 'session.data.authenticated.access_token');

        if (accessToken) {
          headers.Authorization = "Bearer ".concat(accessToken);
        }

        return headers;
      }

    })
  });

  _exports.default = _default;
});