define("apollo/pods/components/search/products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Eb7hmlox",
    "block": "{\"symbols\":[\"item\",\"Element\"],\"statements\":[[5,\"field/search-box\",[],[[\"@onChange\",\"@value\",\"@icon\",\"@label\",\"@onSelect\",\"@items\"],[[29,\"action\",[[24,0,[]],\"onChange\"],null],[23,\"currentText\"],[23,\"icon\"],[23,\"label\"],[29,\"action\",[[24,0,[]],\"onSelect\"],null],[23,\"results\"]]],{\"statements\":[[0,\"\\n  \"],[6,[24,2,[]],[[13,\"title\",[24,1,[\"name\"]]]],[[\"@icon\"],[[24,1,[\"icon\"]]]],{\"statements\":[[0,\"\\n    \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"item-line\"],[[\"from\"],[\"apollo/pods/components/search/products/styles\"]]]]]],[9],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"code\"],[[\"from\"],[\"apollo/pods/components/search/products/styles\"]]]]]],[9],[1,[24,1,[\"epc\"]],false],[10],[0,\"\\n      \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"name\"],[[\"from\"],[\"apollo/pods/components/search/products/styles\"]]]]]],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1,2]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/search/products/template.hbs"
    }
  });

  _exports.default = _default;
});