define("apollo/pods/components/apollo/layout/relative/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['grid-relative'],
    localClassNameBindings: ['marginLayout', 'panelSize', 'gravityLayout', 'alignLayout', 'borderLayout'],
    margin: 'none',
    gravity: 'horizontal',
    border: 'none',
    marginLayout: Ember.computed('margin', function () {
      return "margin-".concat(Ember.get(this, 'margin'));
    }),
    borderLayout: Ember.computed('border', function () {
      return "border-".concat(Ember.get(this, 'border'));
    }),
    alignLayout: Ember.computed('align', function () {
      return "align-".concat(Ember.get(this, 'align'));
    }),
    gravityLayout: Ember.computed('gravity', function () {
      return "gravity-".concat(Ember.get(this, 'gravity'));
    })
  });

  _exports.default = _default;
});