define("apollo/pods/components/checkmark-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rOe0qaGa",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"checkmark-label \",[25,[\"color\"]],\" \",[25,[\"mold\"]],\" \",[29,\"if\",[[25,[\"value\"]],\"active\"],null]],null]],[[\"from\"],[\"apollo/pods/components/checkmark-label/styles\"]]]]]],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"toggleProperty\"],null]],[12,\"for\",[29,\"if\",[[25,[\"name\"]],[29,\"concat\",[[25,[\"name\"]],\"-checkbox\"],null]],null]],[9],[0,\"\\n  \"],[7,\"img\"],[12,\"class\",[30,[[29,\"local-class\",[\"icon\"],[[\"from\"],[\"apollo/pods/components/checkmark-label/styles\"]]]]]],[12,\"src\",[23,\"moldIcon\"]],[11,\"alt\",\"✓\"],[9],[10],[0,\"\\n  \"],[7,\"input\"],[12,\"class\",[30,[[29,\"local-class\",[\"input invisible\"],[[\"from\"],[\"apollo/pods/components/checkmark-label/styles\"]]]]]],[12,\"id\",[29,\"if\",[[25,[\"name\"]],[29,\"concat\",[[25,[\"name\"]],\"-checkbox\"],null]],null]],[12,\"checked\",[23,\"value\"]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/checkmark-label/template.hbs"
    }
  });

  _exports.default = _default;
});