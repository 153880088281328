define("apollo/pods/components/apollo/link/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "link": "_link_9x812w",
    "color-primary": "_color-primary_9x812w",
    "color-secondary": "_color-secondary_9x812w",
    "color-gray": "_color-gray_9x812w",
    "color-cancel": "_color-cancel_9x812w",
    "color-pdf": "_color-pdf_9x812w",
    "color-excel": "_color-excel_9x812w",
    "size-menu": "_size-menu_9x812w",
    "size-square": "_size-square_9x812w",
    "size-tiny_small": "_size-tiny_small_9x812w",
    "size-small": "_size-small_9x812w",
    "size-normal": "_size-normal_9x812w",
    "size-big": "_size-big_9x812w",
    "design-breadcrumb": "_design-breadcrumb_9x812w",
    "design-button": "_design-button_9x812w _button_moybta _design-default_moybta",
    "design-button-ghost": "_design-button-ghost_9x812w _button_moybta _design-ghost_moybta",
    "design-button-cover": "_design-button-cover_9x812w _button_moybta _design-cover_moybta"
  };
  _exports.default = _default;
});