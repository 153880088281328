define("apollo/pods/components/search/policy-version/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notifications'),
    tagName: '',
    store: Ember.inject.service(),
    records: [],
    currentText: "",
    icon: "search",
    minor: false,
    company_id: 0,
    page_size: 100,

    init() {
      this._super(...arguments);

      this.set('errors', []);
      let value = Ember.get(this, 'value');

      if (value) {
        Ember.get(this, 'store').findRecord('policy-version', value).then(record => {
          Ember.set(this, 'records', [record]);
          Ember.set(this, 'currentText', record.name);
        });
      }
    },

    actions: {
      onChange(query) {
        if (query && query.length >= 3) {
          query = query.replaceAll('*', '%');
          let id = Ember.get(this, 'company_id');
          let filter = {};
          let pType = Ember.get(this, 'type');

          if (pType) {
            filter.p_type = {
              like: pType
            };
          }

          let pageSize = Ember.get(this, 'page_size');

          if (pageSize) {
            filter.name = {
              like: query
            };
          }

          Ember.get(this, 'store').query('policy', {
            filter,
            endpointURI: "companies/".concat(id, "/policies/_search?page_number=1&page_size=").concat(pageSize)
          }).then(records => {
            Ember.set(this, 'records', records);
          });
        } else {
          Ember.set(this, 'records', null);
        }
      },

      onSelect(selected) {
        Ember.set(this, 'currentText', selected.name);
        let change = Ember.get(this, 'onChange');

        if (change) {
          change(selected.cBpartnerId, selected, selected.name);
        }
      }

    }
  });

  _exports.default = _default;
});