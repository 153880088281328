define("apollo/pods/components/tabbox/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['tabbox'],
    selectedTab: "",
    actions: {
      changeTab(tab) {
        Ember.set(this, 'selectedTab', tab);
      }

    }
  });

  _exports.default = _default;
});