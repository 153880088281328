define("apollo/pods/components/forms/file-upload/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "file-button": "_file-button_pmw750",
    "down-arrow": "_down-arrow_pmw750",
    "file-button-left": "_file-button-left_pmw750",
    "file-button-right": "_file-button-right_pmw750",
    "file-input": "_file-input_pmw750",
    "file-menu": "_file-menu_pmw750"
  };
  _exports.default = _default;
});