define("apollo/pods/components/apollo/badage/component", ["exports", "apollo/pods/components/apollo/badage/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['badage'],
    classNameBindings: ['colorClass', 'sizeClass', 'designClass'],
    attributeBindings: ['disabled:disabled'],
    size: 'normal',
    color: 'primary',
    design: 'default',
    colorClass: Ember.computed('color', function () {
      return _styles.default["color-".concat(Ember.get(this, 'color'))];
    }),
    designClass: Ember.computed('design', function () {
      return _styles.default["design-".concat(Ember.get(this, 'design'))];
    }),
    sizeClass: Ember.computed('size', function () {
      return _styles.default["size-".concat(Ember.get(this, 'size'))];
    }),

    click() {
      let action = Ember.get(this, 'action');

      if (action) {
        return action();
      }

      return false;
    }

  });

  _exports.default = _default;
});