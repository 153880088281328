define("apollo/mixins/coding-role-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel() {
      if (!Ember.get(this, 'current.user.hasCodingRole')) {
        return this.transitionTo('authorized.home');
      }

      return this._super(...arguments);
    }

  });

  _exports.default = _default;
});