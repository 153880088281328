define("apollo/pods/components/apollo/layout/grid/row/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['grid-row'],
    localClassNameBindings: ['rowSize', 'colSize', 'rowStyle', 'marginLayout', 'alignRow'],
    rows: 1,
    size: 1,
    style: 'default',
    margin: 'none',
    align: 'start',
    marginLayout: Ember.computed('margin', function () {
      return "margin-".concat(Ember.get(this, 'margin'));
    }),
    alignRow: Ember.computed('align', function () {
      return "align-".concat(Ember.get(this, 'align'));
    }),
    colSize: Ember.computed('size', function () {
      return "col-".concat(Ember.get(this, 'size'));
    }),
    rowSize: Ember.computed('rows', function () {
      return "rows-".concat(Ember.get(this, 'rows'));
    }),
    rowStyle: Ember.computed('style', function () {
      return "style-".concat(Ember.get(this, 'style'));
    })
  });

  _exports.default = _default;
});