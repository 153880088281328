define("apollo/pods/components/search-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-box": "_search-box_o0ovg0",
    "grow": "_grow_o0ovg0",
    "search-icon": "_search-icon_o0ovg0",
    "search-input": "_search-input_o0ovg0 _input_1dnopz",
    "item-list": "_item-list_o0ovg0",
    "element-item": "_element-item_o0ovg0",
    "default": "_default_o0ovg0",
    "label": "_label_o0ovg0 _label_1dnopz",
    "left-label": "_left-label_o0ovg0",
    "right-label": "_right-label_o0ovg0",
    "bottom-label": "_bottom-label_o0ovg0",
    "visible": "_visible_o0ovg0"
  };
  _exports.default = _default;
});