define("apollo/pods/authorized/coding/policy_version/benefits/root/controller", ["exports", "apollo/config/environment", "apollo/pods/objects/export-manager"], function (_exports, _environment, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    notifications: Ember.inject.service('notifications'),
    currentBenefit: null,
    benefitdiagnostics: null,
    showPopup: false,
    page_size: 5,
    data_size: 0,
    page_current: 1,
    total_records: 0,
    showPopupDiagConfExists: false,
    showDiagnosticPopupEdition: false,
    existingDiagnostic: null,
    settings: Ember.computed(function () {
      let token = Ember.get(this, 'session.data.authenticated.access_token');
      return {
        'headers': {
          'Authorization': "Bearer ".concat(token)
        }
      };
    }),
    actions: {
      openDiag(benefit) {
        Ember.set(this, 'currentBenefit', benefit);
        Ember.set(this, 'showPopupDiag', true);
        this.send("changePage", 1, true);
      },

      closePopDiag() {
        let model = Ember.get(this, 'currentBenefit');
        model.rollbackAttributes();
        Ember.set(this, 'showPopupDiag', false);
      },

      async saveDiagnostic(item) {
        let benefit = Ember.get(this, 'currentBenefit');
        let notifications = Ember.get(this, 'notifications');
        let store = Ember.get(this, 'store');

        if (Ember.get(benefit, 'isNew')) {
          notifications.show('warning', 'First save benefit header data.');
          return false;
        }

        let existing = await store.queryRecord('benefit-diagnostic', {
          endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostic/find?code=").concat(item.code)
        });

        if (existing) {
          Ember.set(this, 'showPopupDiagConfExists', true);
          Ember.set(this, 'existingDiagnostic', existing);
          return false;
        }

        let benefitDiagnostic = store.createRecord('benefitDiagnostic', {
          benefit,
          name: item.name,
          helixId: item.id,
          helixCode: item.code
        });
        return benefitDiagnostic.save().then(() => {
          notifications.show('success', 'Diagnostic saved!');
          this.send("changePage", Ember.get(this, 'page_current'), true);
        });
      },

      updateDiagnostic(diagnostic) {
        diagnostic.save();
        return false;
      },

      deleteDiagnostic(diagnostic) {
        let notifications = Ember.get(this, 'notifications');
        return diagnostic.destroyRecord().then(() => {
          notifications.show('success', 'Diagnostic deleted!');
          this.send("changePage", Ember.get(this, 'page_current'), true);
        });
      },

      findByCodeDiagnostic(code) {
        Ember.set(this, 'diagnostics', null);

        if (code.length >= 3) {
          let diagnostics = Ember.get(this, 'store').query('diagnostic', {
            query: code
          });
          Ember.set(this, 'diagnostics', diagnostics);
        }
      },

      uploadCSVDiags(file) {
        let benefit = Ember.get(this, 'currentBenefit');
        let url = "".concat(_environment.default.APP.apiUrl, "/benefits/").concat(Ember.get(benefit, 'id'), "/benefit-diagnostics");
        let settings = Ember.get(this, 'settings');
        let notifications = Ember.get(this, 'notifications');
        return file.upload(url, settings).then(() => {
          this.send("changePage", Ember.get(this, 'page_current'), true);
          return notifications.show('success', 'Diagnostics imported');
        }).catch(error => {
          Ember.set(file, 'queue.files', []);

          if (error.status === 500) {
            return notifications.show('error', 'Error, File format not valid');
          } else {
            return notifications.show('error', 'Error, Diagnostics not imported');
          }
        });
      },

      async downloadTemplate(coverages) {
        let manager = _exportManager.default.create();

        if (coverages) {
          let data = manager.fileFrom('diagnosis', (await coverages));
          return manager.download('diagnostics-export.csv', data);
        } else {
          let data = manager.emptyFile('diagnosis');
          return manager.download('diagnostics-template.csv', data);
        }
      },

      changePage(page, recalculate) {
        Ember.set(this, 'page_current', page);
        let id = Ember.get(this, 'currentBenefit.id');
        let pageSize = Ember.get(this, 'page_size');
        Ember.get(this, 'store').query('benefitDiagnostic', {
          endpointURI: "benefits/".concat(id, "/benefit-diagnostics?page_number=").concat(page, "&page_size=").concat(pageSize)
        }).then(benefitdiagnostics => {
          Ember.set(this, 'benefitdiagnostics', benefitdiagnostics);
        });

        if (recalculate) {
          Ember.get(this, 'store').queryRecord('page-number', {
            endpointURI: "benefits/".concat(id, "/benefit-diagnostics/pages-total?page_size=").concat(pageSize)
          }).then(response => {
            Ember.set(this, 'data_size', response.size);
            Ember.set(this, 'total_records', response.total);

            if (page > response.size && response.size > 0) {
              this.send("changePage", response.size, false);
            }
          });
        }
      },

      closeConfirmationPopupDiag() {
        Ember.set(this, 'showPopupDiagConfExists', false);
      },

      openDiagnosticPopupEdition() {
        Ember.set(this, 'showPopupDiagConfExists', false);
        Ember.set(this, 'showDiagnosticPopupEdition', true);
      },

      closeDiagnosticPopupEdition() {
        Ember.set(this, 'showDiagnosticPopupEdition', false);
      },

      deleteDiagPopup(diagnostic) {
        let notifications = Ember.get(this, 'notifications');
        diagnostic.destroyRecord().then(() => {
          notifications.show('success', 'Diagnostic deleted!');
          Ember.set(this, 'showDiagnosticPopupEdition', false);
          this.send("changePage", Ember.get(this, 'page_current'), true);
        });
      }

    }
  });

  _exports.default = _default;
});