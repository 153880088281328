define("apollo/pods/authorized/claims/encounter/charges/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "smLfPOUr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"breadcrumbs\",null,null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\"],[\"authorized.claims.encounters\"]],{\"statements\":[[0,\"Search Panel\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"Patient Details\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"],[1,[29,\"patient-panel\",null,[[\"encounter\"],[[25,[\"encounter\"]]]]],false],[0,\"\\n\\n\"],[4,\"title-bar\",null,[[\"design\"],[\"underline\"]],{\"statements\":[[0,\"  \"],[7,\"span\"],[9],[0,\"Charges\"],[10],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"encounter\",\"status\"]],\"C\"],null]],null,{\"statements\":[[4,\"widgets/x-button\",null,[[\"color\",\"disabled\"],[\"disabled\",true]],{\"statements\":[[0,\"      \"],[7,\"strong\"],[9],[0,\"Sent\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"widgets/x-button\",null,[[\"color\",\"action\"],[\"send\",[29,\"action\",[[24,0,[]],\"sendEncounter\",[25,[\"encounter\"]]],null]]],{\"statements\":[[0,\"      \"],[7,\"strong\"],[9],[0,\"Send\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[29,\"charges-panel\",null,[[\"model\",\"currency\",\"currencyData\",\"dropLeft\",\"dropRight\",\"onSave\"],[[25,[\"charges\"]],[25,[\"policyVersion\",\"currency\"]],[25,[\"policyVersion\",\"currencyData\"]],[29,\"action\",[[24,0,[]],\"rejectCharge\"],null],[29,\"action\",[[24,0,[]],\"approveCharge\"],null],[29,\"action\",[[24,0,[]],\"splitSave\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/authorized/claims/encounter/charges/template.hbs"
    }
  });

  _exports.default = _default;
});