define("apollo/pods/components/forms/input-select/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_1xrjmd _input_1dnopz",
    "label": "_label_1xrjmd _label_1dnopz",
    "form-input": "_form-input_1xrjmd _form-input_1dnopz",
    "required": "_required_1xrjmd _required_1dnopz",
    "expand-eight": "_expand-eight_1xrjmd _expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_1xrjmd _expand-quarter_1dnopz",
    "expand-half": "_expand-half_1xrjmd _expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_1xrjmd _expand-th-q_1dnopz",
    "expand-full": "_expand-full_1xrjmd _expand-full_1dnopz",
    "input-error": "_input-error_1xrjmd _input-error_1dnopz",
    "error-message": "_error-message_1xrjmd _error-message_1dnopz"
  };
  _exports.default = _default;
});