define("apollo/pods/authorized/insurance/policy/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let policy = this.modelFor('authorized.insurance.policy');
      let company = Ember.get(policy, 'company');
      return Ember.RSVP.hash({
        policy,
        policyVersion: policy.currentVersion,
        priceLists: company.then(company => {
          return Ember.get(this, 'store').query('priceList', {
            endpointURI: "companies/".concat(company.id, "/price-lists")
          });
        })
      });
    },

    setupController: (controller, model) => {
      let notifications = Ember.get(controller, 'notifications');
      let priceListId = Ember.get(model, 'policyVersion.priceListId');
      let priceLists = Ember.get(model, 'priceLists');
      let exists = false;
      priceLists.forEach(pl => {
        if ("".concat(priceListId) === Ember.get(pl, 'id')) {
          exists = true;

          if (pl.endDate && new Date().getTime() >= pl.endDate.getTime()) {
            notifications.show('warning', 'The price list has expired');
          }
        }
      });

      if (!exists) {
        Ember.set(model.policyVersion, 'priceListId', null);
      }

      Ember.set(controller, 'model', model);
      Ember.set(controller, 'policy', model.policy);
      Ember.set(controller, 'policyVersion', model.policyVersion);
      Ember.set(controller, 'priceLists', priceLists);
    },
    actions: {
      willTransition() {
        let policy = Ember.get(this.controller, 'policy');
        let policyVersion = Ember.get(this.controller, 'policyVersion');
        policy.rollbackAttributes();
        policyVersion.rollbackAttributes();
        return true;
      }

    }
  });

  _exports.default = _default;
});