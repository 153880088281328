define("apollo/pods/components/forms/file-upload/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['file-input'],
    showMenu: false,
    actions: {
      toogleMenu() {
        this.toggleProperty("showMenu");
      }

    }
  });

  _exports.default = _default;
});