define("apollo/pods/components/navbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KD3o43ib",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"brand\"],[9],[0,\"Apollo\"],[10],[0,\"\\n\"],[7,\"ul\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[1,[23,\"client\"],false],[10],[0,\"\\n  \"],[7,\"li\"],[9],[1,[23,\"user\"],false],[10],[0,\"\\n  \"],[7,\"li\"],[12,\"class\",[30,[[29,\"local-class\",[\"btn\"],[[\"from\"],[\"apollo/pods/components/navbar/styles\"]]]]]],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[7,\"img\"],[12,\"class\",[30,[[29,\"local-class\",[\"icon\"],[[\"from\"],[\"apollo/pods/components/navbar/styles\"]]]]]],[11,\"src\",\"/assets/icons/power.svg\"],[11,\"alt\",\"logout\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],[25,[\"logout\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/navbar/template.hbs"
    }
  });

  _exports.default = _default;
});