define("apollo/pods/components/checkmark-label/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "checkmark-label": "_checkmark-label_iv7njy",
    "active": "_active_iv7njy",
    "yellow": "_yellow_iv7njy",
    "green": "_green_iv7njy",
    "inverted": "_inverted_iv7njy",
    "invisible": "_invisible_iv7njy"
  };
  _exports.default = _default;
});