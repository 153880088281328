define("apollo/pods/components/login-form/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HaDvRN4U",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\"],[9],[0,\"\\n  Apollo\\n\"],[10],[0,\"\\n\\n\"],[1,[29,\"forms/input-text\",null,[[\"label\",\"placeholder\",\"data-test-id\",\"width\",\"value\",\"onChange\"],[\"Username\",\"Enter email\",\"login-email\",5,[25,[\"email\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"email\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[1,[29,\"forms/input-text\",null,[[\"type\",\"label\",\"placeholder\",\"width\",\"data-test-id\",\"value\",\"onChange\"],[\"password\",\"Password\",\"Enter password\",5,\"login-password\",[25,[\"password\"]],[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"password\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[4,\"widgets/x-button\",null,[[\"color\",\"data-test-id\",\"action\"],[\"save\",\"login-submit\",[29,\"action\",[[24,0,[]],[25,[\"authenticate\"]],[25,[\"email\"]],[25,[\"password\"]]],null]]],{\"statements\":[[0,\"  Login\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/login-form/template.hbs"
    }
  });

  _exports.default = _default;
});