define("apollo/pods/components/apollo/button/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "button": "_button_moybta",
    "ripple": "_ripple_moybta",
    "color-primary": "_color-primary_moybta",
    "color-secondary": "_color-secondary_moybta",
    "color-gray": "_color-gray_moybta",
    "color-cancel": "_color-cancel_moybta",
    "color-pdf": "_color-pdf_moybta",
    "color-excel": "_color-excel_moybta",
    "design-default": "_design-default_moybta",
    "design-ghost": "_design-ghost_moybta",
    "design-simple": "_design-simple_moybta",
    "design-breadcrumb": "_design-breadcrumb_moybta",
    "design-cover": "_design-cover_moybta",
    "size-menu": "_size-menu_moybta",
    "size-square": "_size-square_moybta",
    "size-tiny_small": "_size-tiny_small_moybta",
    "size-small": "_size-small_moybta",
    "size-normal": "_size-normal_moybta",
    "size-big": "_size-big_moybta",
    "size-full": "_size-full_moybta"
  };
  _exports.default = _default;
});