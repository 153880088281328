define("apollo/helpers/filter-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterContains = filterContains;
  _exports.default = void 0;

  function filterContains([text, text2]) {
    if (text === undefined || text2 === undefined) {
      return true;
    }

    let contains = false;
    let values = text2.split(" ");
    let comparator = text.toUpperCase();

    if (values) {
      for (let i = 0; i < values.length; i++) {
        let element = values[i].toUpperCase();

        if (comparator.indexOf(element) > -1) {
          contains = true;
        } else {
          contains = false;
          break;
        }
      }
    }

    return contains;
  }

  var _default = Ember.Helper.helper(filterContains);

  _exports.default = _default;
});