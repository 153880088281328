define("apollo/pods/components/field/check-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "label",
    localClassNames: ['form-input'],
    localClassNameBindings: ['widthClass', 'colorClass', 'sizeClass', 'designClass'],
    name: '',
    width: 'default',
    icon: 'check',
    value: false,
    color: 'secondary',
    design: 'default',
    colorClass: Ember.computed('color', function () {
      return "color-".concat(Ember.get(this, 'color'));
    }),
    designClass: Ember.computed('design', function () {
      return "design-".concat(Ember.get(this, 'design'));
    }),
    widthClass: Ember.computed('width', function () {
      switch ("".concat(Ember.get(this, 'width'))) {
        case 'stretch':
          return 'expand-stretch';

        default:
          return 'expand-default';
      }
    }),
    displayIcon: Ember.computed('value', 'design', function () {
      let value = Ember.get(this, 'value');
      let design = Ember.get(this, 'design');
      return value || ['circle', 'ghost'].includes(design);
    }),

    click() {
      let readOnly = Ember.get(this, 'readOnly');

      if (!readOnly) {
        this.send('change', !Ember.get(this, 'value'));
      }

      return false;
    },

    actions: {
      change(value) {
        Ember.set(this, 'value', value);
        let change = Ember.get(this, 'onChange');

        if (change) {
          change(value);
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});