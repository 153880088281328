define("apollo/pods/components/notification-mask/notification/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "notification": "_notification_1qakrj",
    "warning": "_warning_1qakrj",
    "error": "_error_1qakrj",
    "success": "_success_1qakrj",
    "info": "_info_1qakrj"
  };
  _exports.default = _default;
});