define("apollo/pods/components/paginate/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "pagination-button": "_pagination-button_p94q9z",
    "active": "_active_p94q9z",
    "design-rounded": "_design-rounded_p94q9z",
    "design-dotted": "_design-dotted_p94q9z",
    "design-default": "_design-default_p94q9z",
    "pagination-pages": "_pagination-pages_p94q9z",
    "pagination-input": "_pagination-input_p94q9z"
  };
  _exports.default = _default;
});