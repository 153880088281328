define("apollo/pods/components/widgets/table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cKi7Si07",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1,[[29,\"hash\",null,[[\"header\",\"body\",\"normal-row\",\"row\"],[[29,\"component\",[\"widgets/table/header\"],null],[29,\"component\",[\"widgets/table/body\"],null],[29,\"component\",[\"widgets/table/normal-row\"],null],[29,\"component\",[\"widgets/table/link-row\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/widgets/table/template.hbs"
    }
  });

  _exports.default = _default;
});