define("apollo/pods/components/tab-menu/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['tab-menu'],
    showInsuranceOption: Ember.computed.bool('current.user.hasInsuranceRole'),
    showClaimOption: Ember.computed.bool('current.user.hasClaimRole'),
    showCodingOption: Ember.computed.bool('current.user.hasCodingRole'),
    showImportOption: Ember.computed.bool('current.user.hasImportRole')
  });

  _exports.default = _default;
});