define("apollo/pods/components/patient-panel/field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WlgK4Jan",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"field\"],[[\"from\"],[\"apollo/pods/components/patient-panel/field/styles\"]]]]]],[9],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"field-label\"],[[\"from\"],[\"apollo/pods/components/patient-panel/field/styles\"]]]]]],[9],[1,[23,\"label\"],false],[10],[0,\"\\n  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[\"field-value\"],[[\"from\"],[\"apollo/pods/components/patient-panel/field/styles\"]]]]]],[9],[15,1],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/patient-panel/field/template.hbs"
    }
  });

  _exports.default = _default;
});