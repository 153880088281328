define("apollo/pods/authorized/insurance/companies/new/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let client = Ember.get(this, 'current.user.client');
      return Ember.RSVP.hash({
        client,
        company: Ember.get(this, 'store').createRecord('company', {
          client
        })
      });
    },

    afterModel(model) {
      if (Ember.get(model, 'client.systemId')) {
        let company = Ember.get(model, 'company');
        company.rollbackAttributes();
        this.transitionTo('authorized.insurance.companies');
      }
    },

    setupController: (controller, model) => {
      Ember.set(controller, 'model', model);
      Ember.set(controller, 'company', model.company);
    },
    actions: {
      willTransition() {
        let company = Ember.get(this, 'controller.company');

        if (Ember.get(company, 'isNew')) {
          company.rollbackAttributes();
        }

        return true;
      }

    }
  });

  _exports.default = _default;
});