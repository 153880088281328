define("apollo/pods/components/draggable-item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "draggable-item": "_draggable-item_7c8doj"
  };
  _exports.default = _default;
});