define("apollo/models/benefit", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    code: (0, _attr.default)('string'),
    amount: (0, _attr.default)('number'),
    amountFloat: Ember.computed('amount', {
      get() {
        return (Ember.get(this, 'amount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'amount', value * 100);
        return value;
      }

    }),
    amountType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    policyVersion: (0, _relationships.belongsTo)('policyVersion'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    included: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    preauthorization: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    minAmount: (0, _attr.default)('number'),
    minAmountFloat: Ember.computed('amount', {
      get() {
        return (Ember.get(this, 'minAmount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'minAmount', value * 100);
        return value;
      }

    }),
    benefitDiagnostics: (0, _relationships.hasMany)('benefitDiagnostic'),
    coverages: (0, _relationships.hasMany)('coverage')
  });

  _exports.default = _default;
});