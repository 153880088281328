define("apollo/pods/components/apollo/table/header/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "design-title": "_design-title_wyxf12",
    "design-subtitle": "_design-subtitle_wyxf12"
  };
  _exports.default = _default;
});