define("apollo/pods/components/insurance/coverage/threshold/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "div",
    localClassNames: "threshold-popup",
    open: false,
    actions: {
      toogle() {
        this.toggleProperty('open');
      }

    }
  });

  _exports.default = _default;
});