define("apollo/pods/components/apollo/column/sort/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'th',
    order: null,
    column: null,
    hideControls: false,
    value: {},
    ascending: Ember.computed('value', 'order', 'column', function () {
      let column = Ember.get(this, 'column');
      let value = Ember.get(this, 'value');
      return value.name === column && value.order === 'asc';
    }),
    descending: Ember.computed('value', 'order', 'column', function () {
      let column = Ember.get(this, 'column');
      let value = Ember.get(this, 'value');
      return value.name === column && value.order === 'desc';
    }),
    display: Ember.computed('column', function () {
      let column = Ember.get(this, 'column');
      let hide = Ember.get(this, 'hideControls');
      return column && !hide;
    }),

    click() {
      let column = Ember.get(this, 'column');

      if (column) {
        let click = Ember.get(this, 'action');
        let order = Ember.get(this, 'order');
        Ember.set(this, 'order', !order);
        let sort = Ember.get(this, 'value');

        if (!sort) {
          sort = {};
        }

        if (sort.name === column) {
          sort.order = sort.order === "asc" ? "desc" : "asc";
        } else {
          sort = {
            name: column,
            order: "asc"
          };
        }

        if (click) {
          click(sort);
        }
      }
    }

  });

  _exports.default = _default;
});