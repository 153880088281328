define("apollo/models/coverage", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    epc: (0, _attr.default)('string'),
    updatedBy: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    insertedBy: (0, _attr.default)('string'),
    insertedAt: (0, _attr.default)('date'),
    amount: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    amountFloat: Ember.computed('amount', {
      get() {
        return (Ember.get(this, 'amount') || 0) / 100;
      },

      set(_, value) {
        Ember.set(this, 'amount', value * 100);
        return value;
      }

    }),
    amountType: (0, _attr.default)('string', {
      defaultValue: 'currency'
    }),
    excluded: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    preauthorization: (0, _attr.default)('boolean'),
    pType: (0, _attr.default)('string'),
    benefit: (0, _relationships.belongsTo)('benefit'),
    limitRules: (0, _relationships.hasMany)('limit-rules')
  });

  _exports.default = _default;
});