define("apollo/pods/components/dot-menu/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "menu-item": "_menu-item_2vpb9j",
    "icon": "_icon_2vpb9j",
    "icon-dot": "_icon-dot_2vpb9j",
    "items": "_items_2vpb9j",
    "right": "_right_2vpb9j"
  };
  _exports.default = _default;
});