define("apollo/pods/components/apollo/link/component", ["exports", "apollo/pods/components/apollo/link/styles"], function (_exports, _styles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    tagName: 'span',
    localClassNames: ['link'],
    classNameBindings: ['colorClass', 'sizeClass', 'designClass'],
    size: 'normal',
    color: 'primary',
    colorClass: Ember.computed('color', function () {
      return _styles.default["color-".concat(Ember.get(this, 'color'))];
    }),
    sizeClass: Ember.computed('size', function () {
      return _styles.default["size-".concat(Ember.get(this, 'size'))];
    }),
    designClass: Ember.computed('design', function () {
      return _styles.default["design-".concat(Ember.get(this, 'design'))];
    })
  });

  _exports.default = _default;
});