define("apollo/pods/authorized/insurance/policy/policy_versions/root/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      update(version) {
        version.save();
        return false;
      }

    }
  });

  _exports.default = _default;
});