define("apollo/pods/components/forms/input-combo/item/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "combo-item": "_combo-item_do34ld",
    "combo-check": "_combo-check_do34ld",
    "combo-text": "_combo-text_do34ld",
    "multiline": "_multiline_do34ld"
  };
  _exports.default = _default;
});