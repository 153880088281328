define("apollo/pods/components/field/label/currency/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    localClassNames: ['currency-label'],
    type: 'currency',
    currency: 'USD',
    formated: Ember.computed('value', 'type', function () {
      let value = Ember.get(this, 'value');
      let currency = Ember.get(this, 'currency');
      let formater = {};

      switch (currency) {
        case 'SAR':
          formater = {
            separator: ".",
            prefix: "",
            suffix: " SAR",
            decimal: 2
          };
          break;

        case 'EUR':
          formater = {
            separator: ".",
            prefix: "",
            suffix: " €",
            decimal: 2
          };
          break;

        case 'MXN':
          formater = {
            separator: ".",
            prefix: "$ ",
            suffix: " MXN",
            decimal: 2
          };
          break;

        case 'USD':
          formater = {
            separator: ".",
            prefix: "$ ",
            suffix: " USD",
            decimal: 2
          };
          break;

        case 'CLP':
          formater = {
            separator: ".",
            prefix: "$ ",
            suffix: " CLP",
            decimal: 0
          };
          break;

        default:
          formater = {
            separator: ".",
            prefix: "",
            suffix: " ".concat(currency),
            decimal: 2
          };
      }

      return "".concat(formater.prefix).concat(((value || 0) / 100.00).toFixed(formater.decimal)).concat(formater.suffix);
    })
  });

  _exports.default = _default;
});