define("apollo/pods/components/insurance/companies/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "link-helper": "_link-helper_j5h26q",
    "light-text": "_light-text_j5h26q",
    "readOnly": "_readOnly_j5h26q",
    "badage": "_badage_j5h26q",
    "badage-error": "_badage-error_j5h26q",
    "badage-warning": "_badage-warning_j5h26q",
    "badage-ok": "_badage-ok_j5h26q"
  };
  _exports.default = _default;
});