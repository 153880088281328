define("apollo/pods/authorized/insurance/company/policies/policy_archives/style", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-form": "_search-form_nv4wac",
    "inputs": "_inputs_nv4wac",
    "search-button": "_search-button_nv4wac",
    "dates": "_dates_nv4wac"
  };
  _exports.default = _default;
});