define("apollo/pods/authorized/insurance/companies/new/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "diRMh/Ow",
    "block": "{\"symbols\":[],\"statements\":[[4,\"breadcrumbs\",null,null,{\"statements\":[[0,\"  \"],[4,\"link-to\",null,[[\"route\"],[\"authorized.insurance.companies\"]],{\"statements\":[[0,\"Insurance Companies\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\"],[9],[0,\"Create New\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"center-content\"],[9],[0,\"\\n  \"],[1,[29,\"insurance/companies-form\",null,[[\"company\",\"saveAction\",\"cancelAction\"],[[25,[\"company\"]],[29,\"action\",[[24,0,[]],\"saveCompany\"],null],[29,\"action\",[[24,0,[]],\"goBack\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/authorized/insurance/companies/new/template.hbs"
    }
  });

  _exports.default = _default;
});