define("apollo/pods/components/apollo/paginate/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "paginate": "_paginate_15gj17",
    "paginate-pages": "_paginate-pages_15gj17",
    "paginate-center": "_paginate-center_15gj17",
    "pagination-button": "_pagination-button_15gj17",
    "pagination-pages-old": "_pagination-pages-old_15gj17",
    "dir-right": "_dir-right_15gj17",
    "dir-left": "_dir-left_15gj17",
    "design-default": "_design-default_15gj17",
    "pagination-button-dots": "_pagination-button-dots_15gj17",
    "active": "_active_15gj17",
    "pagination-angle": "_pagination-angle_15gj17",
    "pagination-pages": "_pagination-pages_15gj17",
    "pagination-pages-label": "_pagination-pages-label_15gj17",
    "pagination-input": "_pagination-input_15gj17"
  };
  _exports.default = _default;
});