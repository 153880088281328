define("apollo/pods/objects/export-manager", ["exports", "npm:file-saver", "npm:jspdf", "npm:jspdf-autotable", "moment"], function (_exports, _npmFileSaver, _npmJspdf, _npmJspdfAutotable, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    coverage: {
      epc: 'string',
      name: 'string',
      amount: 'number',
      amount_type: 'string',
      preauthorization: 'boolean',
      excluded: 'boolean',
      p_type: 'string'
    },
    diagnosis: {
      name: 'string',
      helix_code: 'string',
      helix_id: 'number',
      preauthorization: 'boolean',
      excluded: 'boolean'
    },
    policy: {
      sequenceNumber: 'string',
      policyName: 'string',
      startDate: 'date',
      endDate: 'date',
      currency: 'string',
      active: 'boolean',
      preauthorization: 'boolean'
    },

    emptyFile(type) {
      return Object.keys(Ember.get(this, type)).map(column => {
        return "\"".concat(column, "\"");
      }).join(',');
    },

    fileFrom(type, data) {
      let columns = Ember.get(this, type);
      let headers = this.emptyFile(type);

      if (data) {
        let records = data.map(model => {
          return Object.keys(columns).map(column => {
            if (Ember.get(model, Ember.String.camelize(column)) !== undefined) {
              if (columns[column] === 'string') {
                return "\"".concat(Ember.get(model, Ember.String.camelize(column)), "\"");
              } else if (columns[column] === 'date') {
                let date = (0, _moment.default)(Ember.get(model, Ember.String.camelize(column))).format("DD/MM/YYYY");
                return "\"".concat(date, "\"");
              } else {
                return Ember.get(model, Ember.String.camelize(column));
              }
            }
          }).join(',');
        }).join('\n');
        return headers + '\n' + records;
      }

      return headers;
    },

    download(filename, data) {
      let blob = new Blob([data], {
        type: 'text/csv;charset=utf-8'
      });
      return (0, _npmFileSaver.default)(blob, filename);
    },

    downloadPDF(filename, htmlTableId) {
      let pdf = new _npmJspdf.default();
      pdf.autoTable({
        html: '#' + htmlTableId
      });
      return pdf.save(filename);
    }

  });

  _exports.default = _default;
});