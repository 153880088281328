define("apollo/pods/components/field/date-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_96s807 _input_lqr8xx",
    "input-readonly": "_input-readonly_96s807 _input_lqr8xx _input-readonly_lqr8xx",
    "label": "_label_96s807 _label_lqr8xx",
    "form-input": "_form-input_96s807 _form-input_lqr8xx",
    "required": "_required_96s807 _required_lqr8xx",
    "left-label": "_left-label_96s807 _left-label_lqr8xx",
    "right-label": "_right-label_96s807 _right-label_lqr8xx",
    "bottom-label": "_bottom-label_96s807 _bottom-label_lqr8xx",
    "input-error": "_input-error_96s807 _input-error_lqr8xx",
    "error-message": "_error-message_96s807 _error-message_lqr8xx",
    "input-icon": "_input-icon_96s807"
  };
  _exports.default = _default;
});