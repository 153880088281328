define("apollo/pods/error/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rNDKbrY0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\"],[9],[0,\"Error\"],[10],[0,\"\\n\\n\"],[7,\"p\"],[9],[0,\"We ran into a problem processing your request.\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/error/template.hbs"
    }
  });

  _exports.default = _default;
});