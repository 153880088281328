define("apollo/pods/authorized/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zu1lQQDt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"main-screen\"],[[\"from\"],[\"apollo/pods/authorized/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"tokenService\",\"authenticatedByToken\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"navbar\",null,[[\"client\",\"user\",\"logout\"],[[25,[\"current\",\"user\",\"client\",\"name\"]],[25,[\"current\",\"user\",\"email\"]],[29,\"action\",[[24,0,[]],\"logout\"],null]]]],false],[0,\"\\n    \"],[1,[23,\"tab-menu\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"main-content\"],[[\"from\"],[\"apollo/pods/authorized/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"outlet\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/authorized/template.hbs"
    }
  });

  _exports.default = _default;
});