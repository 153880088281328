define("apollo/models/encounter", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships", "ember-api-actions"], function (_exports, _model, _attr, _relationships, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    dateStart: (0, _attr.default)('date'),
    diagnostic: (0, _attr.default)('string'),
    client: (0, _relationships.belongsTo)('client'),
    policyVersion: (0, _relationships.belongsTo)('policy-version'),
    charges: (0, _relationships.hasMany)('charge'),
    send: (0, _emberApiActions.memberAction)({
      path: 'send'
    }),
    //Patient Details
    patientType: (0, _attr.default)('string'),
    productClass: (0, _attr.default)('string'),
    estServ: (0, _attr.default)('string'),
    patientName: (0, _attr.default)('string'),
    birthday: (0, _attr.default)('date'),
    age: (0, _attr.default)('string'),
    record: (0, _attr.default)('string'),
    physician: (0, _attr.default)('string'),
    account: (0, _attr.default)('string'),
    location: (0, _attr.default)('string'),
    weight: (0, _attr.default)('string'),
    height: (0, _attr.default)('string'),
    admission: (0, _attr.default)('date'),
    discharge: (0, _attr.default)('date'),
    status: (0, _attr.default)('string')
  });

  _exports.default = _default;
});