define("apollo/pods/authorized/imports/policies/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "import-layout": "_import-layout_15qtra",
    "import-layout-header": "_import-layout-header_15qtra",
    "import-layout-toolbar": "_import-layout-toolbar_15qtra",
    "badage": "_badage_15qtra",
    "badage-error": "_badage-error_15qtra",
    "badage-ok": "_badage-ok_15qtra",
    "form-inputs": "_form-inputs_15qtra",
    "form-actions": "_form-actions_15qtra",
    "sections": "_sections_15qtra",
    "loading-sceen": "_loading-sceen_15qtra"
  };
  _exports.default = _default;
});