define("apollo/pods/authorized/coding/companies/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return Ember.get(this, 'current.user.client').then(client => {
        return Ember.get(client, 'companies');
      });
    }

  });

  _exports.default = _default;
});