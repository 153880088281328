define("apollo/pods/components/forms/input-checkbox/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B0+p4qFg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"input\"],[12,\"class\",[30,[[29,\"local-class\",[\"input\"],[[\"from\"],[\"apollo/pods/components/forms/input-checkbox/styles\"]]]]]],[12,\"checked\",[23,\"value\"]],[12,\"onclick\",[29,\"action\",[[24,0,[]],[25,[\"onChange\"]]],[[\"value\"],[\"target.checked\"]]]],[11,\"type\",\"checkbox\"],[9],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\"],[12,\"class\",[30,[[29,\"local-class\",[[29,\"concat\",[\"label \",[29,\"if\",[[25,[\"required\"]],\"required\"],null]],null]],[[\"from\"],[\"apollo/pods/components/forms/input-checkbox/styles\"]]]]]],[9],[0,\"\\n    \"],[1,[23,\"label\"],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/forms/input-checkbox/template.hbs"
    }
  });

  _exports.default = _default;
});