define("apollo/pods/components/field/list-box/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_tqzoc1 _input_lqr8xx",
    "input-readonly": "_input-readonly_tqzoc1 _input-readonly_lqr8xx",
    "label": "_label_tqzoc1 _label_lqr8xx",
    "form-input": "_form-input_tqzoc1 _form-input_lqr8xx",
    "required": "_required_tqzoc1 _required_lqr8xx",
    "left-label": "_left-label_tqzoc1 _left-label_lqr8xx",
    "right-label": "_right-label_tqzoc1 _right-label_lqr8xx",
    "bottom-label": "_bottom-label_tqzoc1 _bottom-label_lqr8xx",
    "input-error": "_input-error_tqzoc1 _input-error_lqr8xx",
    "error-message": "_error-message_tqzoc1 _error-message_lqr8xx",
    "input-icon": "_input-icon_tqzoc1",
    "menu-mask": "_menu-mask_tqzoc1",
    "menu-list": "_menu-list_tqzoc1"
  };
  _exports.default = _default;
});