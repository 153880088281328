define("apollo/pods/components/field/date-box/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-text'],
    localClassNameBindings: ['labelPosition'],
    type: 'text',
    placeholder: '',
    icon: "calendar",
    defaultDate: new Date(),
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + "-label";
    }),
    actions: {
      toggleCalendar() {
        this.flatpickrRef.toggle();
      },

      clearCalendar() {
        this.flatpickrRef.clear();
      },

      change(value) {
        Ember.set(this, 'value', value[0]);
        let change = Ember.get(this, 'onChange');

        if (change) {
          change(value[0]);
        }

        return false;
      }

    }
  });

  _exports.default = _default;
});