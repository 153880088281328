define("apollo/tests/mirage/mirage.lint-test", [], function () {
  "use strict";

  QUnit.module('ESLint | mirage');
  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/benefit.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/benefit.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/client.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/client.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/company.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/company.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/policy-log.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/policy-log.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/policy-version.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/policy-version.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/policy.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/policy.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/product.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/product.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/service-station.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/service-station.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });
  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});