define("apollo/pods/components/fragment/settings/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    hermes: Ember.inject.service('hermes'),
    language: localStorage.getItem('apollo_language') || 'en',
    preview_option: Boolean(localStorage.getItem('apollo_preview_option')),
    direction: localStorage.getItem('apollo_direction'),
    header: Boolean(localStorage.getItem('apollo_header')),
    actions: {
      change(property, value) {
        localStorage.setItem("apollo_".concat(property), value);
        Ember.set(this, property, value);
      },

      reloadLabels() {
        let hermes = Ember.get(this, 'hermes');
        let language = Ember.get(this, 'language');
        hermes.forceReload(language);
      }

    }
  });

  _exports.default = _default;
});