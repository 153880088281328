define("apollo/router", ["exports", "apollo/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    // Unauthorized routes
    this.route('login');
    this.route('error'); // Authorized routes

    this.route('authorized', {
      path: '/'
    }, function () {
      this.route('home');
      this.route('insurance', function () {
        this.route('companies', function () {
          this.route('root', {
            path: '/'
          });
          this.route('new');
        });
        this.route('company', {
          path: '/companies/:company_id'
        }, function () {
          this.route('edit');
          this.route('policies', function () {
            this.route('root', {
              path: '/'
            });
            this.route('new');
            this.route('policy_archives');
          });
        });
        this.route('policy', {
          path: '/policies/:policy_id'
        }, function () {
          this.route('edit');
          this.route('policy_versions', function () {
            this.route('root', {
              path: '/'
            });
            this.route('new');
          });
          this.route('policy_logs', {
            path: '/:policy_id/policy-logs'
          });
        });
        this.route('policy_version', {
          path: '/policy_versions/:policy_version_id'
        }, function () {
          this.route('benefits', function () {
            this.route('root', {
              path: '/'
            });
            this.route('coverages', {
              path: '/coverages'
            });
            this.route('diagnostics', {
              path: '/diagnostics'
            });
          });
        });
        this.route('benefit', {
          path: '/benefit/:benefit_id'
        }, function () {
          this.route('root', {
            path: '/'
          });
        });
      });
      this.route('claims', function () {
        this.route('encounters');
        this.route('encounter', {
          path: '/encounters/:encounter_id'
        }, function () {
          this.route('charges');
        });
      });
      this.route('coding', function () {
        this.route('companies');
        this.route('company', {
          path: '/companies/:company_id'
        }, function () {
          this.route('policies', function () {
            this.route('root', {
              path: '/'
            });
            this.route('new');
          });
        });
        this.route('policy', {
          path: '/policies/:policy_id'
        }, function () {
          this.route('policy_versions', function () {
            this.route('root', {
              path: '/'
            });
            this.route('new');
          });
        });
        this.route('policy_version', {
          path: '/policy_versions/:policy_version_id'
        }, function () {
          this.route('benefits', function () {
            this.route('root', {
              path: '/'
            });
          });
        });
        this.route('benefit', {
          path: '/benefit/:benefit_id'
        });
      });
      this.route('imports', function () {
        this.route('policies');
        this.route('deductibles');
        this.route('products');
        this.route('diagnostics');
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});