define("apollo/helpers/format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatCurrency = formatCurrency;
  _exports.default = void 0;

  function formatCurrency([value = 0, format = '$%d.%c']) {
    let cents = _padNumber(Math.floor(value % 100), 1);

    let dosh = Math.floor(Math.abs(value) / 100);
    let sign = value < 0 ? '-' : '';
    let amount = '';

    while (dosh > 1000) {
      amount = ",".concat(_padNumber(dosh % 1000, 2)).concat(amount);
      dosh = Math.floor(dosh / 1000);
    }

    amount = "".concat(dosh).concat(amount);
    return [['%d', amount || '0'], ['%c', cents], ['%s', sign]].reduce((str, f) => str.replace(f[0], f[1]), format);
  }

  function _padNumber(number, zeroes) {
    let base = Math.pow(10, zeroes);
    let padded = '';
    number = Math.abs(number);

    while (base > number) {
      padded += '0';
      base = Math.floor(base / 10);
    }

    return number === 0 ? padded : "".concat(padded).concat(number);
  }

  var _default = Ember.Helper.helper(formatCurrency);

  _exports.default = _default;
});