define("apollo/models/benefit-diagnostic", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    helixCode: (0, _attr.default)('string'),
    helixId: (0, _attr.default)('number'),
    benefit: (0, _relationships.belongsTo)('benefit'),
    excluded: (0, _attr.default)('boolean'),
    preauthorization: (0, _attr.default)('boolean'),
    updatedBy: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    insertedBy: (0, _attr.default)('string'),
    insertedAt: (0, _attr.default)('date')
  });

  _exports.default = _default;
});