define("apollo/models/import-deductible", ["exports", "ember-data/model", "ember-data/attr", "ember-api-actions"], function (_exports, _model, _attr, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { belongsTo } from 'ember-data/relationships';
  var _default = _model.default.extend({
    majorCompany: (0, _attr.default)('string'),
    minorCompany: (0, _attr.default)('string'),
    policyIdentifier: (0, _attr.default)('string'),
    policyName: (0, _attr.default)('string'),
    sequenceNumber: (0, _attr.default)('string'),
    patientType: (0, _attr.default)('string'),
    productClass: (0, _attr.default)('string'),
    serviceStation: (0, _attr.default)('string'),
    coinsurance: (0, _attr.default)('string'),
    coinsuranceLimit: (0, _attr.default)('string'),
    deductible: (0, _attr.default)('string'),
    deductibleLimit: (0, _attr.default)('string'),
    copayment: (0, _attr.default)('string'),
    copaymentLimit: (0, _attr.default)('string'),
    medicalCoinsurance: (0, _attr.default)('string'),
    medicalCoinsuranceLimit: (0, _attr.default)('string'),
    maximumLimit: (0, _attr.default)('string'),
    approvalThreshold: (0, _attr.default)('string'),
    followUpLimit: (0, _attr.default)('string'),
    policyVersionId: (0, _attr.default)('number'),
    companyId: (0, _attr.default)('number'),
    status: (0, _attr.default)('string'),
    message: (0, _attr.default)('string'),
    process: (0, _emberApiActions.collectionAction)({
      path: '_process',
      type: 'post',
      urlType: 'findRecord'
    }),
    clean: (0, _emberApiActions.collectionAction)({
      path: '_clean',
      type: 'post',
      urlType: 'findRecord'
    })
  });

  _exports.default = _default;
});