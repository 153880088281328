define("apollo/pods/components/charges-panel/table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isLeft: Ember.computed('side', function () {
      let side = Ember.get(this, "side");
      return side === "left";
    }),
    computeBalance: Ember.computed('charges.[]', function () {
      return Ember.get(this, 'charges').reduce((total, charge) => {
        return total + (Ember.get(charge, 'amount') - Ember.get(charge, 'discount'));
      }, 0);
    })
  });

  _exports.default = _default;
});