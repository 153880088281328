define("apollo/models/user", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    email: (0, _attr.default)('string'),
    role: (0, _attr.default)('string'),
    orgId: (0, _attr.default)('string'),
    client: (0, _relationships.belongsTo)('client'),
    hasInsuranceRole: Ember.computed('role', function () {
      return this.role !== null && this.role.includes('I');
    }),
    hasClaimRole: Ember.computed('role', function () {
      return this.role !== null && this.role.includes('C');
    }),
    hasCodingRole: Ember.computed('role', function () {
      return this.role !== null && this.role.includes('D');
    }),
    hasImportRole: Ember.computed('role', function () {
      return this.role !== null && this.role.includes('D');
    })
  });

  _exports.default = _default;
});