define("apollo/pods/components/insurance/diagnostic/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "code": "_code_6zvzx0",
    "name": "_name_6zvzx0"
  };
  _exports.default = _default;
});