define("apollo/pods/authorized/coding/company/policies/root/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      let company = this.modelFor('authorized.coding.company');
      return company;
    },

    setupController(controller, model) {
      this._super(...arguments);

      Ember.set(controller, 'company', model);
      controller.loadPages(1, true);
    }

  });

  _exports.default = _default;
});