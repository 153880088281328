define("apollo/pods/application/route", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'authorized.home',
    hermes: Ember.inject.service('hermes'),
    interceptRoute: null,

    beforeModel(transition) {
      Ember.set(this, 'interceptRoute', transition.targetName);
    },

    async model(params) {
      let {
        token,
        language
      } = params;
      let session = Ember.get(this, 'session');
      let tokenService = Ember.get(this, 'tokenService');
      let tags = Ember.get(this, 'hermes');
      await tags.loadTags(language);

      if (Ember.isPresent(token)) {
        Ember.set(this, 'routeAfterAuthentication', Ember.get(this, 'interceptRoute'));
        await session.authenticate('authenticator:token', token).then(() => {
          Ember.set(tokenService, 'authenticatedByToken', true);
        }).catch(() => {
          this.transitionTo('error');
        });
      } else if (Ember.get(tokenService, 'authenticatedByToken')) {
        session.invalidate();
        this.transitionTo('error');
        Ember.set(tokenService, 'authenticatedByToken', false);
      }
    }

  });

  _exports.default = _default;
});