define("apollo/pods/components/search/business-partner/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notifications: Ember.inject.service('notifications'),
    tagName: '',
    store: Ember.inject.service(),
    records: [],
    currentText: "",
    icon: "search",
    minor: false,

    init() {
      this._super(...arguments);

      this.set('errors', []);
      let value = Ember.get(this, 'value');

      if (value) {
        Ember.get(this, 'store').findRecord('business-partner', value).then(record => {
          Ember.set(this, 'records', [record]);
          Ember.set(this, 'currentText', record.name);
        });
      }
    },

    actions: {
      onChange(query) {
        if (query && query.length >= 3) {
          let params = {
            query
          };

          if (Ember.get(this, 'minor')) {
            params = {
              query,
              minor: true
            };
          }

          Ember.get(this, 'store').query('business-partner', params).then(records => {
            Ember.set(this, 'records', records);
          });
        } else {
          Ember.set(this, 'records', null);
        }
      },

      onSelect(selected) {
        Ember.set(this, 'currentText', selected.name);
        let change = Ember.get(this, 'onChange');

        if (change) {
          change(selected.cBpartnerId, selected, selected.name);
        }
      }

    }
  });

  _exports.default = _default;
});