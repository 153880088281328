define("apollo/pods/authorized/claims/encounters/controller", ["exports", "apollo/config/environment", "apollo/pods/objects/ref-list", "apollo/pods/objects/export-manager"], function (_exports, _environment, _refList, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    apollo: Ember.inject.service('apollo'),
    filters: {
      startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
      endDate: new Date()
    },
    patientName: null,
    test: null,
    bpName: null,
    startDate: null,
    endDate: null,
    page_size: 15,
    data_size: 0,
    page_current: 1,
    patientTypeList: Ember.computed(function () {
      return _refList.default.create().getList("patientType");
    }),
    productClassList: Ember.computed(function () {
      return _refList.default.create().getList("productClass");
    }),

    getFilters() {
      let filters = Ember.get(this, 'filters');
      let filter = {};

      if (filters.test) {
        filter.test = {
          join_test: filters.test
        };
      }

      let dateFilter = {};

      if (filters.startDate) {
        dateFilter.gte = filters.startDate.toISOString();
      }

      if (filters.endDate) {
        filters.endDate.setDate(filters.endDate.getDate() + 1);
        dateFilter.lte = filters.endDate.toISOString();
      }

      if (!this._isEmpty(dateFilter)) {
        filter.date_start = dateFilter;
      }

      if (filters.patientName) {
        filter.search = filters.patientName;
      }

      return filter;
    },

    loadPages(page, recalculate) {
      let pageSize = Ember.get(this, 'page_size');
      let filter = this.getFilters();
      Ember.get(this, 'store').query('encounter', {
        filter,
        endpointURI: "encounters?page_number=".concat(page, "&page_size=").concat(pageSize)
      }).then(encounters => {
        Ember.set(this, 'encounters', encounters);
      });

      if (recalculate) {
        Ember.get(this, 'store').queryRecord('page-number', {
          filter,
          endpointURI: "encounters/pages-total?page_size=".concat(pageSize)
        }).then(response => {
          Ember.set(this, 'data_size', response.size);
        });
      }
    },

    actions: {
      findByQueryCompany(query) {
        let orgId = Ember.get(this, 'current.user.orgId');
        let systemId = Ember.get(this, 'current.user.client.systemId');

        if (query.length >= 3) {
          let businessPartners = Ember.get(this, 'store').query('business-partner', {
            orgId,
            systemId,
            query
          });
          Ember.set(this, 'businessPartners', businessPartners);
        }

        if (query.length === 0) {
          Ember.set(this, 'businessPartners', null);
        }
      },

      setCompany(businessPartner) {
        Ember.set(this, 'filters.bpName', businessPartner.name);
        Ember.set(this, 'filters.test', businessPartner.cBpartnerId);
      },

      changePage(page, recalculate) {
        if (recalculate) {
          Ember.set(this, 'page_current', 1);
        }

        this.loadPages(page, recalculate);
      },

      download() {
        let manager = _exportManager.default.create();

        let filter = this.getFilters();
        this.apollo.request("".concat(_environment.default.APP.apiUrl, "/encounters/download"), {
          params: filter
        }).then(data => manager.download('coverages-export.csv', data)).catch(data => manager.download('coverages-export.csv', data));
      }

    },

    _isEmpty(obj) {
      return Object.keys(obj).length === 0;
    }

  });

  _exports.default = _default;
});