define("apollo/pods/components/forms/currency-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    localClassNames: ['form-input', 'input-text'],
    localClassNameBindings: ['widthClass', 'labelPosition'],
    type: 'currency',
    placeholder: '',
    width: 2,
    value: 0,
    currency: {
      separator: ".",
      prefix: " $ "
    },
    widthClass: Ember.computed('width', function () {
      switch (Ember.get(this, 'width')) {
        case 1:
          return 'expand-eight';

        case 2:
          return 'expand-quarter';

        case 3:
          return 'expand-half';

        case 4:
          return 'expand-th-q';

        case 5:
          return 'expand-full';

        default:
          return 'expand-quarter';
      }
    }),
    simbol: Ember.computed('type', function () {
      switch (Ember.get(this, 'type')) {
        case 'percent':
          return 'percent';

        default:
          return 'money-bill-alt';
      }
    }),
    labelPosition: Ember.computed('label-align', function () {
      return Ember.get(this, 'label-align') + "-label";
    }),
    actions: {
      click() {
        let click = Ember.get(this, 'onClick');

        if (click) {
          Ember.set(this, 'type', Ember.get(this, 'type') === "currency" ? "percent" : "currency");
          Ember.set(this, 'value', 0);
          return click(Ember.get(this, 'type'), event);
        }
      },

      change(value) {
        let change = Ember.get(this, 'onChange');

        if (change) {
          Ember.set(this, 'value', value);
          return change(value, event);
        }
      },

      focusOut(value) {
        let focusOut = Ember.get(this, 'onFocusOut');

        if (focusOut) {
          return focusOut(value, event);
        }
      }

    }
  });

  _exports.default = _default;
});