define("apollo/pods/components/fragment/insurance/policy/form/component", ["exports", "apollo/pods/objects/ref-list"], function (_exports, _refList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    policyTypes: Ember.computed(function () {
      return _refList.default.create().getList("policyDocType");
    }),
    numberLabel: Ember.computed('isLevel', function () {
      return Ember.get(this, 'isLevel') ? 'Level number' : 'msg.poliza.no';
    }),
    nameLabel: Ember.computed('isLevel', function () {
      return Ember.get(this, 'isLevel') ? 'Level name' : 'msg.policyName';
    }),
    currencies: Ember.computed('policyVersion.currency', function () {
      let list = _refList.default.create().getList("currencies");

      let current = Ember.get(this, 'policyVersion.currency');

      if (current && !list.some(item => item.value === current)) {
        list.push({
          name: current,
          value: current
        });
      }

      return list;
    })
  });

  _exports.default = _default;
});