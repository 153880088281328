define("apollo/pods/components/confirm-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HtmovNeW",
    "block": "{\"symbols\":[],\"statements\":[[4,\"popup\",null,[[\"title\",\"close\"],[\"Confirmation\",[29,\"action\",[[24,0,[]],[25,[\"onCancel\"]]],null]]],{\"statements\":[[0,\"  \"],[1,[23,\"message\"],false],[0,\"\\n  \"],[7,\"div\"],[12,\"class\",[30,[[29,\"local-class\",[\"panel-button\"],[[\"from\"],[\"apollo/pods/components/confirm-panel/styles\"]]]]]],[9],[0,\"\\n\"],[4,\"widgets/x-button\",null,[[\"color\",\"action\"],[\"cancel\",[29,\"action\",[[24,0,[]],[25,[\"onCancel\"]]],null]]],{\"statements\":[[0,\"      \"],[7,\"strong\"],[9],[0,\"Decline\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \\n\"],[4,\"widgets/x-button\",null,[[\"color\",\"action\"],[\"send\",[29,\"action\",[[24,0,[]],[25,[\"onConfirm\"]]],null]]],{\"statements\":[[0,\"      \"],[7,\"strong\"],[9],[0,\"Accept\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "apollo/pods/components/confirm-panel/template.hbs"
    }
  });

  _exports.default = _default;
});