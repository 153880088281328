define("apollo/pods/components/forms/input-text/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "input": "_input_wsimyy _input_1dnopz",
    "label": "_label_wsimyy _label_1dnopz",
    "form-input": "_form-input_wsimyy _form-input_1dnopz",
    "required": "_required_wsimyy _required_1dnopz",
    "expand-eight": "_expand-eight_wsimyy _expand-eight_1dnopz",
    "expand-quarter": "_expand-quarter_wsimyy _expand-quarter_1dnopz",
    "expand-half": "_expand-half_wsimyy _expand-half_1dnopz",
    "expand-th-q": "_expand-th-q_wsimyy _expand-th-q_1dnopz",
    "expand-full": "_expand-full_wsimyy _expand-full_1dnopz",
    "left-label": "_left-label_wsimyy _left-label_1dnopz",
    "right-label": "_right-label_wsimyy _right-label_1dnopz",
    "bottom-label": "_bottom-label_wsimyy _bottom-label_1dnopz",
    "input-error": "_input-error_wsimyy _input-error_1dnopz",
    "error-message": "_error-message_wsimyy _error-message_1dnopz"
  };
  _exports.default = _default;
});