define("apollo/pods/authorized/insurance/companies/root/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    showPopup: false,
    businessPartner: undefined,
    notificationMessage: undefined,
    notificationType: undefined,
    notifications: Ember.inject.service('notifications'),

    actionToCompany(company, callback) {
      if (!Ember.get(company, "readOnly")) {
        if (company.id < 0) {
          Ember.set(company, "readOnly", true);
          company = this.saveCompany(company).then(company => {
            Ember.set(company, "readOnly", false);
            callback(company);
          });
        } else {
          callback(company);
        }
      }
    },

    actions: {
      updateStatus(company, status, event) {
        if (!Ember.get(company, "readOnly")) {
          if (company.id < 0) {
            Ember.set(company, "readOnly", true);
            company = this.saveCompany(company).then(company => {
              if (company) {
                Ember.set(company, "readOnly", false);
                let query = Ember.get(this, 'query') || "";
                this.send('findByQueryCompany', query);
              }

              return company;
            });
          } else {
            company.save();
          }

          event.stopPropagation();
        }
      },

      findByQueryCompany(query) {
        Ember.set(this, 'query', query);

        if (query.length === 0) {
          Ember.get(this, 'store').query('company', {
            findBy: 'find'
          }).then(companies => {
            Ember.set(this, 'model', companies);
          });
        } else if (query.length >= 3) {
          Ember.get(this, 'store').query('company', {
            findBy: 'find',
            query
          }).then(companies => {
            Ember.set(this, 'model', companies);
          });
        }
      },

      toNewPolicy(company) {
        this.actionToCompany(company, company => {
          this.transitionToRoute('authorized.insurance.company.policies.new', company);
        });
        return false;
      },

      toEditCompany(company) {
        this.actionToCompany(company, company => {
          this.transitionToRoute('authorized.insurance.company.edit', company);
        });
        return false;
      },

      toRoute(company, path) {
        this.actionToCompany(company, company => {
          this.transitionToRoute(path, company);
        });
        return false;
      },

      openPopup(businessPartner) {
        Ember.set(this, 'businessPartner', businessPartner);
        Ember.set(this, 'showPopup', true);
      },

      closePopup() {
        Ember.set(this, 'showPopup', false);
      }

    },

    saveCompany(company) {
      let client = Ember.get(this, 'current.user.client');
      company = Ember.get(this, 'store').createRecord('company', company.toJSON());
      Ember.set(company, 'client', client);
      Ember.set(company, 'active', true);
      Ember.set(this, 'showPopup', false);

      try {
        company = company.save();
        this.notifications.show('success', 'Company added successfully', 5);
      } catch (e) {
        this.notifications.show('error', "Company \"".concat(company.name, "\" is already added"), 5);
        company.rollbackAttributes();
        company = undefined;
      }

      return company;
    }

  });

  _exports.default = _default;
});