define("apollo/pods/authorized/insurance/company/policies/root/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "search-form": "_search-form_tgaqjz",
    "search-button": "_search-button_tgaqjz",
    "dates": "_dates_tgaqjz",
    "filter-header": "_filter-header_tgaqjz",
    "filter-header-cell": "_filter-header-cell_tgaqjz",
    "filter-fields": "_filter-fields_tgaqjz"
  };
  _exports.default = _default;
});