define("apollo/pods/authorized/insurance/policy_version/benefits/root/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "align-columns": "_align-columns_1ig4qn",
    "item-padding": "_item-padding_1ig4qn",
    "container": "_container_1ig4qn",
    "layout-direction": "_layout-direction_1ig4qn",
    "delete": "_delete_1ig4qn"
  };
  _exports.default = _default;
});