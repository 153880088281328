define("apollo/authenticators/token", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "apollo/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    ajax: Ember.inject.service('ajax'),

    authenticate(token) {
      let ajax = Ember.get(this, 'ajax');
      return ajax.request("".concat(_environment.default.APP.apiUrl, "/validate?token=").concat(token)).then(() => {
        return {
          access_token: token,
          token_type: 'bearer'
        };
      }).catch(error => {
        return Ember.RSVP.reject(error);
      });
    }

  });

  _exports.default = _default;
});