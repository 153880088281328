define("apollo/adapters/application", ["exports", "ember-data/adapters/json-api", "ember-simple-auth/mixins/data-adapter-mixin", "apollo/config/environment"], function (_exports, _jsonApi, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jsonApi.default.extend(_dataAdapterMixin.default, {
    host: _environment.default.APP.baseUrl,
    namespace: _environment.default.APP.basePath,

    urlForQueryRecord(query) {
      if (query.findBy) {
        let url = "".concat(this._super(...arguments), "/").concat(query.findBy);
        delete query.findBy;
        return url;
      }

      if (query.endpointURI) {
        let url = "".concat(this.host, "/").concat(this.namespace, "/").concat(query.endpointURI);
        delete query.endpointURI;
        return url;
      }

      return this._super(...arguments);
    },

    urlForQuery(query) {
      if (query.findBy) {
        let url = "".concat(this._super(...arguments), "/").concat(query.findBy);
        delete query.findBy;
        return url;
      }

      if (query.endpointURI) {
        let url = "".concat(this.host, "/").concat(this.namespace, "/").concat(query.endpointURI);
        delete query.endpointURI;
        return url;
      }

      return this._super(...arguments);
    },

    authorize(request) {
      let token = Ember.get(this, 'session.data.authenticated.access_token');

      if (Ember.isPresent(token)) {
        request.setRequestHeader('Authorization', "Bearer ".concat(token));
      }
    }

  });

  _exports.default = _default;
});