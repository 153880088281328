define("apollo/pods/authorized/insurance/benefit/root/controller", ["exports", "apollo/config/environment", "apollo/pods/objects/ref-list", "apollo/pods/objects/export-manager"], function (_exports, _environment, _refList, _exportManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notifications: Ember.inject.service('notifications'),
    apollo: Ember.inject.service('apollo'),
    session: Ember.inject.service('session'),
    collapsePolicyInfo: false,
    panel: null,
    page_size_coverages: 15,
    page_current_coverages: 1,
    data_size_coverages: 0,
    page_size_diagnostics: 15,
    page_current_diagnostics: 1,
    data_size_diagnostics: 0,
    //Filter properties
    filters: {},
    sort: {},
    settings: Ember.computed(function () {
      let token = Ember.get(this, 'session.data.authenticated.access_token');
      return {
        'headers': {
          'Authorization': "Bearer ".concat(token)
        }
      };
    }),
    frequenciesList: Ember.computed(function () {
      return _refList.default.create().getList("limitFrequency");
    }),
    targetList: Ember.computed(function () {
      return _refList.default.create().getList("limitTarget");
    }),
    canAddCoverages: Ember.computed('benefit', 'benefit.isNew', function () {
      return !Ember.get(this, 'benefit.isNew');
    }),
    canAddDiagnosis: Ember.computed('benefit', 'benefit.isNew', function () {
      return !Ember.get(this, 'benefit.isNew');
    }),
    title: Ember.computed('panel', function () {
      return Ember.get(this, 'panel') === 'C' ? 'Add Coverage' : 'Add Diagnostic';
    }),

    loadPages(subfix, page, recalculate) {
      let benefit = Ember.get(this, 'benefit');

      if (benefit.id) {
        let pageSize = Ember.get(this, "page_size_".concat(subfix));
        let filter = Ember.get(this, "filters.query_".concat(subfix));
        let sort = this.getSort();
        let params = {};
        let docType = null;

        if (subfix === 'coverages') {
          docType = 'coverage';
          params = {
            endpointURI: "benefits/".concat(benefit.id, "/coverages/_search?page_number=").concat(page, "&page_size=").concat(pageSize),
            filter,
            sort
          };
        } else {
          docType = 'benefitDiagnostic';
          params = {
            endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostics/_search?page_number=").concat(page, "&page_size=").concat(pageSize),
            filter,
            sort
          };
        }

        Ember.get(this, 'store').query(docType, params).then(data => {
          Ember.set(this, subfix, data);
        });

        if (recalculate) {
          let params2 = {};

          if (subfix === 'coverages') {
            params2 = {
              endpointURI: "benefits/".concat(benefit.id, "/coverages/pages-total?page_size=").concat(pageSize),
              filter
            };
          } else {
            params2 = {
              endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostics/pages-total?page_size=").concat(pageSize),
              filter
            };
          }

          Ember.get(this, 'store').queryRecord('page-number', params2).then(response => {
            if (subfix === 'diagnostics') {
              Ember.set(this, 'total_records', response.total);
            }

            Ember.set(this, "data_size_".concat(subfix), response.size);
          });
        }
      } else {
        Ember.set(this, subfix, null);
        Ember.set(this, "data_size_".concat(subfix), 0);
      }
    },

    getSort() {
      let sort = {};
      let sorting = Ember.get(this, 'sort');

      if (sorting.name) {
        sort.name = Ember.String.decamelize(sorting.name);
        sort.order = sorting.order;

        if (sort.name.includes(".")) {
          let params = sort.name.split(".");
          sort.bind = params[0];
          sort.name = params[1];
        }
      }

      return sort;
    },

    actions: {
      changePage(subfix, page, pageSize, recalculate) {
        if (pageSize > 0) {
          Ember.set(this, "page_size_".concat(subfix), pageSize);
        }

        this.loadPages(subfix, page, recalculate);
      },

      showPanel(selected) {
        Ember.set(this, 'panel', selected);
      },

      showPolicyInfo() {
        Ember.set(this, 'collapsePolicyInfo', !Ember.get(this, 'collapsePolicyInfo'));
      },

      downloadLayout(value) {
        let manager = _exportManager.default.create();

        let benefit = Ember.get(this, 'benefit');

        switch (value) {
          case 4:
            // Current
            manager.download('diagnostics-export.csv', manager.fileFrom('diagnosis', Ember.get(this, 'diagnostics')));
            break;

          case 3:
            //Empty
            manager.download('diagnostics-export.csv', manager.emptyFile('diagnosis'));
            break;

          case 2:
            // Current
            manager.download('coverages-export.csv', manager.fileFrom('coverage', Ember.get(this, 'coverages')));
            break;

          case 1:
            // Full
            this.apollo.request("".concat(_environment.default.APP.apiUrl, "/benefits/").concat(benefit.id, "/download-coverages")).then(data => manager.download('coverages-export.csv', data)).catch(data => manager.download('coverages-export.csv', data));
            break;

          case 0: //Empty

          default:
            manager.download('coverages-export.csv', manager.emptyFile('coverage'));
        }
      },

      uploadCoveragesLayout(file) {
        let benefit = Ember.get(this, 'benefit');
        let url = "".concat(_environment.default.APP.apiUrl, "/benefits/").concat(Ember.get(benefit, 'id'), "/coverages");
        let settings = Ember.get(this, 'settings');
        let notifications = Ember.get(this, 'notifications');
        return file.upload(url, settings).then(() => {
          this.loadPages("coverages", 1, true);
          return notifications.show('success', 'Products imported');
        }).catch(error => {
          Ember.set(file, 'queue.files', []);

          if (error.status === 500) {
            return notifications.show('error', 'Error, File format not valid');
          } else {
            return notifications.show('error', 'Error, products not imported');
          }
        });
      },

      uploadDiagnosticsLayout(file) {
        let benefit = Ember.get(this, 'benefit');
        let url = "".concat(_environment.default.APP.apiUrl, "/benefits/").concat(Ember.get(benefit, 'id'), "/benefit-diagnostics");
        let settings = Ember.get(this, 'settings');
        let notifications = Ember.get(this, 'notifications');
        return file.upload(url, settings).then(() => {
          this.loadPages("diagnostics", 1, true);
          return notifications.show('success', 'Diagnostics imported');
        }).catch(error => {
          Ember.set(file, 'queue.files', []);

          if (error.status === 500) {
            return notifications.show('error', 'Error, File format not valid');
          } else {
            return notifications.show('error', 'Error, Diagnostics not imported');
          }
        });
      },

      changeBenefit(benefit) {
        this.transitionToRoute('authorized.insurance.benefit.root', benefit);
      },

      deleteBenefit() {
        let notifications = Ember.get(this, 'notifications');
        return notifications.show('error', 'Try to delete benefit');
      },

      async onSelectProduct(value, product) {
        if (product) {
          let benefit = Ember.get(this, 'benefit');
          let store = Ember.get(this, 'store');
          let existingCoverage = await store.queryRecord('coverage', {
            endpointURI: "benefits/".concat(benefit.id, "/coverages/find?epc=").concat(product.epc)
          });

          if (existingCoverage) {
            Ember.set(this, 'coverage', existingCoverage);
          } else {
            Ember.set(this, 'coverage', store.createRecord('coverage', {
              benefit,
              name: product.name,
              epc: product.epc,
              pType: product.category_name
            }));
          }
        }

        return false;
      },

      async onSelectDiagnostic(value, item) {
        if (item) {
          let benefit = Ember.get(this, 'benefit');
          let store = Ember.get(this, 'store');
          let existing = await store.queryRecord('benefit-diagnostic', {
            endpointURI: "benefits/".concat(benefit.id, "/benefit-diagnostic/find?code=").concat(item.code)
          });

          if (existing) {
            Ember.set(this, 'diagnostic', existing);
          } else {
            Ember.set(this, 'diagnostic', store.createRecord('benefitDiagnostic', {
              benefit,
              name: item.name,
              helixId: item.id,
              helixCode: item.code
            }));
          }
        }

        return false;
      },

      saveRecord() {
        let notifications = Ember.get(this, 'notifications');
        let coverage = Ember.get(this, 'coverage');

        if (coverage) {
          return coverage.save().then(() => {
            Ember.set(this, 'coverage', null);
            this.loadPages("coverages", Ember.get(this, 'page_current_coverages'), true);
            notifications.show('success', 'Coverage added');
          }).catch(() => {
            notifications.show('error', 'Coverage couldn\'t be added');
          });
        }

        let diagnostic = Ember.get(this, 'diagnostic');

        if (diagnostic) {
          return diagnostic.save().then(() => {
            Ember.set(this, 'diagnostic', null);
            this.loadPages("diagnostics", Ember.get(this, 'page_current_diagnostics'), true);
            notifications.show('success', 'Diagnostic saved!');
          }).catch(() => {
            notifications.show('error', 'Diagnostic couldn\'t be added');
          });
        }
      },

      updateCoverage(coverage) {
        return coverage.save().then(() => {
          return true;
        }).catch(response => {
          let notifications = Ember.get(this, 'notifications');
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      deleteCoverage(coverage) {
        let notifications = Ember.get(this, 'notifications');
        coverage.destroyRecord().then(() => {
          notifications.show('success', 'Coverage successfully deleted.');
          this.loadPages("coverages", Ember.get(this, 'page_current_coverages'), true);
        }).catch(() => {
          coverage.rollbackAttributes();
          notifications.show('error', 'Coverage delete failed!');
        });
      },

      saveLimitRule(coverage) {
        let notifications = Ember.get(this, 'notifications');
        let limitRule = Ember.get(this, 'limitRule');
        Ember.set(limitRule, "coverage", coverage);
        limitRule.save().then(limitRule => {
          notifications.show('success', 'Limit Rule saved!');
          return limitRule;
        }).catch(() => {
          limitRule.rollbackAttributes();
          notifications.show('error', 'Limit Rule save failed!');
        });
        Ember.set(this, 'limitRule', Ember.get(this, 'store').createRecord('limitRule'));
      },

      deleteLimitRule(rule) {
        rule.destroyRecord();
      },

      updateDiagnostic(diagnostic) {
        return diagnostic.save().then(() => {
          return true;
        }).catch(response => {
          let notifications = Ember.get(this, 'notifications');
          let message = response.errors.map(error => error.detail).join(", ");
          return notifications.show('error', message);
        });
      },

      deleteDiagnostic(diagnostic) {
        let notifications = Ember.get(this, 'notifications');
        diagnostic.destroyRecord().then(() => {
          notifications.show('success', 'Diagnostic successfully deleted.');
          this.loadPages("diagnostics", Ember.get(this, 'page_current_diagnostics'), true);
        }).catch(() => {
          diagnostic.rollbackAttributes();
          notifications.show('error', 'Diagnostic delete failed!');
        });
      },

      changeRadioValue(model, changedField, relatedField, value) {
        Ember.set(model, changedField, value);
        Ember.set(model, relatedField, false);
        this.send("updateCoverage", model);
        return false;
      },

      filterCoverages(query) {
        Ember.set(this, "filters.query_coverages_text", query);
        Ember.set(this, "filters.query_coverages", {
          epc: {
            like: query
          },
          name: {
            or_ilike: query
          }
        });

        if (!query || query.length > 3) {
          this.loadPages("coverages", 1, true);
        }
      },

      filterDiagnostics(query) {
        Ember.set(this, "filters.query_diagnostics_text", query);
        Ember.set(this, "filters.query_diagnostics", {
          helix_code: {
            like: query
          },
          name: {
            or_ilike: query
          }
        });

        if (!query || query.length > 3) {
          this.loadPages("diagnostics", 1, true);
        }
      },

      sortBy(sort) {
        Ember.set(this, "sort", sort);
        this.loadPages("coverages", 1, true);
      }

    }
  });

  _exports.default = _default;
});