define("apollo/pods/authorized/imports/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "import-screen": "_import-screen_13cta4",
    "import-section": "_import-section_13cta4",
    "list-menu": "_list-menu_13cta4"
  };
  _exports.default = _default;
});