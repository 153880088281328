define("apollo/app", ["exports", "apollo/resolver", "ember-load-initializers", "apollo/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  Ember.Component.reopen({
    /*  Some components can be tagless ( tagName: '' ),
     *  but they still may contain logic/actions/components inside of them.
     *  Since they get rendered as HTML comments ( <!---> ),
     *  it's impossible for them to have attributeBindings, and as a result,
     *  Ember throws an error.
     *  Therefore we need to check if the component has a tagName
     *  before adding attributeBindings.
     */
    init() {
      this._super(...arguments);

      if (this.tagName || this.elementId) {
        let newAttributeBindings = [];
        let bindings = Ember.get(this, 'attributeBindings');

        if (Array.isArray(bindings)) {
          newAttributeBindings = newAttributeBindings.concat(bindings);
        }

        newAttributeBindings.push('data-test-id');
        Ember.set(this, 'attributeBindings', newAttributeBindings);
      }
    }

  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});