define("apollo/models/company", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    name: (0, _attr.default)('string'),
    orgId: (0, _attr.default)('string'),
    active: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    client: (0, _relationships.belongsTo)('client'),
    policies: (0, _relationships.hasMany)('policy'),
    items: (0, _attr.default)('string'),
    systemId: (0, _attr.default)('number'),
    rfc: (0, _attr.default)('string'),
    priceListId: (0, _attr.default)('number'),
    priceLists: (0, _relationships.hasMany)('price-list'),
    readOnly: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    missingInfoMessage: Ember.computed('systemId', {
      get() {
        let message = null;

        if (Ember.get(this, 'systemId') === null) {
          message = message || [];
          message.push({
            key: "error",
            value: "Missing info"
          });
        }
        /*if (get(this, 'priceListId') === null) {
          message = message || [];
          message.push({ key: "warning", value: "price list not found"});
        }*/


        return message;
      }

    })
  });

  _exports.default = _default;
});