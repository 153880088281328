define("apollo/models/benefit-link", ["exports", "ember-data/model", "ember-data/attr", "ember-data/relationships"], function (_exports, _model, _attr, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _model.default.extend({
    identifier: (0, _attr.default)('string'),
    name: (0, _attr.default)('string'),
    benefits: (0, _relationships.hasMany)('benefit'),
    policyLevel: (0, _relationships.belongsTo)('policyVersion'),
    policyVersion: (0, _relationships.belongsTo)('policyVersion'),
    excluded: (0, _attr.default)('boolean'),
    preauthorization: (0, _attr.default)('boolean'),
    collapse: (0, _attr.default)('boolean'),
    updatedBy: (0, _attr.default)('string'),
    updatedAt: (0, _attr.default)('date'),
    insertedBy: (0, _attr.default)('string'),
    insertedAt: (0, _attr.default)('date')
  });

  _exports.default = _default;
});